define('yxt-apps/components/chart/progress-donut', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let arc = d3.arc().outerRadius(110).innerRadius(100).cornerRadius(8);
  let color = d3.scaleOrdinal().range(['#49A970', '#f2f2f2']);

  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['chart-widget'],
    percentComplete: 0,
    previousPercentComplete: 0,

    didInsertElement() {
      let width = 400;
      let height = 250;

      let percentComplete = this.get('percentComplete');
      let percentRemaining = 100 - percentComplete;
      let data = [percentComplete, percentRemaining];

      let svg = d3.select(".chart-widget").attr("width", width).attr("height", height);

      let group = svg.append("g").attr("id", "donut-meter").attr("transform", "translate(" + 200 + "," + 125 + ")");

      svg.append("g").attr("id", "percentValue").append("text").text(`${percentComplete}`).attr("id", "percentValueText").attr("x", "50%").attr("y", "50%").attr("alignment-baseline", "middle").attr("text-anchor", "middle");

      svg.append("g").attr("id", "percentLabel").append("text").text(`% complete`).attr("x", "50%").attr("y", 165).attr("alignment-baseline", "middle").attr("text-anchor", "middle");

      let pie = d3.pie().sort(null)(data);

      var g = group.selectAll("arc").data(pie).enter().append("g").attr("class", "arc");

      g.append("path").attr("d", arc).attr("fill", function (d, i) {
        return color(i);
      }).each(function (d) {
        this._current = d;
      });

      this.set('previousPercentComplete', percentComplete);
    },

    didUpdateAttrs() {
      let percentComplete = this.get('percentComplete');
      let previousPercentComplete = this.get('previousPercentComplete');
      let percentRemaining = 100 - percentComplete;

      // Only update if the value is different
      if (previousPercentComplete !== percentComplete) {
        let data = [percentComplete, percentRemaining];
        let pie = d3.pie().sort(null)(data);

        let svg = d3.select(".chart-widget");

        let paths = svg.selectAll("path").data(pie);
        paths.transition().duration(2000).attrTween("d", function (a) {
          let i = d3.interpolate(this._current, a);
          this._current = i(1);
          return function (t) {
            return arc(i(t));
          };
        });

        svg.select("#percentValue").transition().duration(2000).tween("percentText", function () {
          let i = d3.interpolate(previousPercentComplete, percentComplete);
          return function (t) {
            svg.select("#percentValueText").text(Math.round(i(t)));
          };
        });

        this.set('previousPercentComplete', percentComplete);
      }
    }

  });
});