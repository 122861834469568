define('yxt-apps/components/reaction/study-card-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'li',
        classNameBindings: ['study.isSelected:selected', 'isDeleted:destroying'],
        classNames: ['list-item'],
        actions: {
            deleteStudy(study) {
                function deleteStudy() {
                    study.destroyRecord();
                }
                if (window.confirm(`Are you sure you want to delete ${study.get('title')}`)) {
                    this.$().addClass('destroying');
                    window.setTimeout(deleteStudy, 500);
                }
            }
        }
    });
});