define('yxt-apps/components/role-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            cancelChanges(role) {
                this.get('cancelChanges')(role);
            },
            updateRole(role) {
                this.get('updateRole')(role);
            },
            addTag(role) {
                this.get('addTag')(role);
            },
            removeTagAtIndex(role) {
                this.get('removeTagAtIndex')(role);
            }
        }
    });
});