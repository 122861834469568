define('yxt-apps/helpers/date-input-format', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateInputFormat = dateInputFormat;
  function dateInputFormat(params /*, hash*/) {
    return params;
  }

  exports.default = Ember.Helper.helper(dateInputFormat);
});