define('yxt-apps/routes/admin/users', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return this.get('store').findAll('user').then(function (user) {
        return user.sortBy('username');
      });
    },
    actions: {}
  });
});