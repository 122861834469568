define('yxt-apps/models/page', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _emberDataModelFragments, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    templateId: _emberData.default.attr(),
    title: _emberData.default.attr('string'),
    content: (0, _attributes.fragmentArray)('content'),
    properties: _emberData.default.attr(),
    studyWebsiteTask: _emberData.default.belongsTo('study-website-task'),
    studyJournalTask: _emberData.default.belongsTo('study-journal-task'),
    studyPostSessionQuestion: _emberData.default.belongsTo('study-post-session-question'),
    studyPostSessionQuestionResponses: _emberData.default.belongsTo('study-post-session-question-responses'),
    studyParticipantTaskAnswers: _emberData.default.belongsTo('study-participant-responses-tasks'),
    studyParticipantPostAnswers: _emberData.default.belongsTo('study-participant-responses-post-questions'),
    studyParticipantScoreAnswers: _emberData.default.belongsTo('study-participant-responses-youxscore-post-questions'),
    isEditing: _emberData.default.attr('boolean', { defaultValue: false }),
    createdAt: _emberData.default.attr(),
    modifiedAt: _emberData.default.attr(),
    isValid: false,
    isError: Ember.computed('content.@each.metadata', function () {
      //WIP
      this.get("content").forEach(function (content) {
        if (content.metadata.question == "") return true;else return false;
      });
    })
  });
});