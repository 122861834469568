define('yxt-apps/routes/reaction/studies/study', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    let routeParams;
    let self;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = { title: pageName };
            return breadCrumbTitle;
        }),
        studyParticipants: '',
        sParticipants: null,
        getStudy(params) {
            return this.get('store').findRecord('study', params.study_id).catch(() => {
                return null;
            });
        },
        getAllProjects() {
            return this.get('store').findAll('project').catch(() => {
                return null;
            });
        },
        getParticipants(params) {
            let self = this;
            return this.get('store').query('study-participant', {
                filterByField: 'study',
                filterValue: params.study_id
            }).catch(() => {
                return null;
            }).then(function (participants) {
                return participants;
            });
        },
        getParticipantResults(params) {
            return this.get('store').query('participant-result', {
                studyId: params.study_id
            }).catch(() => {
                return null;
            }).then(results => {
                return results.sortBy('modifiedAt').reverse();
            });
        },
        getStudyWebsiteTasks(params) {
            self = this;
            return this.get('store').query('study-website-task', {
                studyId: params.study_id
            }).catch(() => {
                return null;
            }).then(function (websiteTasks) {

                if (websiteTasks.get('length') === 0) {
                    return self.get('store').createRecord('study-website-task', {
                        study: params.study_id
                    });
                } else {
                    return websiteTasks;
                }
            });
        },
        getStudyPostSessionQuestions(params) {
            self = this;
            return this.get('store').query('study-post-session-question', {
                studyId: params.study_id
            }).catch(() => {
                return null;
            }).then(function (postQuestions) {

                if (postQuestions.get('length') === 0) {
                    // then we are creating a new study so we'll need these.
                    return self.get('store').createRecord('study-post-session-question', {
                        study: params.study_id
                    });
                } else {
                    return postQuestions;
                }
            });
        },
        model(params) {
            self = this;
            routeParams = params;
            let studyTypes = this.get('store').peekAll('study-template');

            this.get('store').findAll('participant').catch(() => {
                return null;
            });

            let invitationMailTemplate = this.get('store').query('mail-template', {
                studyId: params.study_id
            }).catch(() => {
                return null;
            }).then(function (mTemplates) {
                return mTemplates.filterBy('mailType', 'invitation').get('firstObject');
            });

            let reminderMailTemplate = this.get('store').query('mail-template', {
                studyId: params.study_id
            }).catch(() => {
                return null;
            }).then(function (mTemplates) {
                return mTemplates.filterBy('mailType', 'reminder').get('firstObject');
            });

            let allParticipants = this.getParticipants(params);
            let selectedParticipants = allParticipants.then(function (participants) {
                return participants.filterBy('isSelected', true);
            });;
            return Ember.RSVP.hash({
                studyTypes: studyTypes,
                study: this.getStudy(params),
                projects: this.getAllProjects(),
                participants: allParticipants,
                selectedParticipants: selectedParticipants,
                completedParticipants: null,
                pendingParticipants: null,
                studyWebsiteTasks: this.getStudyWebsiteTasks(params),
                studyPostSessionQuestions: this.getStudyPostSessionQuestions(params),
                participantResults: this.getParticipantResults(params),
                invitationMailTemplate: invitationMailTemplate,
                reminderMailTemplate: reminderMailTemplate,
                isUploadEditorOpen: false
            });
        },
        onPoll() {
            return this.getStudy(routeParams).then(study => {
                this.set('currentModel.study', study);
                return this.getParticipants(routeParams).then(participants => {
                    this.set('currentModel.participants', participants);
                    this.set('currentModel.completedParticipants', this.get('currentModel.participants').filterBy('status', 'completed'));

                    if (study.get('status').toLowerCase() === 'active' || study.get('status').toLowerCase() === 'complete' || study.get('status').toLowerCase() === 'completed') {
                        return this.getParticipantResults(routeParams).then(participantResults => {
                            this.set('currentModel.participantResults', participantResults);
                        });
                    }
                });
            });
        },
        afterModel(model) {
            let usersPoller = this.get('usersPoller');
            //this.set('headData.title', `YouXReaction - ${model.study.get('title')}`);
            // Make sure we only create one poller instance. Without this every time onPoll
            // is called afterModel would create a new poller causing us to have a growing list
            // of pollers all polling the same thing (which would result in more frequent polling).
            if (!usersPoller) {
                usersPoller = this.get('pollboy').add(this, this.onPoll, this.get('config.pollFrequency'));
                this.set('usersPoller', usersPoller);
            }
        },
        deactivate() {
            const usersPoller = this.get('usersPoller');
            this.get('pollboy').remove(usersPoller);
        }
    });
});