define('yxt-apps/components/study-participant-generator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let studyParticipants = [];
  exports.default = Ember.Component.extend({

    didInsertElement() {
      let self = this;
      let study = this.get('study');
      let myOwnTemplate = this.get('store').peekRecord('study-participant-template', this.get('config.participantMyOwnTemplateId'));
      this.get('store').query('study-participant', { filterByField: 'study', filterValue: study.get('id') }).then(function (studyParticipants) {

        let curIndex = studyParticipants.get('length');
        let startingGenerator = 1;
        if (curIndex < startingGenerator) {
          for (var i = curIndex; i < startingGenerator; i++) {
            let person = self.get('store').createRecord('participant', {
              isEditing: true,
              template: myOwnTemplate
            });
            person.set('profile', self.get('store').createFragment('profile', {}));

            studyParticipants[i] = self.get('store').createRecord('study-participant', {
              study: study,
              participant: person,
              isEditing: true
            });

            study.set('participantQty', i);
          }
        }
      });
    },
    actions: {
      addParticipant(study) {
        let myOwnTemplate = this.get('store').peekRecord('study-participant-template', this.get('config.participantMyOwnTemplateId'));
        let person = this.get('store').createRecord('participant', {
          isEditing: true,
          template: myOwnTemplate
        });
        person.set('profile', self.get('store').createFragment('profile', {}));

        let newParticipant = this.get('store').createRecord('study-participant', {
          study: study,
          participant: person
        });
        studyParticipants.pushObject(newParticipant);

        study.set('participantQty', study.get('participants.length'));
      }
    }
  });
});