define('yxt-apps/serializers/study-worker-data', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      hitId: { serialize: false },
      study: { serialize: false },
      requirements: { embedded: 'always' }
    },

    modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'data') {
        let modelName = 'study-worker-data';
        return this._super(payloadKey.replace('data', modelName));
      } else {
        return this._super(payloadKey);
      }
    }

  });
});