define('yxt-apps/models/role', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    permissions: (0, _attributes.fragment)('permissions'),
    //permission: DS.attr(),
    createdAt: _emberData.default.attr(),
    modifiedAt: _emberData.default.attr(),
    tenantId: _emberData.default.attr(),
    user: _emberData.default.hasMany('user'),
    organizationMember: _emberData.default.belongsTo('organizationMember')
  });
});