define('yxt-apps/routes/journal/studies/study/create/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: {
            title: 'Create a Study'
        },
        beforeModel() {
            this.get('state').set('studyCreationStep', 'create');
        },
        afterModel: function (transition) {
            Ember.$('body > div.ember-view').animate({
                scrollTop: 0
            }, 500);
            this._super(transition);
        },
        actions: {
            validateNumberOfDays() {
                let numDays = this.get('currentModel.study.numberOfDays');
                if (numDays > 31) {
                    this.toast.error(`You can run your study for up to 31 days.  We've reset the length of your study to 31 days.`);
                    this.set('currentModel.study.numberOfDays', 31);
                } else if (numDays < 1) {
                    this.toast.error(`You must run your study for a minimum of 1 day.  We've reset the length of your study to 1 day.`);
                    this.set('currentModel.study.numberOfDays', 1);
                }
            },
            willTransition() {
                let self = this;

                function failure(reason) {
                    self.toast.error(reason);
                }

                let studyModel = this.modelFor('journal.studies.study');
                let study = studyModel.study;
                let journalTasks = studyModel.studyJournalTasks;
                if (journalTasks.length > 0) {
                    journalTasks = journalTasks.get('firstObject');
                }

                journalTasks.set('study', study);
                journalTasks.set('name', `Entries for ${study.get('title')}`);

                if (journalTasks.get('hasDirtyAttributes')) {
                    journalTasks.save().catch(failure);
                }
                if (study.get('hasDirtyAttributes')) {
                    study.save().catch(failure);
                }
            }
        }
    });
});