define('yxt-apps/helpers/journal-days-remaining', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.journalDaysRemaining = journalDaysRemaining;
  function journalDaysRemaining(params /*, hash*/) {
    let [startDate, numberOfDays] = params;
    let endDate = (0, _moment.default)(startDate).add(numberOfDays, 'days');
    return endDate;
  }

  exports.default = Ember.Helper.helper(journalDaysRemaining);
});