define('yxt-apps/models/content-meta', ['exports', 'ember-data', 'ember-data-model-fragments/fragment', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _fragment, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        isMandatory: _emberData.default.attr('boolean', {
            defaultValue: true
        }),
        allowMultiple: _emberData.default.attr('boolean', {
            defaultValue: false
        }),
        question: _emberData.default.attr('string'),
        body: (0, _attributes.fragmentArray)('question-choice'),
        size: _emberData.default.attr('number', {
            defaultValue: 5
        }),
        validChoices: Ember.computed('body.@each', 'body.@each.isChoiceValid', function () {
            let invalidChoices = this.get('body').filterBy('isChoiceValid', false);
            let validChoices = this.get('body').filterBy('isChoiceValid', true);
            let choicesQty = this.get('body.length');
            if (choicesQty > 0) {
                if (invalidChoices.length < 1 && validChoices.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        })
    });
});