define('yxt-apps/models/study-participant-data', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    templateId: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number')
  });
});