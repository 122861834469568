define('yxt-apps/routes/project/study', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {
            return this.get('store').findRecord('study', params.study_id);
        },
        afterModel(model) {
            let studyType = model.get('studyTemplate.studyType').toLowerCase();

            if (studyType === 'reaction') {
                this.transitionTo('reaction.studies.study', model.get('id'));
            } else if (studyType === 'journal') {
                this.transitionTo('journal.studies.study', model.get('id'));
            }
        }
    });
});