define('yxt-apps/routes/reaction/studies/study/create/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: {
            title: 'Create a Study'
        },
        beforeModel() {
            this.get('state').set('studyCreationStep', 'create');
        },
        afterModel: function (transition) {
            Ember.$('body > div.ember-view').animate({
                scrollTop: 0
            }, 500);
            this._super(transition);
        },
        actions: {
            removeYXScoreFeature() {
                let self = this;
                let studyModel = this.modelFor('reaction.studies.study');
                let study = studyModel.study;
                let yxScore = study.get('features').filterBy('name', 'youxscore').get('firstObject');
                yxScore.set('enabled', false);
            },
            addYXScoreFeature() {
                let self = this;
                let studyModel = this.modelFor('reaction.studies.study');
                let study = studyModel.study;
                let yxScore = study.get('features').filterBy('name', 'youxscore').get('firstObject');
                if (yxScore) {
                    yxScore.set('enabled', true);
                } else {
                    let features = study.get('features');
                    features.createFragment({
                        name: 'youxscore',
                        template: '59c94b36d34d6d0a480896c4'
                    });
                }
            },
            removeVoicePromptsFeature() {
                let self = this;
                let studyModel = this.modelFor('reaction.studies.study');
                let study = studyModel.study;
                let yxScore = study.get('features').filterBy('name', 'voiceprompts').get('firstObject');
                yxScore.set('enabled', false);
            },
            addVoicePromptsFeature() {
                let self = this;
                let studyModel = this.modelFor('reaction.studies.study');
                let study = studyModel.study;
                let voicePrompts = study.get('features').filterBy('name', 'voiceprompts').get('firstObject');

                if (voicePrompts) {
                    voicePrompts.set('enabled', true);
                } else {
                    let features = study.get('features');
                    features.createFragment({
                        name: 'voiceprompts',
                        template: '59c94b36d34d6d0a480896c5'
                    });
                }
            },
            willTransition(transition) {
                let self = this;
                let studyModel = this.modelFor('reaction.studies.study');
                let study = studyModel.study;
                let websiteTasks = studyModel.studyWebsiteTasks;
                if (websiteTasks.get('length') > 0) {
                    websiteTasks = websiteTasks.get('firstObject');
                }
                let isCreateReactionWorkflowComplete = study.get('isCreateReactionWorkflowComplete');
                let isStudyDirty = study.get('hasDirtyAttributes');
                let isWebsiteTasksDirty = websiteTasks.get('hasDirtyAttributes');
                if (isCreateReactionWorkflowComplete) {
                    if (isStudyDirty || isWebsiteTasksDirty) {
                        transition.abort();

                        function failure(reason) {
                            self.toast.error(reason);
                        }

                        function transitionToSetup() {
                            transition.retry();
                        }

                        function saveWebsiteTasks() {
                            if (isWebsiteTasksDirty) {
                                websiteTasks.set('study', study);
                                websiteTasks.set('name', `Tasks for ${study.get('title')}`);
                                websiteTasks.save().then(saveStudy).catch(failure);
                            } else {
                                saveStudy(websiteTasks);
                            }
                        }

                        function saveStudy(studyWebsiteTasks) {
                            if (study.get('studyWebsiteTasks').length < 1) {
                                study.get('studyWebsiteTasks').pushObject(studyWebsiteTasks);
                            }

                            study.save().then(transitionToSetup).catch(failure);
                        }
                        let studyModel = this.modelFor('reaction.studies.study');
                        let study = studyModel.study;
                        let websiteTasks = studyModel.studyWebsiteTasks;
                        saveWebsiteTasks();
                    }
                } else {
                    let webTasks = study.get('studyWebsiteTasks').get('firstObject');
                    this.get('store').unloadRecord(webTasks);
                }
            },
            saveStudy() {
                let self = this;

                function failure(reason) {
                    self.toast.error(reason);
                }

                function transitionToSetup() {
                    self.transitionTo('reaction.studies.study.create.setup');
                }

                function saveWebsiteTasks() {
                    websiteTasks.set('study', study);
                    websiteTasks.set('name', `Tasks for ${study.get('title')}`);
                    websiteTasks.save().then(saveStudy).catch(failure);
                }

                function saveStudy(studyWebsiteTasks) {
                    if (study.get('studyWebsiteTasks').length < 1) {
                        study.get('studyWebsiteTasks').pushObject(studyWebsiteTasks);
                    }

                    study.save().then(transitionToSetup).catch(failure);
                }
                let studyModel = this.modelFor('reaction.studies.study');
                let study = studyModel.study;
                let websiteTasks = studyModel.studyWebsiteTasks.get('firstObject');
                saveWebsiteTasks();
            }
        }
    });
});