define('yxt-apps/initializers/browser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('model', 'browser', 'service:browser');
    application.inject('route', 'browser', 'service:browser');
    application.inject('controller', 'browser', 'service:browser');
    application.inject('component', 'browser', 'service:browser');
  }

  exports.default = {
    name: 'browser',
    initialize: initialize
  };
});