define('yxt-apps/models/signup', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isEmailValid(email) {
    if (email) {
      if (email.length >= 5) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
      }
    }
  }
  function isPasswordMatched(a, b) {
    if (a == b) {
      return true;
    }
  }
  function isPasswordValid(pwd) {
    return (/(?=^.{6,32}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(pwd)
    );
  }

  exports.default = _emberData.default.Model.extend({
    organization: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirm: _emberData.default.attr('string'),
    profile: _emberData.default.belongsTo('registration-profile'),
    isPasswordValid: Ember.computed('password', function () {
      let pwd = this.get('password');
      return isPasswordValid(pwd);
    }),
    isEmailValid: Ember.computed('email', function () {
      let email = this.get('email');
      return isEmailValid(email);
    }),
    isPasswordMatched: Ember.computed('password', 'passwordConfirm', function () {
      let password = this.get('password');
      let confirmPassword = this.get('passwordConfirm');
      return isPasswordMatched(password, confirmPassword);
    }),
    isValidSignup: Ember.computed('isEmailValid', 'isPasswordValid', 'passwordConfirm', function () {
      let isEmailValid = this.get('isEmailValid');
      let isPasswordValid = this.get('isPasswordValid');
      let isPasswordMatched = this.get('isPasswordMatched');

      if (isEmailValid && isPasswordValid && isPasswordMatched) {
        return true;
      }
    })
  });
});