define('yxt-apps/initializers/pollboy', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(app) {
    app.inject('route', 'pollboy', 'service:pollboy');
  }

  exports.default = {
    name: 'pollboy',
    initialize
  };
});