define('yxt-apps/helpers/humanize-storage', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.humanizeStorage = humanizeStorage;
    function humanizeStorage(params /*, hash*/) {
        let [bytes, si] = params;

        if (bytes === 'Unlimited') {
            return 'Unlimited';
        }
        var thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si ? ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'] : ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    }

    exports.default = Ember.Helper.helper(humanizeStorage);
});