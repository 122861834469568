define('yxt-apps/services/state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    pagetitle: '',
    displayMode: 'grid',
    isDisplayModeGrid: Ember.computed('displayMode', function () {
      let displayMode = this.get('displayMode');
      if (displayMode == 'grid') {
        return true;
      }
    }),
    isDisplayModeList: Ember.computed('displayMode', function () {
      let displayMode = this.get('displayMode');
      if (displayMode == 'list') {
        return true;
      }
    }),
    isLoading: true,
    isEditMode: false,
    isInfoExpanded: false,
    isCreationFlowActive: false,
    studyCreationStep: 'creation',

    // some flags to disable features/options
    isParticipantTypeExpressDisabled: true,
    isParticipantTypeMyOwnDisabled: false,
    isParticipantTypePremiumDisabled: true,

    // error states
    isError: false,
    isErrorDismissable: true,
    errorMessage: null,

    isReportProblem: false,
    isGiveFeedback: false,
    isDisplayConsentModal: false,
    isRemoveParticipantFromStudyModal: false,
    init() {
      let displayMode = window.localStorage.getItem('displayMode');
      if (displayMode) {
        this.set('displayMode', displayMode);
      } else {
        this.set('displayMode', 'grid');
      }
    },
    savePref(prop, val) {
      window.localStorage.setItem(prop, val);
      this.set(prop, val);
      return false;
    },
    openModal(name) {
      this.set(name, true);
    },
    closeModal() {
      this.set('isReportProblem', false);
      this.set('isGiveFeedback', false);
      this.set('isDisplayConsentModal', false);
      this.set('isRemoveParticipantFromStudyModal', false);
    },
    tinymceOpts: {
      height: 350,
      plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste"],
      toolbar: "insertfile undo redo | styleselect | bold italic | forecolor backcolor | alignleft               aligncenter alignright alignjustify | bullist numlist outdent indent | link image"
    }
  });
});