define('yxt-apps/serializers/role', ['exports', 'yxt-apps/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      tenantId: { serialize: false },
      organizationMember: { serialize: false },
      modifiedAt: { serialize: false },
      createdAt: { serialize: false }
    },
    modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'data') {
        let modelName = 'role';
        return this._super(payloadKey.replace('data', modelName));
      } else {
        return this._super(payloadKey);
      }
    }
  });
});