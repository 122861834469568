define('yxt-apps/models/study-worker-data', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    hitId: _emberData.default.attr('string'),
    requirements: _emberData.default.hasMany('mturk-requirement'),
    lifetimeInSeconds: _emberData.default.attr('number', { defaultValue: 1814400 }), // 1814400 = 3 weeks
    assignmentDurationInSeconds: _emberData.default.attr('number', { defaultValue: 3600 }), // 60min * 60seconds = 3600
    reward: _emberData.default.attr('string', { defaultValue: '2.50' }),
    study: _emberData.default.belongsTo('study')
  });
});