define('yxt-apps/helpers/journal-entry-result-timespan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.journalEntryResultTimespan = journalEntryResultTimespan;
  function journalEntryResultTimespan(params /*, hash*/) {
    let [results] = params;
    let count = 0;
    results.forEach(function (result) {
      //
      result.get('entries.StudyEntry').forEach(function (entry) {
        count++;
      });
    });
    return count;
  }

  exports.default = Ember.Helper.helper(journalEntryResultTimespan);
});