define('yxt-apps/adapters/ford-html-report', ['exports', 'yxt-apps/adapters/application', 'ember-data', 'ember-cli-form-data/mixins/form-data-adapter', 'yxt-apps/config/environment'], function (exports, _application, _emberData, _formDataAdapter, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend(_formDataAdapter.default, {
        host: function () {
            return `${_environment.default.reportApiHost}`;
        }.property(),
        pathForType: function () {
            // hardcode
            return 'reports';
        }
    });
});