define('yxt-apps/services/me', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        isDemographicsEnabled: false,
        isShareReportEnabled: true,
        currency: 'USD',
        language: 'en',
        isLangPrefAllowed: false,
        isCurrencyUSD: Ember.computed('currency', function () {
            let currency = this.get('currency');
            if (currency == 'USD') {
                return true;
            }
        }),
        isCurrencyEUR: Ember.computed('currency', function () {
            let currency = this.get('currency');
            if (currency == 'EUR') {
                return true;
            }
        }),
        isCurrencyGBP: Ember.computed('currency', function () {
            let currency = this.get('currency');
            if (currency == 'GBP') {
                return true;
            }
        }),
        isCurrencySEK: Ember.computed('currency', function () {
            let currency = this.get('currency');
            if (currency == 'SEK') {
                return true;
            }
        }),

        currencySymbol: Ember.computed('currency', function () {
            let currency = this.get('currency');
            if (currency == 'EUR') {
                return '€';
            } else if (currency == 'GBP') {
                return '£';
            } else if (currency == 'SEK') {
                return 'kr';
            } else {
                return '$';
            }
        }),
        invalidateAll() {
            window.localStorage.removeItem('username');
            window.localStorage.removeItem('currentId');
            window.localStorage.removeItem('organizationId');
            window.localStorage.removeItem('currentEmail');
            window.localStorage.removeItem('firstName');
            window.localStorage.removeItem('lastName');
            return false;
        },
        username: Ember.computed(function () {
            if (window.localStorage.getItem('username')) {
                return window.localStorage.getItem('username');
            }
        }),

        id: Ember.computed(function () {
            if (window.localStorage.getItem('currentId')) {
                return window.localStorage.getItem('currentId');
            }
        }),
        organizationId: Ember.computed(function () {
            if (window.localStorage.getItem('organizationId')) {
                return window.localStorage.getItem('organizationId');
            }
        }),
        email: Ember.computed(function () {
            if (window.localStorage.getItem('currentEmail')) {
                return window.localStorage.getItem('currentEmail');
            }
        }),
        firstName: Ember.computed(function () {
            if (window.localStorage.getItem('firstName')) {
                return window.localStorage.getItem('firstName');
            }
        }),
        lastName: Ember.computed(function () {
            if (window.localStorage.getItem('lastName')) {
                return window.localStorage.getItem('lastName');
            }
        }),
        fullName: Ember.computed('firstName', 'lastName', function () {
            let firstName = this.get('firstName');
            let lastName = this.get('lastName');
            return `${firstName} ${lastName}`;
        }),
        userNotes: Ember.computed(function () {
            if (window.localStorage.getItem('userNotes')) {
                return window.localStorage.getItem('userNotes');
            }
        }),
        sharedToken() {
            if (window.localStorage.getItem('sharedToken')) {
                return window.localStorage.getItem('sharedToken');
            } else {
                return false;
            }
        },
        primaryProject: Ember.computed(function () {
            if (window.localStorage.getItem('primaryProject')) {
                return window.localStorage.getItem('primaryProject');
            } else {
                return false;
            }
        }),
        appName() {
            if (window.localStorage.getItem('yxtAppName')) {
                return window.localStorage.getItem('yxtAppName');
            } else {
                return 'reaction';
            }
        },
        yxtAppName: Ember.computed(function () {
            if (window.localStorage.getItem('yxtAppName')) {
                return window.localStorage.getItem('yxtAppName');
            } else {
                return 'reaction';
            }
        }),
        isSuperAdmin: Ember.computed(function () {
            if (window.localStorage.getItem('ember_simple_auth-session')) {
                let authcookie = window.localStorage.getItem('ember_simple_auth-session');
                let auth = JSON.parse(authcookie);
                if (auth.authenticated.identity) {
                    let roleId = auth.authenticated.identity.roles[0];
                    if (roleId == '59c2f6978b0a3906bbfa7b23') {
                        this.set('isDemographicsEnabled', true); // enable the demographic selection
                        this.set('isShareReportEnabled', true); // enable the sharing feature
                        return true;
                    }
                }
            }
        }),
        isAuth: Ember.computed(function () {
            if (window.localStorage.getItem('ember_simple_auth-session')) {
                let authcookie = window.localStorage.getItem('ember_simple_auth-session');
                let auth = JSON.parse(authcookie);
                if (auth.authenticated.length > 0) {
                    return true;
                }
            }
        }),
        orgStored: 0,
        orgCapacity: 0,
        storageCapacity: Ember.computed(function () {
            if (window.localStorage.getItem('orgCapacity')) {
                return window.localStorage.getItem('orgCapacity');
            } else {
                return 0;
            }
        }),
        storageUsed: Ember.computed(function () {
            if (window.localStorage.getItem('orgStored')) {
                return window.localStorage.getItem('orgStored');
            } else {
                return 0;
            }
        }),
        storagePercentageUsed: Ember.computed(function () {
            let total = window.localStorage.getItem('orgCapacity');
            let used = window.localStorage.getItem('orgStored');
            return (used / total * 100).toFixed(0);
        }),
        orgId: Ember.computed(function () {
            if (window.localStorage.getItem('organizationId')) {
                return window.localStorage.getItem('organizationId');
            } else {
                return false;
            }
        }),
        init() {
            let username = window.localStorage.getItem('username');
            this.set('username', username);
            let firstName = window.localStorage.getItem('firstName');
            this.set('firstName', firstName);
            let lastName = window.localStorage.getItem('lastName');
            this.set('username', lastName);
            let id = window.localStorage.getItem('currentId');
            this.set('id', id);
            let email = window.localStorage.getItem('currentEmail');
            this.set('email', email);
        },

        saveUsername(username) {
            window.localStorage.setItem('username', username);
            this.set('username', username);
            return false;
        },
        saveEmail(email) {
            window.localStorage.setItem('currentEmail', email);
            this.set('email', email);
            return false;
        },
        saveFirstName(firstName) {
            window.localStorage.setItem('firstName', firstName);
            this.set('firstName', firstName);
            return false;
        },
        saveLastName(lastName) {
            window.localStorage.setItem('lastName', lastName);
            this.set('lastName', lastName);
            return false;
        },

        saveUserNotes(notes) {
            window.localStorage.setItem('userNotes', notes);
            this.set('userNotes', notes);
            return false;
        },
        saveId(id) {
            window.localStorage.setItem('currentId', id);
            this.set('id', id);
            return false;
        },
        saveOrganizationId(id) {
            window.localStorage.setItem('organizationId', id);
            this.set('organizationId', id);
            return false;
        },
        saveStorageUsed(stored) {
            window.localStorage.setItem('orgStored', stored);
            this.set('orgStored', stored);
            return false;
        },
        saveStorageCapacity(capacity) {
            window.localStorage.setItem('orgCapacity', capacity);
            this.set('orgCapacity', capacity);
            return false;
        },
        setCurrency(currency) {
            window.localStorage.setItem('currency', currency);
            this.set('currency', currency);
            return false;
        },
        saveSharedToken(token) {
            window.localStorage.setItem('sharedToken', token);
            this.set('sharedToken', token);
            return false;
        },
        savePrimaryProject(id) {
            window.localStorage.setItem('primaryProject', id);
            this.set('primaryProject', id);
            return false;
        },
        saveAppName(name) {
            window.localStorage.setItem('yxtAppName', name);
            this.set('yxtAppName', name);
            return false;
        }
    });
});