define('yxt-apps/routes/reaction/studies/study/participants/participant', ['exports', 'yxt-apps/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _environment, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = { title: `${pageName} Overview` };
            return breadCrumbTitle;
        }),
        model(params) {
            let parentModel = this.modelFor('reaction.studies.study');
            let studyParticipant = this.get('store').findRecord('study-participant', params.participant_id);
            return Ember.RSVP.hash({
                studyParticipant: studyParticipant,
                study: parentModel.study
            });
        }
    });
});