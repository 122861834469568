define('yxt-apps/routes/journal/studies/study/create/setup/entries', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    let self = undefined;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = {
                title: `${pageName} - Add Entries`
            };
            return breadCrumbTitle;
        }),
        model() {
            self = this;
            this.get('state').set('studyCreationStep', 'entries');

            let studyModel = this.modelFor('journal.studies.study');
            let study = studyModel.study;

            let studyJournalTasks = this.get('store').query('study-journal-task', {
                studyId: study.id
            }).then(function (studyJournalTasks) {
                if (studyJournalTasks.get('length') == 0) {

                    let newJournalQuestions = self.get('store').createRecord('study-journal-task', {
                        name: 'entry_1',
                        study: study
                    });
                    let newPage = self.get('store').createRecord('page-template', {
                        name: 'Entry_1',
                        title: 'Entry_1',
                        isEditing: true,
                        studyJournalTask: newJournalQuestions,
                        content: [{
                            name: 'Entry_1',
                            contentType: 'TextEntry',
                            metadata: {
                                isMandatory: true,
                                question: ''
                            }
                        }]
                    });
                    return [newJournalQuestions];
                }
                return studyJournalTasks;
            });

            return Ember.RSVP.hash({
                study: study,
                studyJournalTasks: studyJournalTasks
            });
        },
        afterModel: function (transition) {
            Ember.$('body > div.ember-view').animate({
                scrollTop: 0
            }, 500);
            this._super(transition);
        },
        actions: {
            willTransition() {
                let self = this;

                function cleanup() {
                    let allPages = self.get('store').peekAll('page-template');
                    allPages.toArray().forEach(function (page) {
                        if (page.get('id')) {
                            // do nothing
                        } else {
                            page.unloadRecord();
                        }
                    });
                    let allContent = self.get('store').peekAll('content');
                    allContent.toArray().forEach(function (content) {
                        if (content.get('id')) {
                            // do nothing
                        } else {
                            content.unloadRecord();
                        }
                    });
                    let allContentMeta = self.get('store').peekAll('content-meta');
                    allContentMeta.toArray().forEach(function (meta) {
                        if (meta.get('id')) {
                            // do nothing
                        } else {
                            meta.unloadRecord();
                        }
                    });
                    let allChoices = self.get('store').peekAll('question-choice');
                    allChoices.toArray().forEach(function (choice) {
                        if (choice.get('id')) {
                            // do nothing
                        } else {
                            choice.unloadRecord();
                        }
                    });
                }
                let studyJournalTasks = this.controller.get('model.studyJournalTasks');
                //studyJournalTasks.save().then(cleanup);
                studyJournalTasks.forEach(function (ps) {
                    if (ps.get('pages.length') > 0) {
                        ps.save().then(cleanup);
                    }
                });
            }
        }
    });
});