define('yxt-apps/services/l10n', ['exports', 'ember-l10n/services/l10n'], function (exports, _l10n) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _l10n.default.extend({
    /**
     * Defines available locales as hash map, where key corresponds
     * to ISO_639-1 country codes and value can be any truthy value.
     * By default, it's used to translate the language codes, which
     * could be used for a language drop down. Adjust the hash map
     * for each new language being added your translatable project.
     *
     * @property availableLocales
     * @type {object}
     * @public
     */
    availableLocales: Ember.computed('locale', function () {
      return {
        'en': 'English'
      };
    }),

    /**
     * Flag indicating if service should try to detect user langugage
     * from browser settings and load/set the corresponding JSON file.
     *
     * @property autoInitialize
     * @type {boolean}
     * @public
     */
    autoInitialize: true

  });
});