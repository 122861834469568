define('yxt-apps/routes/admin/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    breadCrumb: null,
    beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('admin.dashboard');
      } else {
        this.transitionTo('admin.login');
      }
    }

  });
});