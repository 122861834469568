define('yxt-apps/routes/journal/studies/study/results/participant', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = { title: `${pageName} Results` };
            return breadCrumbTitle;
        }),
        model(params) {
            let parentModel = this.modelFor('journal.studies.study');
            let selectedParticipant = this.get('store').peekRecord('participant-result', params.participant_id);

            let emotions = [];
            let postquestions = selectedParticipant.get('entries.PostSessionQuestions');
            let entries = selectedParticipant.get('entries.StudyEntry');
            if (entries) {
                entries = entries.sortBy('properties.entryDate');

                for (let i = 0; i < entries.length; i++) {

                    emotions.push(entries[i].properties.emotions);

                    let firstMediaQuestionName = '';
                    let result = '';
                    for (let j = 0; j < entries[i].pages.length; j++) {
                        entries[i].thumbnail = '';

                        let contentTemp = entries[i].pages[j].templateId.content[0];
                        if (contentTemp.contentType == 'Media' && result == '') {
                            firstMediaQuestionName = contentTemp.name;
                            if (entries[i].pages[j].content[firstMediaQuestionName].thumbnail != undefined) {
                                result = entries[i].pages[j].content[firstMediaQuestionName].thumbnail.url;
                            } else {
                                result = entries[i].pages[j].content[firstMediaQuestionName].url;
                            }
                        }

                        //sanitize the results
                        if (result != '') {
                            let tmp = result.split(".");
                            let ext = tmp[tmp.length - 1];
                            if (ext != "jpeg" && ext != "jpg" && ext != "png" && ext != "gif") {
                                result = '';
                            } else {
                                entries[i].thumbnail = result;
                                break;
                            }
                        }
                    }
                }
            }
            return Ember.RSVP.hash({
                participantResults: parentModel.participantResults,
                selectedParticipant: selectedParticipant,
                selectedParticipantEntries: entries,
                selectedParticipantPostQuestions: postquestions,
                selectedEntryPages: null,
                selectedEntryIndex: null,
                studyEntryViewType: "ENTRY", //ENTRY | EMOTION
                emotions: emotions,
                studyTypes: parentModel.studyTypes,
                study: parentModel.study

            });
        },

        actions: {
            didTransition() {
                // override the default scroll up behavior
                this.set('state.isLoading', false);
            },
            changeStudyEntryView(view) {
                this.set('currentModel.studyEntryViewType', view);
            }
        }
    });
});