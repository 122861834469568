define('yxt-apps/components/chart/you-x-score', ['exports', 'yxt-apps/helpers/format-score'], function (exports, _formatScore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let easeColors = { 'red': '#E33E43', 'orange': '#F08601', 'yellow': '#F0E507', 'lime': '#81C23E', 'green': '#2A7F2A' };
  let emotionColors = { 'red': '#E33E43', 'orange': '#F08601', 'yellow': '#F0E507', 'lime': '#81C23E', 'green': '#2A7F2A' };
  let satisfactionColors = { 'red': '#E33E43', 'orange': '#F08601', 'yellow': '#F0E507', 'lime': '#81C23E', 'green': '#2A7F2A' };

  let totalArcPercent = 70;
  let arcEmptyPercent = 100 - totalArcPercent;

  let easeArc = d3.arc().outerRadius(110).innerRadius(100).cornerRadius(8);

  let easeArcHighlighted = d3.arc().outerRadius(112).innerRadius(99).cornerRadius(8);

  let satisfactionArc = d3.arc().outerRadius(90).innerRadius(80).cornerRadius(8);

  let satisfactionArcHighlighted = d3.arc().outerRadius(92).innerRadius(79).cornerRadius(8);

  let emotionArc = d3.arc().outerRadius(70).innerRadius(60).cornerRadius(8);

  let emotionArcHighlighted = d3.arc().outerRadius(72).innerRadius(59).cornerRadius(8);

  function setColorDefinition(props) {
    props = props || {};
    props.score = props.score || 2;
    props.multiplier = props.multiplier || 1;
    props.score = props.score * props.multiplier;
    return props.score < 1 ? '#E33E43' : props.score < 2 ? '#F08601' : props.score < 3 ? '#F0E507' : props.score < 4 ? '#81C23E' : '#2A7F2A';
  }
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['chart-widget'],
    satisfaction: 0,
    ease: 0,
    emotion: 0,
    score: 0,
    max: 5,
    min: 0,
    id: 'youxscore',
    previousScore: 0,
    previousBaseColor: '',

    didInsertElement() {
      let width = 300;
      let height = 250;
      let max = this.get('max');
      let id = this.get('id');

      let rotationAngle = 180 + arcEmptyPercent / 100 * 360 / 2;
      let translateParam = "translate(150,115)rotate(" + rotationAngle + ")";

      // Create initial empty arc before animation
      let zeroData = [0, totalArcPercent, arcEmptyPercent];
      let zeroPie = d3.pie().sort(null)(zeroData);

      let svg = d3.select(`#${id}`).attr('width', width).attr('height', height);

      // Overall Score

      let score = this.get('score');
      let baseColor = setColorDefinition({ score: score, multiplier: 1 });

      svg.append("g").attr("id", "overallScore").append("text").text(`${score / 2}`).attr("id", "overallScoreText").attr("x", "50%").attr("y", 125).attr("text-anchor", "middle");

      // Ease Score

      let easeScore = this.get('ease');
      let easePercent = easeScore / max * totalArcPercent;
      let easeData = [easePercent, totalArcPercent - easePercent, arcEmptyPercent];

      let easeColor = setColorDefinition({ score: easeScore });
      let easeArcColors = d3.scaleOrdinal().range([easeColor, '#EFEFEF', 'transparent']);

      let easeGroup = svg.append("g").attr("transform", translateParam);
      // Draw unfilled arc
      let g = easeGroup.selectAll(".easeArc").data(zeroPie).enter().append("g").attr("class", "easeArc");

      g.append("path").attr("d", easeArc).attr("fill", function (d, i) {
        return easeArcColors(i);
      }).each(function (d) {
        this._current = d;
      });

      // Use animation to draw filled arc over time
      let easePie = d3.pie().sort(null)(easeData);
      let easePaths = svg.selectAll(".easeArc path").data(easePie);
      easePaths.transition().duration(2000).attrTween("d", function (a) {
        let i = d3.interpolate(this._current, a);
        this._current = i(1);
        return function (t) {
          return easeArc(i(t));
        };
      });

      // When user mouses over legend item, highlight the corresponding arc
      Ember.$(".chart-legend").on("mouseover", "#ease-legend-item", function () {
        d3.select(".easeArc path").transition().duration(500).attr("d", easeArcHighlighted);
      });
      Ember.$(".chart-legend").on("mouseout", "#ease-legend-item", function () {
        d3.select(".easeArc path").transition().duration(500).attr("d", easeArc);
      });

      Ember.$(".chart-legend #ease-legend-item .indicator").css("background", easeColor);

      // Satisfaction Score

      let satisfactionScore = this.get('satisfaction');
      let satisfactionPercent = satisfactionScore / max * totalArcPercent;
      let satisfactionData = [satisfactionPercent, totalArcPercent - satisfactionPercent, arcEmptyPercent];

      let satisfactionColor = setColorDefinition({ score: satisfactionScore });
      let satisfactionArcColors = d3.scaleOrdinal().range([satisfactionColor, '#EFEFEF', 'transparent']);

      let satisfactionGroup = svg.append("g").attr("transform", translateParam);

      // Draw unfilled arc
      g = satisfactionGroup.selectAll(".satisfactionArc").data(zeroPie).enter().append("g").attr("class", "satisfactionArc");

      g.append("path").attr("d", satisfactionArc).attr("fill", function (d, i) {
        return satisfactionArcColors(i);
      }).each(function (d) {
        this._current = d;
      });

      // Use animation to draw filled arc over time
      let satisfactionPie = d3.pie().sort(null)(satisfactionData);
      let satisfactionPaths = svg.selectAll(".satisfactionArc path").data(satisfactionPie);
      satisfactionPaths.transition().duration(2000).attrTween("d", function (a) {
        let i = d3.interpolate(this._current, a);
        this._current = i(1);
        return function (t) {
          return satisfactionArc(i(t));
        };
      });

      // When user mouses over legend item, highlight the corresponding arc
      Ember.$(".chart-legend").on("mouseover", "#satisfaction-legend-item", function () {
        d3.select(".satisfactionArc path").transition().duration(500).attr("d", satisfactionArcHighlighted);
      });
      Ember.$(".chart-legend").on("mouseout", "#satisfaction-legend-item", function () {
        d3.select(".satisfactionArc path").transition().duration(500).attr("d", satisfactionArc);
      });

      Ember.$(".chart-legend #satisfaction-legend-item .indicator").css("background", satisfactionColor);

      // Emotion Score

      let emotionScore = this.get('emotion');
      let emotionPercent = emotionScore / max * totalArcPercent;
      let emotionData = [emotionPercent, totalArcPercent - emotionPercent, arcEmptyPercent];

      let emotionColor = setColorDefinition({ score: emotionScore });
      let emotionArcColors = d3.scaleOrdinal().range([emotionColor, '#EFEFEF', 'transparent']);

      let emotionGroup = svg.append("g").attr("transform", translateParam);

      // Draw unfilled arc
      g = emotionGroup.selectAll(".emotionArc").data(zeroPie).enter().append("g").attr("class", "emotionArc");

      g.append("path").attr("d", emotionArc).attr("fill", function (d, i) {
        return emotionArcColors(i);
      }).each(function (d) {
        this._current = d;
      });

      // Use animation to draw filled arc over time
      let emotionPie = d3.pie().sort(null)(emotionData);
      let emotionPaths = svg.selectAll(".emotionArc path").data(emotionPie);
      emotionPaths.transition().duration(2000).attrTween("d", function (a) {
        let i = d3.interpolate(this._current, a);
        this._current = i(1);
        return function (t) {
          return emotionArc(i(t));
        };
      });

      // When user mouses over legend item, highlight the corresponding arc
      Ember.$(".chart-legend").on("mouseover", "#emotion-legend-item", function () {
        d3.select(".emotionArc path").transition().duration(500).attr("d", emotionArcHighlighted);
      });
      Ember.$(".chart-legend").on("mouseout", "#emotion-legend-item", function () {
        d3.select(".emotionArc path").transition().duration(500).attr("d", emotionArc);
      });

      Ember.$(".chart-legend #emotion-legend-item .indicator").css("background", emotionColor);

      this.set('previousScore', score);
      this.set('previousBaseColor', baseColor);
    },

    didUpdateAttrs() {
      let score = this.get('score');
      if (typeof score === "string") score = parseFloat(score);
      let previousScore = this.get('previousScore');
      if (typeof previousScore === "string") previousScore = parseFloat(previousScore);

      // Only update if the value is different
      if (score !== previousScore) {

        let id = this.get('id');
        let svg = d3.select(`#${id}`);

        let max = this.get('max');

        let baseColor = setColorDefinition({ score: score, multiplier: 1 });

        // Update Ease Score

        let easeScore = this.get('ease');
        let easePercent = easeScore / max * totalArcPercent;
        let easeData = [easePercent, totalArcPercent - easePercent, arcEmptyPercent];
        let easePie = d3.pie().sort(null)(easeData);
        let easeColor = setColorDefinition({ score: easeScore });
        //let easeArcColors = d3.scaleOrdinal().range([easeColor, '#EFEFEF', 'transparent']);
        let easePaths = svg.selectAll(".easeArc path").data(easePie);
        easePaths.transition().duration(2000).attrTween("d", function (a) {
          let i = d3.interpolate(this._current, a);
          this._current = i(1);
          return function (t) {
            return easeArc(i(t));
          };
        });
        Ember.$(".chart-legend #ease-legend-item .score-val").html((0, _formatScore.formatScore)([easeScore, 2]));

        // Update Satisfaction Score

        let satisfactionScore = this.get('satisfaction');
        let satisfactionPercent = satisfactionScore / max * totalArcPercent;
        let satisfactionData = [satisfactionPercent, totalArcPercent - satisfactionPercent, arcEmptyPercent];
        let satisfactionPie = d3.pie().sort(null)(satisfactionData);
        let satisfactionColor = setColorDefinition({ score: satisfactionScore });
        //let satisfactionArcColors = d3.scaleOrdinal().range([satisfactionColor, '#EFEFEF', 'transparent']);
        let satisfactionPaths = svg.selectAll(".satisfactionArc path").data(satisfactionPie);
        satisfactionPaths.transition().duration(2000).attrTween("d", function (a) {
          let i = d3.interpolate(this._current, a);
          this._current = i(1);
          return function (t) {
            return satisfactionArc(i(t));
          };
        });
        Ember.$(".chart-legend #satisfaction-legend-item .score-val").html((0, _formatScore.formatScore)([satisfactionScore, 2]));

        // Update Emotion Score

        let emotionScore = this.get('emotion');
        let emotionPercent = emotionScore / max * totalArcPercent;
        let emotionData = [emotionPercent, totalArcPercent - emotionPercent, arcEmptyPercent];
        let emotionPie = d3.pie().sort(null)(emotionData);
        let emotionColor = setColorDefinition({ score: emotionScore });
        //let emotionArcColors = d3.scaleOrdinal().range([emotionColor, '#EFEFEF', 'transparent']);
        let emotionPaths = svg.selectAll(".emotionArc path").data(emotionPie);
        emotionPaths.transition().duration(2000).attrTween("d", function (a) {
          let i = d3.interpolate(this._current, a);
          this._current = i(1);
          return function (t) {
            return emotionArc(i(t));
          };
        });
        Ember.$(".chart-legend #emotion-legend-item .score-val").html((0, _formatScore.formatScore)([emotionScore, 2]));

        // Update Overall Score and color coding of bars

        svg.select("#overallScore").transition().duration(2000).tween("overallScoreText", function () {
          let i = d3.interpolate(previousScore, score);
          return function (t) {
            let newScore = i(t);
            svg.select("#overallScoreText").text(i(t).toFixed(1));
            let baseColor = setColorDefinition({ score: score, multiplier: 1 });
            let easeColor = setColorDefinition({ score: easeScore });
            let easeArcColors = d3.scaleOrdinal().range([easeColor, '#EFEFEF', 'transparent']);
            easePaths.attr("fill", function (d, i) {
              return easeArcColors(i);
            });
            Ember.$(".chart-legend #ease-legend-item .indicator").css("background", easeColor);
            let satisfactionColor = setColorDefinition({ score: satisfactionScore });
            let satisfactionArcColors = d3.scaleOrdinal().range([satisfactionColor, '#EFEFEF', 'transparent']);
            satisfactionPaths.attr("fill", function (d, i) {
              return satisfactionArcColors(i);
            });
            Ember.$(".chart-legend #satisfaction-legend-item .indicator").css("background", satisfactionColor);
            let emotionColor = setColorDefinition({ score: emotionScore });
            let emotionArcColors = d3.scaleOrdinal().range([emotionColor, '#EFEFEF', 'transparent']);
            emotionPaths.attr("fill", function (d, i) {
              return emotionArcColors(i);
            });
            Ember.$(".chart-legend #emotion-legend-item .indicator").css("background", emotionColor);
          };
        });

        this.set('previousScore', this.get('score'));
        this.set('previousBaseColor', baseColor);
      }
    }

  });
});