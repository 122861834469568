define('yxt-apps/routes/admin/study-feature-templates/study-feature-template', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    newFeatureTemplate: '',
    model(params) {
      this.set('newFeatureTemplate', this.get('store').findRecord('study-feature-template', params.studyFeatureTemplate_id));
      return this.get('newFeatureTemplate');
    },
    actions: {
      saveFeatureTemplate(template) {
        template.save();
      }
    }
  });
});