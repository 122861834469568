define('yxt-apps/routes/reaction/confirm-email', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'yxt-apps/routes/config/environment'], function (exports, _unauthenticatedRouteMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    let self = undefined;
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        isConfirmed: false,
        isAlreadyConfirmed: false,
        breadCrumb: {
            title: 'Confirming your email'
        },
        model(params) {
            self = this;

            Ember.$.ajax({
                url: `${_environment.default.host}/${_environment.default.namespace}/activateAccount/?token=${params.activateAccount_id}`,
                method: 'POST',
                data: { 'token': params.activateAccount_id }
            }).done(function () {
                self.set('currentModel.isConfirmed', true);
            }).catch(function (response) {
                if (response.responseJSON) {
                    if (response.responseJSON.name) {
                        if (response.responseJSON.name === 'DuplicatedItemIdError') {
                            self.set('currentModel.isConfirmed', true);
                            self.set('currentModel.isAlreadyConfirmed', true);
                        }
                    }
                }
            });

            return Ember.RSVP.hash({
                isConfirmed: this.get('isConfirmed'),
                isAlreadyConfirmed: this.get('isAlreadyConfirmed')
            });
        }
    });
});