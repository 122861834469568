define('yxt-apps/helpers/humanize-ease-rating', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeEaseRating = humanizeEaseRating;
  function humanizeEaseRating(params /*, hash*/) {
    let [rating] = params;
    if (rating === 1) {
      return 'Very difficult';
    } else if (rating === 2) {
      return 'Difficult';
    } else if (rating === 3) {
      return 'Neutral';
    } else if (rating === 4) {
      return 'Easy';
    } else if (rating === 5) {
      return 'Very easy';
    }
  }

  exports.default = Ember.Helper.helper(humanizeEaseRating);
});