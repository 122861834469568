define('yxt-apps/routes/admin/organizations/organization/studies/study/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

        afterModel(transition) {

            Ember.$('body > div.ember-view').animate({
                scrollTop: 0
            }, 500);
            this._super(transition);
        },

        actions: {

            addParticipant() {
                let study = this.controller.get('model.study');
                let person = this.get('store').createRecord('participant', {
                    isEditing: true
                });
                this.get('store').createFragment('profile', {
                    participant: person
                });
                this.get('store').createRecord('study-participant', {
                    study: study,
                    participant: person,
                    isEditing: true
                });
            },
            readCSVfile(file) {
                let study = this.controller.get('model.study');
                let self = this;
                let participantTemplate = self.get('store').peekRecord('study-participant-template', self.get('config.participantMyOwnTemplateId'));

                Ember.$("input[type=file]").parse({
                    config: {
                        header: true,
                        complete: function (results, file) {
                            results.data.forEach(function (newPerson) {
                                let person = self.get('store').createRecord('participant', {
                                    isEditing: false,
                                    template: participantTemplate,
                                    email: newPerson.email
                                });
                                person.set('profile', self.get('store').createFragment('profile', {
                                    firstName: newPerson.firstName,
                                    lastName: newPerson.lastName,
                                    email: newPerson.email
                                }));

                                person.save().then(function (newb) {
                                    let studyParticipantRecord = self.get('store').createRecord('study-participant', {
                                        study: study,
                                        participant: newb,
                                        isEditing: false
                                    });
                                    studyParticipantRecord.save();
                                });
                            });
                        }
                    }
                });
            }
        }
    });
});