define('yxt-apps/helpers/convert-percent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convertPercent = convertPercent;
  function convertPercent(params) {
    let [part, total] = params;
    let percent = (part / total * 100).toFixed(0);

    return percent;
  }

  exports.default = Ember.Helper.helper(convertPercent);
});