define('yxt-apps/routes/reaction/logout', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel() {
            this.get('session').invalidate();
        }
    });
});