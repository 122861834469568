define('yxt-apps/components/item-generator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['control-generator', 'task-creation-panel'],
    internalItems: [],
    itemType: 'page',
    itemStore: null,
    itemStoreModelName: null,
    actions: {
      editItem(item) {
        let tasks = this.get('store').peekAll(this.get('itemType'));
        tasks.setEach('isEditing', false);
        item.set('isEditing', true);
      },
      deleteItem(item) {
        // if we are editing...
        if (item.isEditing) {
          // figure out the previous or next in the list and set to editing/active
        }

        item.deleteRecord();
        item.get('isDeleted');
        item.save();
      },
      addItem() {
        let tasks = this.get('store').peekAll(this.get('itemType'));
        tasks.setEach('isEditing', false);
        let itemStore = this.get('itemStore');
        let itemType = this.get('itemType');
        let newItem = this.get('store').createRecord(itemType, {
          studyWebsiteTask: itemStore,
          isEditing: true
        });
        let newPage = this.get('store').createRecord('page-template', {
          task: newItem
        });
        this.get('store').createRecord('page-content', {
          page: newPage
        });
      }
    }
  });
});