define('yxt-apps/serializers/study-journal-task', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            modifiedAt: {
                serialize: false
            },
            createdAt: {
                serialize: false
            },
            pages: {
                embedded: 'always'
            }
        },
        modelNameFromPayloadKey(payloadKey) {
            if (payloadKey === 'data') {
                let modelName = 'study-journal-task';
                return this._super(payloadKey.replace('data', modelName));
            } else {
                return this._super(payloadKey);
            }
        },

        normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            if (!payload.data) {
                payload = {
                    data: payload
                };
            }
            if (payload.data) {
                payload.data.forEach(function (val) {
                    val.type = 'study-journal-task';

                    let studyJournalTaskId = val.id;

                    if (val.pages) {
                        val.pages.forEach(function (page, key) {

                            if (typeof page == 'object') {
                                if (!page.id) {
                                    page.id = `${studyJournalTaskId}_wtpg_${key}`;
                                }
                                if (!page.type) {
                                    page.type = 'page-template';
                                }

                                if (page.content) {
                                    page.content.forEach(function (content, key) {
                                        if (!content.id || content.id == null) {
                                            content.id = `${page.id}_wtpgct_${key}`;
                                            content.page = page.id;
                                        }

                                        if (content.metadata) {
                                            content.metadata.type = "content-meta";
                                            content.metadata.id = `${content.id}_meta_${key}`;

                                            if (content.metadata.body) {
                                                content.metadata.body.forEach(function (choice, key) {
                                                    if (!choice.id) {
                                                        choice.id = `${content.metadata.id}_choice_${key}`;
                                                    }
                                                    if (!choice.type) {
                                                        choice.type = 'question-choice';
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            }

            if (requestType == 'updateRecord') {
                payload = payload.data[0];
            }
            if (requestType == 'saveRecord') {
                payload = payload.data[0];
            }
            if (requestType == 'save') {
                payload = payload.data[0];
            }
            if (requestType == 'createRecord') {
                payload = payload.data[0];
            }

            return this._super(store, primaryModelClass, payload, id, requestType);
        }

    });
});