define('yxt-apps/models/study-participant', ['exports', 'ember-data', 'yxt-apps/config/environment', 'ember-api-actions'], function (exports, _emberData, _environment, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    participant: _emberData.default.belongsTo("participant"),
    study: _emberData.default.belongsTo("study"),
    studyData: _emberData.default.attr(),
    studyEntries: _emberData.default.hasMany('study-entry'),
    workerData: _emberData.default.attr(),
    workflowType: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    modifiedAt: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    tempKey: _emberData.default.attr(),
    wasReminderSent: _emberData.default.attr('boolean', { defaultValue: false }),
    isViewing: _emberData.default.attr('boolean', { defaultValue: false }),
    isEditing: _emberData.default.attr('boolean', { defaultValue: false }),
    isSelected: _emberData.default.attr('boolean', { defaultValue: false }),
    statusMessage: Ember.computed('status', 'wasReminderSent', function () {
      let status = this.get('status');
      let wasReminderSent = this.get('wasReminderSent');
      if (status) {
        status = status.toLowerCase();
      }
      if (wasReminderSent) {
        return 'Reminder emailed';
      } else if (status == 'ready') {
        return 'Invitation sent';
      } else if (status == 'active') {
        return 'Opened Study';
      } else if (status == 'complete' || status == 'completed' || status == 'pending') {
        return 'Completed';
      } else {
        return 'Added';
      }
    }),

    isDeleteDisabled: Ember.computed('isEditing', 'status', function () {
      return false;
    }),
    isEditDisabled: Ember.computed('isEditing', 'status', function () {
      let status = this.get('status');
      if (status) {
        status = status.toLowerCase();
      }
      if (status == 'completed' || status == 'active') {
        return true;
      } else {
        return false;
      }
    }),

    isSaveDisabled: Ember.computed('isEditing', 'status', function () {
      let isEditing = this.get('isEditing');
      let status = this.get('status');
      if (status) {
        status = status.toLowerCase();
      }
      if (isEditing == false) {
        return true;
      }
    }),
    isRemindDisabled: Ember.computed('status', function () {
      let status = this.get('status');
      if (status) {
        status = status.toLowerCase();
      }
      if (!status) {
        return true;
      } else {
        status = status.toLowerCase();

        if (status == 'completed' || status == 'initial') {
          return true;
        }
      }
    }),
    isEmailDisabled: Ember.computed('status', function () {
      let status = this.get('status');
      if (status) {
        status = status.toLowerCase();
      }
      if (!status) {
        return false;
      } else {
        status = status.toLowerCase();

        if (status == 'active' || status == 'completed' || status == 'initial') {
          return true;
        } else {
          return false;
        }
      }
    }),
    isPreInvite: Ember.computed('status', function () {
      let status = this.get('status');
      if (status) {
        status = status.toLowerCase();
      }
      if (!status) {
        return false;
      } else {
        status = status.toLowerCase();

        if (status == 'initial') {
          return true;
        } else {
          return false;
        }
      }
    }),

    isResultsDisabled: Ember.computed('status', function () {
      let status = this.get('status');
      if (status) {
        status = status.toLowerCase();
      }
      if (status == 'complete' || status == 'completed' || status == 'active') {
        return false;
      } else {
        return true;
      }
    }),

    // fetch the data.
    responseData: Ember.computed('id', function () {
      let id = this.get('id');
      this.get('store').findRecord('study-participant', id).then(function (person) {
        return person.get('studyData');
      });
    }),
    entryData: Ember.computed('id', function () {
      let authcookie = window.localStorage.getItem('ember_simple_auth-session');
      let orgId = window.localStorage.getItem('organizationId');
      let auth = JSON.parse(authcookie);
      let authToken = auth.authenticated.token;
      Ember.$.ajax({
        beforeSend: function (request) {
          request.setRequestHeader("Authorization", `Cactus token=${authToken}`);
        },
        url: `${_environment.default.host}/${_environment.default.namespace}/organization/${orgId}/studyParticipants/${this.get('id')}/entries/StudyEntry`,
        method: 'GET'
      }).done(function (res) {
        return res;
      });
    }),
    remind: (0, _emberApiActions.memberAction)({
      path: 'remind',
      type: 'post'
    })
  });
});