define('yxt-apps/components/modal/give-feedback', ['exports', 'yxt-apps/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userMessage: '',
    store: Ember.inject.service(),

    actions: {
      submitFeedback() {
        let authcookie = window.localStorage.getItem('ember_simple_auth-session');
        let auth = JSON.parse(authcookie);
        let authToken = auth.authenticated.token;
        // add the researcher's email and name.
        let researcherMessage = this.get('userMessage');
        let researcherName = this.get('me.fullName');
        let researcherEmail = this.get('me.email');
        let researcherOrgId = this.get('me.organizationId');
        let currentApp = this.get('me.yxtAppName');

        let emailBody = `
        <div>
          <h4>Message from researcher</h4>
          <p>${researcherMessage}</p>
        </div>
        <div>
          <h4>About this researcher</h4>
          <table>
            <tr>
              <td>Name</td>
              <td>${researcherName}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>${researcherEmail}</td>
            </tr>
            <tr>
              <td>App</td>
              <td>Sent from <span style="font-weight:bold;">${currentApp}</span></td>
            </tr>
            <tr>
              <td>Organization ID</td>
              <td>${researcherOrgId}</td>
            </tr>
            <tr>
              <td>Operating System</td>
              <td>${this.get('browser.os')}</td>
            </tr>
            <tr>
              <td>Browser</td>
              <td>${this.get('browser.browser.browserName')} (${this.get('browser.browser.version')})</td>
            </tr>
          </table>
        </div>
      `;

        Ember.$.ajax({
          beforeSend: function (request) {
            request.setRequestHeader("Authorization", `Cactus token=${authToken}`);
          },
          url: `${_environment.default.host}/${_environment.default.namespace}/contact/message`,
          method: 'POST',
          data: {
            from: 'noreply@youxtools.com',
            fromName: 'YouXTools',
            category: 'support',
            subject: `YouXtools (${currentApp.toUpperCase()}): Researcher UI Feedback`,
            body: emailBody
          }
        }).done(function (res) {
          return res;
        });

        this.get('state').closeModal();
        this.set('userMesssage', '');
      },
      cancelFeedback() {
        this.set('userMessage', '');
        this.get('state').closeModal();
      }
    }
  });
});