define('yxt-apps/routes/admin/organizations/organization', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.name', function () {
            const orgName = this.get('controller.model.name') || 'Org';

            const org = {
                title: orgName
            };

            return org;
        }),

        model(params) {
            this.get('me').saveOrganizationId(params.organization_id);
            return this.get('store').findRecord('organization', params.organization_id);
        },
        actions: {
            updateOrg(org) {
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }
                function notifySuccess(org) {
                    self.toast.success(`Organization (${org.get('name')}) updated succesfully`);
                }
                org.save().then(notifySuccess).catch(failure);
            },
            cancelChanges(org) {
                org.rollbackAttributes();
            }
        }
    });
});