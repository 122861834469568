define('yxt-apps/routes/admin/organizations/create', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

        model() {
            let orgUser = this.get('store').findRecord('user', '5a8ba41f749a5b0001a984d9'); // hardcoded to Geoff's user ID
            return this.get('store').createRecord('organization', {
                name: 'Test',
                numberOfEmployees: 1
            });
        },
        actions: {
            updateOrg(org) {
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }
                function notifySuccess(org) {
                    self.toast.success(`Organization (${org.get('name')}) updated succesfully`);
                }
                org.save().then(notifySuccess).catch(failure);
            },
            cancelChanges(org) {
                org.rollbackAttributes();
            }
        }
    });
});