define('yxt-apps/components/journal/list-control-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['list-control-bar'],
    displayMode: 'table',
    selectedStudies: Ember.computed('studies.@each.isSelected', function () {
      let studies = this.get('studies');
      return studies.filterBy('isSelected', true);
    }),
    isDeleteButtonDisabled: Ember.computed('selectedStudies', function () {
      let selectedStudies = this.get('selectedStudies');
      if (selectedStudies.length > 0) {
        return false;
      } else {
        return true;
      }
    }),
    actions: {
      changeListMode(val) {
        this.get('state').savePref('displayMode', val);
      },
      createNewJournalStudy() {
        this.get('createNewJournalStudy')(); // call on the parent route
      },
      deleteSelectedStudies() {
        this.get('deleteSelectedStudies')(); // call on the parent route
      }
    }
  });
});