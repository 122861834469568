define('yxt-apps/models/content', ['exports', 'ember-data', 'ember-data-model-fragments/fragment', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _fragment, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        name: _emberData.default.attr("string"),
        value: _emberData.default.attr(),
        metadata: (0, _attributes.fragment)('content-meta'),
        contentType: _emberData.default.attr('string'),
        createdAt: _emberData.default.attr(),
        modifiedAt: _emberData.default.attr(),
        isQuestionValid: Ember.computed('metadata', 'metadata.question', 'metadata.validChoices', 'contentType', 'value', function () {
            let contentType = this.get('contentType').toLowerCase();
            let val = this.get('value');
            if (val !== undefined && val == 'DELETED') {
                return true;
            } else if (contentType === 'textentry') {
                if (this.get('metadata.question')) {
                    if (this.get('metadata.question') !== null && this.get('metadata.question') != '') {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else if (contentType === 'multichoice') {
                if (this.get('metadata.question') && this.get('metadata.validChoices')) {
                    console.log(`multichoice question is valid=true`);
                    return true;
                } else {
                    console.log(`multichoice question is valid=false`);
                    return false;
                }
            } else {
                if (this.get('metadata.question')) {
                    if (this.get('metadata.question') !== null && this.get('metadata.question') != '') {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        })
    });
});