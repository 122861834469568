define('yxt-apps/routes/reaction/studies/study/create', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: {
            title: 'Create a Study'
        },
        beforeModel() {
            this.get('state').set('isCreationFlowActive', true);
            if (typeof tinymce == 'undefined') {
                return Ember.$.getScript('//cdn.tiny.cloud/1/5mcmvyu2zalcpwn06bpu3zv1ilgjp5jryo938qv7h84ijl22/tinymce/5/tinymce.min.js');
            }
        },
        afterModel: function (transition) {
            let parentModel = this.modelFor('reaction.studies.study');
            let study = parentModel.study;
            let websiteTask = parentModel.studyWebsiteTasks;
            websiteTask.set('study', study);

            Ember.$('body > div.ember-view').animate({
                scrollTop: 0
            }, 500);
            this._super(transition);
        }
    });
});