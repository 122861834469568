define('yxt-apps/routes/journal/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    breadCrumb: Ember.computed('session.isAuthenticated', function () {
      const isAuthenticated = this.get('session.isAuthenticated') || false;
      if (isAuthenticated) {
        const dashTitle = {
          title: 'Studies'
        };
        return dashTitle;
      } else {
        return null;
      }
    }),
    beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('journal.studies');
      } else {
        this.transitionTo('journal.login');
      }
    }

  });
});