define('yxt-apps/components/report-card/participant-videos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    participant: null,
    selectedParticipant: null,
    didInsertElement() {
      this.get('participants').get('firstObject').set('isViewing', true);
      this.set('selectedParticipant', this.get('participants').get('firstObject'));
    },
    actions: {
      changeParticipant(participant) {
        this.get('participants').setEach('isViewing', false);
        participant.set('isViewing', true);
        this.set('selectedParticipant', participant);
      }
    }
  });
});