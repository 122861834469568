define('yxt-apps/models/study-website-task', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        url: _emberData.default.attr('string'),
        urlProtocol: _emberData.default.attr('string', { defaultValue: 'https://' }),
        urlPath: _emberData.default.attr('string'),
        urlBuilt: Ember.computed('urlProtocol', 'urlPath', function () {
            let curURL = this.get('url');
            let urlPath = this.get('urlPath');
            let builtURL = `${this.get('urlProtocol')}${urlPath}`;
            this.set('url', builtURL);
            return builtURL;
        }),
        urlBuiltLC: Ember.computed('urlBuilt', function () {
            let urlBuilt = this.get('urlBuilt');
            if (urlBuilt) {
                return urlBuilt.toLowerCase();
            }
        }),
        name: _emberData.default.attr('string'),
        pages: _emberData.default.hasMany('page-template'),
        study: _emberData.default.belongsTo('study'),
        createdAt: _emberData.default.attr(),
        modifiedAt: _emberData.default.attr(),
        startTime: _emberData.default.attr(),
        endTime: _emberData.default.attr(),
        logo: _emberData.default.attr(),
        isUrlValid: Ember.computed('url', 'urlBuilt', 'urlProtocol', 'urlPath', function () {
            if (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(this.get("urlBuiltLC"))) {
                let builtURL = `${this.get('urlProtocol')}${this.get('urlPath')}`;
                let curURL = this.get('url');
                this.set('url', builtURL);
                return true;
            } else {
                return false;
            }
        }),
        areTasksValid: Ember.computed('pages.@each.isPageValid', 'pages.@each.isValid', 'pages.@each.content', 'pages', function () {
            let validQuestions = this.get('pages').filterBy('isPageValid', true);
            let invalidQuestions = this.get('pages').filterBy('isPageValid', false);

            if (validQuestions && invalidQuestions) {
                if (invalidQuestions.length == 0 && validQuestions.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        })
    });
});