define('yxt-apps/routes/reaction/studies', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: null,
        beforeModel() {
            let self = this;
            this.set('headData.title', 'YouXReaction - Studies');
            this.get('store').findAll('project').then(function (projects) {

                if (projects.get('length') < 1) {
                    // then we need to create one to get started.
                    function failure(reason) {
                        self.toast.error(reason);
                    }
                    function setAsMyPrimaryProject(project) {
                        self.get('me').savePrimaryProject(project.get('id'));
                    }
                    let myFirstProject = self.get('store').createRecord('project', {
                        name: 'My Studies',
                        description: 'A collection of studies'
                    });
                    myFirstProject.save().then(setAsMyPrimaryProject).catch(failure);
                } else {
                    let firstProject = projects.get('firstObject');
                    self.get('me').savePrimaryProject(firstProject.get('id'));
                }
            });
        }
    });
});