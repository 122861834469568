define('yxt-apps/models/activate-account', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        token: _emberData.default.attr('string'),
        status: _emberData.default.attr('string'),
        user: _emberData.default.belongsTo('user'),
        organization: _emberData.default.belongsTo('organization'),
        // for error responses:
        technicalMessage: _emberData.default.attr('string'),
        name: _emberData.default.attr('string')
    });
});