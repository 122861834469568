define('yxt-apps/components/question-generator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'section',
        classNames: ['control-generator', 'question-creation-panel'],
        pages: [],
        selectedOption: null,
        questions: [],
        choicesQty: 3,
        questionListValidCount: Ember.computed('questionList.pages.@each.isDeleted', function () {
            let count = 0;
            this.get("questionList.pages").forEach(function (page) {
                if (!page.get('isDeleted')) {
                    count++;
                }
            });
            return count;
        }),
        validQuestions: Ember.computed('questionList.pages.@each', 'questionList.pages.@each.isDeleted', function () {
            return this.get('questionList.pages').filterBy('isDeleted', false);
        }),
        relParam: '',
        didInsertElement() {
            this.get('questionList.pages').setEach('isEditing', false);

            let allPages = this.get('questionList').get('pages');

            if (allPages.get('length') > 0) {
                let activeQuestion = this.get('questionList').get('pages').get('firstObject');
                activeQuestion.set('isEditing', true);
                this.set('activeQuestion', activeQuestion);
                this.get('questions').push(activeQuestion);
            }
        },
        actions: {
            setQuestionType(questionType) {
                this.set('selectedOption', questionType);
                this.get('activeQuestion').get('content').get('firstObject').set('contentType', questionType);

                /*
                if(questionType == 'MultiChoice' || questionType == 'Scale'){
                  let curChoices = this.get('activeQuestion.content').get('firstObject').get('metadata');
                   //avoid adding always 2 new options when use just changes the type of question
                  if(curChoices.get('body.length') == 0) {
                    // start with 2 options.
                    let choice1 = this.get('store').createRecord('question-choice',{
                      name: '',
                    });
                    curChoices.get('body').pushObject(choice1);
                     if(questionType == 'MultiChoice') {
                      let choice2 = this.get('store').createRecord('question-choice',{
                        name: '',
                      });
                      curChoices.get('body').pushObject(choice2);
                    }
                  }
                  else{
                    if(questionType == 'Scale'){
                      //this.get('activeQuestion').get('content').get('firstObject').set('metadata.body',null);
                      this.get('activeQuestion.content').get('firstObject').get('metadata.body').forEach(function(question){
                        question.deleteRecord();
                      });
                      let choice1 = this.get('store').createRecord('question-choice',{
                        name: '',
                      });
                      curChoices.get('body').pushObject(choice1);
                    }
                  }
                }*/

                let curChoices = this.get('activeQuestion.content.firstObject').get('metadata');
                let choicesQty = this.get('choicesQty');
                let curChoicesQty = curChoices.get('body.length') || 0;
                if (questionType == 'MultiChoice' || questionType == 'Scale') {

                    if (questionType == 'MultiChoice') {
                        for (let i = curChoicesQty; i < choicesQty; i++) {
                            let choice = this.get('store').createRecord('question-choice', {
                                name: ''
                            });
                            curChoices.get('body').pushObject(choice);
                        }
                        // remove 'size' from any existing choices
                        curChoices.get('body').forEach(function (choice) {
                            choice.set('size', undefined);
                        });
                    } else if (questionType == 'Scale') {
                        for (let i = 0; i < curChoicesQty; i++) {
                            curChoices.get('body').popObject();
                        }
                        curChoices.get('body').createFragment('question-choice', {
                            name: '',
                            size: 6
                        });
                    }
                } else if (questionType == 'TextEntry' || questionType == 'Instruction' || questionType == 'Media') {
                    //curChoices.set('body',null);
                    for (let i = 0; i < curChoicesQty; i++) {
                        curChoices.get('body').popObject();
                    }
                }
            },
            editItem(item) {
                this.get('questionList.pages').setEach('isEditing', false);
                let allPages = this.get('store').peekAll('page-template');
                allPages.setEach('isEditing', false);
                item.set('isEditing', true);
                this.set('activeQuestion', item);
            },
            deleteItem(item) {
                let resetActive = false;
                if (item.get('isEditing')) {
                    resetActive = true;
                    item.set('isEditing', false);
                }

                item.deleteRecord();

                if (resetActive) {
                    if (this.get('questionListValidCount') > 0) {
                        let activeQuestion = this.get('validQuestions').get('firstObject');
                        activeQuestion.set('isEditing', true);
                        //self.set('activeQuestion', activeQuestion);
                    }
                }
            },
            addItem() {
                this.get('questionList.pages').setEach('isEditing', false);
                let allPages = this.get('store').peekAll('page-template');
                allPages.setEach('isEditing', false);
                let ranArray = new Uint32Array(1);
                window.crypto.getRandomValues(ranArray);
                let uId = `${moment()}_${ranArray[0]}`;
                let newQuestionName = `q_${uId}`;
                var pObj = {
                    title: newQuestionName,
                    name: newQuestionName,
                    isEditing: true,
                    content: [{
                        name: newQuestionName,
                        contentType: 'Scale', // Emotion, TextEntry, MultiChoice, Photo/Video
                        metadata: {
                            isMandatory: true,
                            question: '',
                            body: [{
                                name: "",
                                size: 6
                            }]
                        }
                    }]
                };
                pObj[this.get('relParam')] = this.get('questionList');
                let newQuestion = this.get('store').createRecord('page-template', pObj);

                this.set('activeQuestion', newQuestion);
            },
            addChoice() {
                let newChoice = this.get('store').createRecord('question-choice', {
                    name: ''
                });

                this.get('activeQuestion.content').get('firstObject').get('metadata.body').pushObject(newChoice);
            },
            deleteChoice(index) {
                this.get('activeQuestion.content').get('firstObject').get('metadata.body').removeObject(index);
            },
            reorderItems(groupModel, itemModels) {
                groupModel.set('pages', itemModels);
                this.set('questionList.pages', groupModel);
            }
        }

    });
});