define('yxt-apps/routes/admin/organizations/organization/projects/create', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.name', function () {
            const projectName = this.get('controller.model.name') || 'Project';

            const stdy = {
                title: projectName
            };

            return stdy;
        }),
        getProject(params) {
            return this.get('store').createRecord('project', {});
        },

        model(params) {
            return Ember.RSVP.hash({
                project: this.getProject(params)
            });
        },
        actions: {
            updateProject(project) {
                let self = this;

                function failure(reason) {
                    self.toast.error(reason);
                }

                function notifyStudySuccess(project) {
                    self.toast.success(`Project (${project.get('name')}) updated`);
                }
                //let curProj = this.get('store').peekRecord('project', project.get('id'));
                project.save().then(notifyStudySuccess).catch(failure);
            },
            cancelChanges(project) {
                project.rollbackAttributes();
            }
        }
    });
});