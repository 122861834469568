define('yxt-apps/routes/confirm-email', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'yxt-apps/config/environment'], function (exports, _unauthenticatedRouteMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        isConfirmed: false,
        isAlreadyConfirmed: false,
        hasError: false,
        isDuplicate: false,
        breadCrumb: {
            title: 'Confirming your email'
        },
        model(params) {
            let self = this;

            Ember.$.ajax({
                url: `${_environment.default.host}/${_environment.default.namespace}/activateAccount/?token=${params.activateAccount_id}`,
                method: 'POST',
                data: { 'token': params.activateAccount_id }
            }).done(function () {
                self.set('currentModel.isConfirmed', true);
            }).catch(function (response) {
                if (response.responseJSON) {
                    if (response.responseJSON.name) {
                        if (response.responseJSON.name === 'DuplicatedItemIdError') {
                            self.set('currentModel.isConfirmed', false);
                            self.set('currentModel.isAlreadyConfirmed', true);
                            self.set('currentModel.hasError', false);
                            self.set('currentModel.isDuplicate', true);
                        } else if (response.responseJSON.name === 'SecurityError') {
                            self.set('currentModel.isConfirmed', false);
                            self.set('currentModel.isAlreadyConfirmed', false);
                            self.set('currentModel.hasError', true);
                            self.set('currentModel.isDuplicate', false);
                        } else {
                            self.set('currentModel.isConfirmed', false);
                            self.set('currentModel.isAlreadyConfirmed', false);
                            self.set('currentModel.hasError', true);
                            self.set('currentModel.isDuplicate', false);
                        }
                    }
                }
            });

            return Ember.RSVP.hash({
                isConfirmed: this.get('isConfirmed'),
                isAlreadyConfirmed: this.get('isAlreadyConfirmed'),
                hasError: this.get('hasError'),
                isDuplicate: this.get('isDuplicate')
            });
        }
    });
});