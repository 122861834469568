define('yxt-apps/components/report-card/study-entry-detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    pageValue: Ember.computed('', function () {
      let result = "";
      let name = this.get('page.templateId.content.firstObject.name');
      let type = this.get('page.templateId.content.firstObject.contentType');

      if (type == "MultiChoice") {
        var answers = result = this.get('page.content')[name];
        let options = this.get('page.templateId.content.firstObject.metadata.body');
        result = "";

        if (answers instanceof Array) {
          for (let i = 0; i < answers.length; i++) {
            for (let j = 0; j < options.length; j++) {
              if (answers[i] == j) {
                result += options[j].name + ", ";
              }
            }
          }

          result = result.substring(0, result.length - 2);
        } else {
          result = options[answers].name;
        }
      } else if (type == "Media") {
        if (this.get('page.answers.firstObject.presets')) {
          result = this.get('page.answers.firstObject.presets.firstObject.asset.url');
        } else {
          result = this.get('page.answers.firstObject.url');
        }
      } else if (type == "Scale") {
        result = [];
        var scaleAnswers = this.get('page.content')[name];
        let options = this.get('page.templateId.content.firstObject.metadata.body');
        if (scaleAnswers instanceof Array) {
          for (let j = 0; j < options.length; j++) {
            result[j] = options[j].name + " (" + scaleAnswers[j] + ")";
          }
        } else {
          result[0] = this.get('page.content')[name];
        }
      } else {
        result = this.get('page.content')[name];
      }
      return result;
    })
  });
});