define('yxt-apps/initializers/preloader', ['exports', 'ember-cli-preloader/services/preloader'], function (exports, _preloader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { inject, run } = Ember;

  exports.default = {
    name: 'preloader',

    initialize: function (app) {
      let options = app.preloader || {};
      let service = _preloader.default.create({
        options: options
      });

      app.register('service:preloader', service, { instantiate: false });
    }
  };
});