define('yxt-apps/routes/ford/projects/project/study', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        getReports(params) {
            if (this.get('config.isReportZipUploadEnabled')) {
                return this.get('store').query('ford-html-report', {
                    study: params.study_id
                });
            }
        },
        getStudy(params) {
            return this.get('store').findRecord('study', params.study_id);
        },
        getParticipantResults(params) {
            return this.get('store').query('participant-result', {
                studyId: params.study_id
            }).catch(() => {
                return null;
            }).then(results => {
                return results.sortBy('modifiedAt').reverse();
            });
        },
        model(params) {
            return Ember.RSVP.hash({
                study: this.getStudy(params),
                participantResults: this.getParticipantResults(params),
                reports: this.getReports(params)
            });
        }
    });
});