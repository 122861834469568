define('yxt-apps/serializers/participant', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      modifiedAt: { serialize: false },
      createdAt: { serialize: false },
      profile: { embedded: 'always' },
      isViewing: { serialize: false },
      isSelected: { serialize: false },
      participantResult: { serialize: false }
    },
    serializeAttribute(snapshot, json, key) {
      if (snapshot.attr(key) != null) {
        this._super(...arguments);
      }
    },
    normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.profile) {
        payload.profile.id = `${payload.id}_pfl`;

        if (payload.profile.address) {
          payload.profile.address.id = `${payload.profile.id}_adrs`;
        }
      }
      if (!payload.type) {
        payload.type = 'participant';
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload.forEach(function (participant) {
        if (participant.profile) {
          participant.profile.id = `${participant.id}_pfl`;
        }
        if (!participant.type) {
          participant.type = 'participant';
        }
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      payload.forEach(function (participant) {
        if (participant.profile) {
          participant.profile.id = `${participant.id}_pfl`;
        }
        if (!participant.type) {
          participant.type = 'participant';
        }
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });
});