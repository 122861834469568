define('yxt-apps/routes/admin/organizations/organization/participants', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    breadCrumb: Ember.computed('controller.model.name', function () {
      const participantsLength = this.get('controller.model.length') || 0;

      const participantsCrumb = {
        title: `Participants (${participantsLength})`
      };

      return participantsCrumb;
    }),
    beforeModel() {
      this.get('store').unloadAll('participant');
    },
    model() {
      return this.get('store').findAll('participant').then(function (person) {
        return person.sortBy('profile.lastName');
      });
    }
  });
});