define('yxt-apps/components/sticky-element', ['exports', 'ember-sticky-element/components/sticky-element'], function (exports, _stickyElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _stickyElement.default;
    }
  });
});