define('yxt-apps/helpers/humanize-emotion-rating', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeEmotionRating = humanizeEmotionRating;
  function humanizeEmotionRating(params /*, hash*/) {
    let [rating] = params;
    if (rating === 1) {
      return 'Frustrated';
    } else if (rating === 2) {
      return 'Confused';
    } else if (rating === 3) {
      return 'Indifferent';
    } else if (rating === 4) {
      return 'Satisfied';
    } else if (rating === 5) {
      return 'Excited';
    }
  }

  exports.default = Ember.Helper.helper(humanizeEmotionRating);
});