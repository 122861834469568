define('yxt-apps/helpers/humanize-question-type', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.humanizeQuestionType = humanizeQuestionType;
    function humanizeQuestionType(params) {
        let [questionType] = params;
        if (questionType === 'TextEntry') {
            return 'Text';
        } else if (questionType === 'MultiChoice') {
            return 'Multiple Choice';
        } else if (questionType === 'Scale') {
            return 'Rating';
        } else if (questionType === 'Media') {
            return 'Image/Video';
        } else {
            return questionType;
        }
    }

    exports.default = Ember.Helper.helper(humanizeQuestionType);
});