define('yxt-apps/routes/journal/studies/study/results', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    breadCrumb: Ember.computed('controller.model.study.title', function () {
      const pageName = this.get('controller.model.study.title') || 'Study';
      const breadCrumbTitle = { title: `${pageName} Results` };
      return breadCrumbTitle;
    }),
    studyId: '',
    getSharelink(studyId) {
      return this.get('store').queryRecord('study-report-share-link', { studyId: studyId }).catch(() => {
        return null;
      });
    },
    beforeModel() {
      this.get('state').set('isCreationFlowActive', false);
    },
    model() {
      let parentModel = this.modelFor('journal.studies.study');
      let studyId = parentModel.study.get('id');
      this.set('studyId', studyId);
      return Ember.RSVP.hash({
        studyTypes: parentModel.studyTypes,
        study: parentModel.study,
        studyEntryViewType: "ENTRY", //ENTRY OR EMOTION
        studyJournalTasks: parentModel.studyJournalTasks,
        participantResults: parentModel.participantResults,
        sharelink: this.getSharelink(studyId)
      });
    }

  });
});