define('yxt-apps/components/modal/signup-consent', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isTermsBoxTicked: false,
        isModalDisplayed: true,
        actions: {
            acceptTerms() {
                Ember.$('form#register-form').submit();
                this.get('state').closeModal();
            },
            dismissTerms() {
                this.set('isTermsBoxTicked', false);
                this.get('state').closeModal();
            }
        }
    });
});