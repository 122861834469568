define('yxt-apps/helpers/pluralize-word', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.pluralizeWord = pluralizeWord;
    function pluralizeWord(params) {
        let [singularWord, pluralWord, count] = params;

        if (count > 1) {
            return pluralWord;
        } else {
            return singularWord;
        }
    }

    exports.default = Ember.Helper.helper(pluralizeWord);
});