define('yxt-apps/components/participant-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        actions: {
            cancelChanges(participant) {
                this.get('cancelChanges')(participant);
            },
            updateParticipant(participant) {
                this.get('updateParticipant')(participant);
            }
        }
    });
});