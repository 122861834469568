define('yxt-apps/adapters/participant-token', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend({

    host: function () {
      return `${this.get('config.host')}/${this.get('config.namespace')}/organization/${window.localStorage.getItem('organizationId')}`;
    }.property().volatile(),
    pathForType: function () {
      // hardcode
      return 'auth/studyparticipants';
    }
  });
});