define('yxt-apps/models/study-report-share-link', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    expires: _emberData.default.attr(),
    url: _emberData.default.attr('string'),
    token: _emberData.default.attr('string')
  });
});