define('yxt-apps/components/modal/report-problem', ['exports', 'yxt-apps/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userMessage: '',
    store: Ember.inject.service(),
    actions: {
      submitReport() {
        let authcookie = window.localStorage.getItem('ember_simple_auth-session');
        let auth = JSON.parse(authcookie);
        let authToken = auth.authenticated.token;
        Ember.$.ajax({
          beforeSend: function (request) {
            request.setRequestHeader("Authorization", `Cactus token=${authToken}`);
          },
          url: `${_environment.default.host}/${_environment.default.namespace}/contact/message`,
          method: 'POST',
          data: {
            from: 'noreply@youxtools.com',
            fromName: 'YouXTools',
            category: 'support',
            subject: 'YouXtools: Researcher UI Problem Report',
            body: this.get('userMessage')
          }
        }).done(function (res) {
          return res;
        });

        this.get('state').closeModal();
        this.set('userMesssage', '');
      },
      cancelReport() {
        this.get('state').closeModal();
      }
    }
  });
});