define('yxt-apps/routes/journal/studies/study/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel() {
      let parentModel = this.modelFor('journal.studies.study');
      let study = parentModel.study;
      let studyStatus = study.get('status').toLowerCase();
      if (studyStatus === 'ready' || studyStatus === 'pendingpayment' || studyStatus === 'active' || studyStatus === 'complete' || studyStatus === 'completed') {
        this.transitionTo('journal.studies.study.overview');
      } else {
        this.transitionTo('journal.studies.study.create');
      }
    }
  });
});