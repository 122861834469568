define('yxt-apps/components/privacy-policy', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        willInsertElement() {
            window.location = 'http://youxtools.com/legal/Privacy_Policy.pdf';
        }
    });
});