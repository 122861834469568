define('yxt-apps/helpers/share-report-url', ['exports', 'yxt-apps/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shareReportUrl = shareReportUrl;
  function shareReportUrl(params /*, hash*/) {
    let [studyId, token] = params;
    /*if(!token || token == 'undefined'){
      token = '1';
    }*/
    let orgId = window.localStorage.getItem('organizationId');
    return `https://${_environment.default.domain}/results/${token}/organization/${orgId}/study/${studyId}`;
  }

  exports.default = Ember.Helper.helper(shareReportUrl);
});