define('yxt-apps/routes/ford/sign-up', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model() {
      let newUser, newProfile;
      // create a new profile
      newProfile = this.get('store').createRecord('registration-profile', {
        signup: newUser,
        address: {}
      });

      // create a new user
      newUser = this.get('store').createRecord('signup', {
        profile: newProfile
      });

      return newUser;
    },
    actions: {}
  });
});