define('yxt-apps/models/page-template', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        templateId: _emberData.default.attr(),
        title: _emberData.default.attr('string'),
        content: (0, _attributes.fragmentArray)('content'),
        properties: _emberData.default.attr(),
        studyWebsiteTask: _emberData.default.belongsTo('study-website-task'),
        studyJournalTask: _emberData.default.belongsTo('study-journal-task'),
        studyPostSessionQuestion: _emberData.default.belongsTo('study-post-session-question'),
        studyPostSessionQuestionResponses: _emberData.default.belongsTo('study-post-session-question-responses'),
        isEditing: _emberData.default.attr('boolean', {
            defaultValue: false
        }),
        createdAt: _emberData.default.attr(),
        modifiedAt: _emberData.default.attr(),
        isValid: false,
        isPageValid: Ember.computed('content.@each', 'content.@each.metadata', 'content.@each.isQuestionValid', function () {
            if (this.get('content')) {
                let contentLength = this.get('content.length');
                if (this.get('content').filterBy('isQuestionValid', false).length > 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }),
        isPageAdvancedValid: Ember.computed('content.@each', 'content.@each.metadata', 'content.@each.isQuestionValid', 'content.@each.metadata.validChoices', 'areChoicesValid', 'isError', function () {
            if (this.get('content')) {
                let isError = this.get('isError');
                let isQuestionInvalid = this.get('content').filterBy('isQuestionValid', false).length > 0;

                if (!isQuestionInvalid && !isError) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }),
        isError: Ember.computed('content.@each.metadata.question', function () {
            this.get("content").forEach(function (content) {
                if (content.metadata.question == "") return true;else return false;
            });
        }),
        areChoicesValid: Ember.computed('content.@each', 'content.@each.metadata', 'content.@each.metadata.validChoices', function () {
            if (this.get("content")) {
                if (this.get('content').filterBy('isQuestionValid', false).length > 0) {
                    return false;
                } else {
                    return true;
                }
            }
        })
    });
});