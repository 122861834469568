define("yxt-apps/helpers/is-video-file", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.isVideoFile = isVideoFile;
    function isVideoFile(params) {

        let tmp = params[0].split(".");
        let ext = tmp[tmp.length - 1].toLowerCase();
        if (ext == "mp4" || ext == "mov" || ext == "webm") {
            return true;
        } else {
            return false;
        }
    }

    exports.default = Ember.Helper.helper(isVideoFile);
});