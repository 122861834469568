define('yxt-apps/components/logo/you-x-reaction', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'i',
    classNames: ['brand-logo']
  });
});