define('yxt-apps/models/permissions', ['exports', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes'], function (exports, _emberDataModelFragments, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    mediaAssets: (0, _attributes.array)(),
    userOrganizations: (0, _attributes.array)(),
    users: (0, _attributes.array)(),
    userProfiles: (0, _attributes.array)(),
    timeSlots: (0, _attributes.array)(),
    studyReports: (0, _attributes.array)(),
    studyPromotions: (0, _attributes.array)(),
    studyWebsiteTasks: (0, _attributes.array)(),
    studyJournalTasks: (0, _attributes.array)(),
    studyTemplates: (0, _attributes.array)(),
    studyPostSessionQuestions: (0, _attributes.array)(),
    studyParticipants: (0, _attributes.array)(),
    studies: (0, _attributes.array)(),
    roles: (0, _attributes.array)(),
    projects: (0, _attributes.array)(),
    participants: (0, _attributes.array)(),
    organizationMembersOfUser: (0, _attributes.array)(),
    organizationMembers: (0, _attributes.array)(),
    organizationRoles: (0, _attributes.array)(),
    organization: (0, _attributes.array)(),
    mailTemplates: (0, _attributes.array)(),
    mail: (0, _attributes.array)(),
    ingestion: (0, _attributes.array)(),
    facilities: (0, _attributes.array)(),
    pageForms: (0, _attributes.array)(),
    pageTemplates: (0, _attributes.array)(),
    journalTemplates: (0, _attributes.array)(),
    documentTemplates: (0, _attributes.array)(),
    calendars: (0, _attributes.array)()
  });
});