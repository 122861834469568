define('yxt-apps/routes/results', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var firstParticipant;

    exports.default = Ember.Route.extend({
        breadCrumb: null,
        studyId: '',
        getYouXScore(studyId) {
            if (this.get('me.yxtAppName').toLowerCase() == 'reaction') {
                return this.get('store').queryRecord('you-x-score', { studyId: studyId }).catch(() => {
                    return null;
                });
            } else {
                return null;
            }
        },
        getParticipantResults(params) {
            return this.get('store').query('participant-result', {
                studyId: params.study_id
            }).catch(() => {
                return null;
            }).then(results => {
                firstParticipant = results.get('firstObject');
                return results;
            });
        },
        redirect: function () {
            this.transitionTo('results.participant', firstParticipant.id);
        },
        model(params) {
            let organization = params.organization_id;
            // set the organization.
            this.get('me').saveOrganizationId(organization);

            let token = params.result_id;
            // set the token

            let study = this.get('store').findRecord('study', params.study_id).then(study => {
                this.get('state').set('pagetitle', `${study.get('title')} Results`);
                let studyType = study.get('studyTemplate.studyType').toLowerCase();
                this.get('me').saveAppName(studyType);
                Ember.$('body').attr('appName', studyType);
                return study;
            });

            return Ember.RSVP.hash({
                token: token,
                study: study,
                organization: organization,
                youxscore: this.getYouXScore(params.study_id),
                participantResults: this.getParticipantResults(params)
            });
        }
    });
});