define('yxt-apps/models/profile', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _emberDataModelFragments, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    firstName: _emberData.default.attr('string', { defaultValue: null }),
    lastName: _emberData.default.attr('string', { defaultValue: null }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return `${this.get('firstName')} ${this.get('lastName')}`;
    }),
    firstNameLastInitial: Ember.computed('firstName', 'lastName', function () {
      return `${this.get('firstName')} ${this.get('lastName').charAt(0)}.`;
    }),
    name: Ember.computed('firstName', 'lastName', {
      get() {
        let firstName = this.get('firstName');
        let lastName = this.get('lastName');
        if (firstName == null && lastName == null) {
          return null;
        } else if (firstName == null || firstName == 'null' || firstName == '') {
          return `${lastName}`;
        } else if (lastName == null || lastName == 'null' || lastName == '') {
          return `${firstName}`;
        } else {
          return `${firstName} ${lastName}`;
        }
      },
      set(key, value) {
        let [firstName, lastName] = value.split(/\s+/);
        if (firstName !== null) {
          this.set('firstName', firstName);
        } else {
          this.set('firstName', '');
        }
        if (lastName !== null) {
          this.set('lastName', lastName);
        } else {
          this.set('lastName', '');
        }

        return value;
      }
    }),
    isMinimumName: Ember.computed('firstName', 'lastName', function () {
      let firstName = this.get('firstName');
      let lastName = this.get('lastName');
      if (firstName && lastName) {
        if (firstName.length >= 2 && lastName.length >= 1) {
          return true;
        } else if (firstName.length >= 1 && lastName.length >= 2) {
          return true;
        }
      }
    }),
    address: (0, _attributes.fragment)('address'),
    phoneNumber: _emberData.default.attr('string'),
    textNumber: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    employmentStatus: _emberData.default.attr('string'),
    employerName: _emberData.default.attr('string'),
    industry: _emberData.default.attr('string'),
    jobTitle: _emberData.default.attr('string'),
    birthDate: _emberData.default.attr('string'),
    ethnicity: _emberData.default.attr('string'),
    houseHoldIncome: _emberData.default.attr('string'),
    childrenAges: _emberData.default.attr(),
    activitiesHobbies: _emberData.default.attr('string'),
    hasMinor: _emberData.default.attr('boolean'),
    hasSmartphone: _emberData.default.attr('boolean'),
    hasGamingSystem: _emberData.default.attr('boolean'),
    hasTablet: _emberData.default.attr('boolean'),
    hasPaidTvService: _emberData.default.attr('boolean'),
    notes: _emberData.default.attr('string'),
    memberRating: _emberData.default.attr('number'),
    agile: _emberData.default.attr('string'),
    picture: _emberData.default.attr(),
    modifiedAt: _emberData.default.attr(),
    createdAt: _emberData.default.attr()
  });
});