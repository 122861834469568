define('yxt-apps/helpers/storage-level', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.storageLevel = storageLevel;
    function storageLevel(params) {
        let [used, limit] = params;

        let percentUsed = (used / limit * 100).toFixed(0);
        if (limit == 0 || limit == 'Unlimited' || limit == undefined || limit == 'undefined') {
            return 'unlimited';
        } else if (used > limit) {
            return 'overlimit';
        } else if (percentUsed > 90) {
            return 'critical';
        } else if (percentUsed > 70) {
            return 'warning';
        } else {
            return 'safe';
        }
    }

    exports.default = Ember.Helper.helper(storageLevel);
});