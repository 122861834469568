define('yxt-apps/adapters/study-journal-task', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'yxt-apps/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),
    authorize(xhr) {
      let { token } = this.get('session.data.authenticated');
      if (Ember.isPresent(token)) {
        xhr.setRequestHeader('Authorization', `Cactus token=${token}`);
      }
    },
    host: function () {
      return `${this.get('config.host')}/${this.get('config.namespace')}/organization/${window.localStorage.getItem('organizationId')}`;
    }.property().volatile(),
    pathForType: function () {
      // hardcode
      return 'studyJournalTasks';
    },
    createRecord(store, type, snapshot) {
      // we need to manipulate the data before sending to the server.
      // server needs this wrapped in [] as if it's multiple records.
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      const url = `${_environment.default.host}/${_environment.default.namespace}/organization/${window.localStorage.getItem('organizationId')}/studyJournalTasks/save`;

      data.pages.forEach(function (page) {
        page.content.forEach(function (content) {
          if (content.contentType != "Scale") {
            for (let i = 0; i < content.metadata.body.length; i++) {
              delete content.metadata.body[i].size;
            }
          } else {
            for (let i = 0; i < content.metadata.body.length; i++) {
              content.metadata.body[i].size = content.metadata.size;
            }
          }
          delete content.metadata.size;
          if (content.id) {
            delete content.id;
          }
          if (content.metadata) {
            if (content.metadata.id) {
              delete content.metadata.id;
            }
          }
          if (content.metadata) {
            if (content.metadata.id) {
              delete content.metadata.id;
            }
            if (content.metadata.body) {
              content.metadata.body.forEach(function (body) {
                delete body.metaparent;
              });
            }
          }
        });
      });
      return this.ajax(url, "POST", { data: [data] });
    },
    updateRecord(store, type, snapshot) {
      // we need to manipulate the data before sending to the server.
      // server needs this wrapped in [] as if it's multiple records.
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      const url = `${_environment.default.host}/${_environment.default.namespace}/organization/${window.localStorage.getItem('organizationId')}/studyJournalTasks/save`;

      data.pages.forEach(function (page) {
        page.content.forEach(function (content) {

          if (content.contentType != "Scale") {
            for (let i = 0; i < content.metadata.body.length; i++) {
              delete content.metadata.body[i].size;
            }
          } else {
            for (let i = 0; i < content.metadata.body.length; i++) {
              content.metadata.body[i].size = content.metadata.size;
            }
          }
          delete content.metadata.size;
          if (content.id) {
            delete content.id;
          }
          if (content.metadata) {
            if (content.metadata.id) {
              delete content.metadata.id;
            }
          }
          if (content.metadata) {
            if (content.metadata.id) {
              delete content.metadata.id;
            }
            if (content.metadata.body) {
              content.metadata.body.forEach(function (body) {
                delete body.metaparent;
              });
            }
          }
        });
      });
      return this.ajax(url, "POST", { data: [data] });
    }
  });
});