define('yxt-apps/serializers/mail-template', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {

    attrs: {
      modifiedAt: { serialize: false },
      createdAt: { serialize: false },
      study: { serialize: false },
      contentType: { serialize: false },
      isEditing: { serialize: false }
    },

    serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attributes);
      }
    },

    modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'data') {
        let modelName = 'mail-template';
        return this._super(payloadKey.replace('data', modelName));
      } else {
        return this._super(payloadKey);
      }
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data) {
        payload.data.forEach(function (val) {
          val.contentType = val.type;
          val.type = 'mail-template';
        });
        payload = payload.data;
      } else {
        payload.contentType = payload.type;
        payload.type = 'mail-template';
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });
});