define('yxt-apps/components/password-strength-meter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['addon'],
        classNameBindings: ['isPasswordValid:ok'],
        password: '',

        isPasswordValid: Ember.computed('password', function () {
            let pwd = this.get('password');
            if (pwd) {
                return (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(pwd)
                );
            }
        })

    });
});