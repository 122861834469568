define('yxt-apps/serializers/you-x-score', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeQueryRecordResponse(store, primaryModelClass, payload, id) {

      if (!payload.id || payload.id == null) {
        let randomString = Math.random().toString(36).slice(-8);
        payload.id = `youxscore_${randomString}`;
        id = payload.id;
      }
      if (!payload.type) {
        payload.type = 'you-x-score';
      }
      if (!payload.taskScores) {
        payload.taskScores = [];
      }

      payload.count = 1; //new endpoint don't have this... :/
      payload.uegScore = payload.total;
      payload.emotioncompleting = payload.rawScores.emotionsAverage;
      payload.ratecompleting = payload.rawScores.eouAverage;
      payload.howsatisfied = payload.rawScores.satisfactionAverage;

      delete payload.rawScores;
      delete payload.scoreOverMaxScore;
      delete payload.avgPerCategory;
      delete payload.sum;
      delete payload.total;

      payload.taskScores = [];
      for (var i = 0; i < payload.breakdown.length; i++) {

        if (payload.breakdown[i].total !== 0 && payload.breakdown[i].total !== '0') {
          var obj = {};
          obj.id = `${payload.id}_task_${i}`;
          obj.title = `Task ${i + 1}`;
          obj.task = `Task #${i + 1}`;
          obj.type = `you-x-score-task`;
          if (isNumber(payload.breakdown[i].total)) {
            obj.score = payload.breakdown[i].total.toFixed(1);
          } else {
            obj.score = 3.0;
          }

          //this don't exists on the new endpoint
          obj.uegScore = 0;

          //this duplication is because some components use emotion, others use emotioncompleting...the same for the two above
          if (isNumber(payload.breakdown[i].rawScores.emotioncompleting)) {
            obj.emotion = payload.breakdown[i].rawScores.emotioncompleting.toFixed(1);
          } else {
            obj.emotion = 3.0;
          }
          obj.emotioncompleting = obj.emotion;

          if (isNumber(payload.breakdown[i].rawScores.ratecompleting)) {
            obj.ease = payload.breakdown[i].rawScores.ratecompleting.toFixed(1);
          } else {
            obj.ease = 3.0;
          }
          obj.ratecompleting = obj.ease;

          if (isNumber(payload.breakdown[i].rawScores.howsatisfied)) {
            obj.satisfaction = payload.breakdown[i].rawScores.howsatisfied.toFixed(1);
          } else {
            obj.satisfaction = 3.0;
          }

          obj.howsatisfied = obj.satisfaction;

          obj.youXScore = payload.id;
          payload.taskScores.push(obj);
        }
      }
      delete payload.breakdown;
      return this.normalizeSingleResponse(...arguments);
    },
    normalizeFindRecordResponse(store, primaryModelClass, payload, id) {

      if (!payload.id || payload.id == null) {
        let randomString = Math.random().toString(36).slice(-8);
        payload.id = `youxscore_${randomString}`;
        id = payload.id;
      }
      if (!payload.type) {
        payload.type = 'you-x-score';
      }
      if (!payload.taskScores) {
        payload.taskScores = [];
      }

      payload.count = 1; //new endpoint don't have this... :/
      payload.uegScore = payload.total;
      payload.emotioncompleting = payload.rawScores.emotionsAverage;
      payload.ratecompleting = payload.rawScores.eouAverage;
      payload.howsatisfied = payload.rawScores.satisfactionAverage;

      delete payload.rawScores;
      delete payload.scoreOverMaxScore;
      delete payload.avgPerCategory;
      delete payload.sum;
      delete payload.total;

      payload.taskScores = [];
      for (var i = 0; i < payload.breakdown.length; i++) {
        if (payload.breakdown[i].total !== 0 && payload.breakdown[i].total !== '0') {
          var obj = {};
          obj.id = `${payload.id}_task_${i}`;
          obj.title = `Task ${i + 1}`;
          obj.task = `Task #${i + 1}`;
          obj.type = `you-x-score-task`;
          if (isNumber(payload.breakdown[i].total)) {
            obj.score = payload.breakdown[i].total.toFixed(1);
          } else {
            obj.score = 3.0;
          }

          //this don't exists on the new endpoint
          obj.uegScore = 0;

          //this duplication is because some components use emotion, others use emotioncompleting...the same for the two above
          if (isNumber(payload.breakdown[i].rawScores.emotioncompleting)) {
            obj.emotion = payload.breakdown[i].rawScores.emotioncompleting.toFixed(1);
          } else {
            obj.emotion = 3.0;
          }
          obj.emotioncompleting = obj.emotion;

          if (isNumber(payload.breakdown[i].rawScores.ratecompleting)) {
            obj.ease = payload.breakdown[i].rawScores.ratecompleting.toFixed(1);
          } else {
            obj.ease = 3.0;
          }
          obj.ratecompleting = obj.ease;

          if (isNumber(payload.breakdown[i].rawScores.howsatisfied)) {
            obj.satisfaction = payload.breakdown[i].rawScores.howsatisfied.toFixed(1);
          } else {
            obj.satisfaction = 3.0;
          }

          obj.howsatisfied = obj.satisfaction;

          obj.youXScore = payload.id;
          payload.taskScores.push(obj);
        }
      }
      delete payload.breakdown;
      return this.normalizeSingleResponse(...arguments);
    }
  });
});