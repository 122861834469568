define('yxt-apps/routes/admin/roles/role', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model(params) {
            return this.get('store').findRecord('role', params.role_id);
        },
        actions: {
            updateRole(role) {
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }
                function notifySuccess(role) {
                    self.toast.success(`Role (${role.get('name')}) updated succesfully`);
                }
                let _role = this.get('store').peekRecord('role', role.get('id'));
                _role.save().then(notifySuccess).catch(failure);
            },
            cancelChanges(role) {
                let _role = this.get('store').peekRecord('role', role.get('id'));
                _role.rollbackAttributes();
            }
        }
    });
});