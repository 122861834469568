define('yxt-apps/components/storage-capacity', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isShowStorageHelp: false,
        tagName: 'section',
        classNameBindings: ['isStorageVisible:storage-capacity', 'isShowStorageHelp:show-tooltip', 'isOverlimit:overlimit', 'isSafe:safe', 'isWarning:warning', 'isCritical:critical'],
        isStorageVisible: false,
        isOverlimit: false,
        isSafe: true,
        isWarning: false,
        isCritical: false,
        willInsertElement() {
            let storageCapacity = this.get('me.orgCapacity');
            let storageUsed = this.get('me.orgStored');
            let percentUsed = (storageUsed / storageCapacity * 100).toFixed(0);
            if (storageCapacity !== 'Unlimited') {
                this.set('isStorageVisible', true);
            }
            if (storageUsed > storageCapacity) {
                this.set('isCritical', false);
                this.set('isWarning', false);
                this.set('isSafe', false);
                this.set('isOverlimit', true);
            } else if (percentUsed > 90) {
                this.set('isCritical', true);
                this.set('isWarning', false);
                this.set('isSafe', false);
                this.set('isOverlimit', false);
            } else if (percentUsed > 70) {
                this.set('isCritical', false);
                this.set('isWarning', true);
                this.set('isSafe', false);
                this.set('isOverlimit', false);
            } else {
                this.set('isCritical', false);
                this.set('isWarning', false);
                this.set('isSafe', true);
                this.set('isOverlimit', false);
            }
        },
        actions: {
            toggleStorageHelp() {
                this.toggleProperty('isShowStorageHelp');
            },
            showStorageHelp() {
                this.set('isShowStorageHelp', true);
            },
            hideStorageHelp() {
                this.set('isShowStorageHelp', false);
            }
        }
    });
});