define('yxt-apps/components/icon/info-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'i',
    classNames: ['icon']
  });
});