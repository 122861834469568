define('yxt-apps/routes/reaction/studies/study/results/participant', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = { title: `${pageName} Results` };
            return breadCrumbTitle;
        }),
        model(params) {
            let parentModel = this.modelFor('reaction.studies.study');

            let selectedParticipant = this.get('store').peekRecord('participant-result', params.participant_id);

            return Ember.RSVP.hash({
                selectedParticipant: selectedParticipant,
                studyTypes: parentModel.studyTypes,
                study: parentModel.study,
                participants: parentModel.participants,
                participantResults: parentModel.participantResults
            });
        },

        actions: {
            didTransition() {
                // override the default scroll up behavior
                this.set('state.isLoading', false);
            },
            changeStudyEntryView(view) {
                let reportModel = this.modelFor('reaction.studies.study.result');
                reportModel.studyEntryViewType = view;
                this.set('currentModel.studyEntryViewType', view);
            }
        }
    });
});