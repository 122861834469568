define('yxt-apps/components/excel-exporter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function removeTags(str) {
        if (str === null || str === '') return false;else str = str.toString();
        str = str.replace(/(&nbsp;)/ig, ' ');
        return str.replace(/(<([^>]+)>)/ig, '');
    }

    exports.default = Ember.Component.extend({
        excel: Ember.inject.service(),
        actions: {
            exportToExcel() {
                let fileName = `${this.get('study.title')}.xlsx`;
                let taskScores = null;
                let scoreData = [];
                if (this.get('youxscore')) {
                    taskScores = this.get('youxscore.taskScores');
                    scoreData = [['TOTAL', this.get('youxscore.uegScore') / 2], ['Emotion', this.get('youxscore.emotioncompleting')], ['Satisfaction', this.get('youxscore.howsatisfied')], ['Ease of Use', this.get('youxscore.ratecompleting')], [''], ['Breakdown by Task']];

                    let taskScoresData = ['Task', 'Description', 'Score', 'Emotion', 'Satisfaction', 'Rate Completing'];
                    scoreData.push(taskScoresData);
                    this.get('youxscore.taskScores').forEach(function (task) {
                        scoreData.push([removeTags(task.task), removeTags(task.title), task.score / 2, task.emotion, task.satisfaction, task.ease]);
                    });
                }
                let participantResults = this.get('participantResults');

                // participant data
                let participantData = [];
                participantData.push(['Participant']);
                this.get('participantResults').forEach(function (participantResult, index) {
                    participantData.push(['___________________']);
                    participantData.push([`${participantResult.get('participant.profile.firstName')} ${participantResult.get('participant.profile.lastName')}`, participantResult.get('participant.email')]);
                    participantResult.get('entries.StudyEntry').forEach(function (entry) {

                        participantData.push(['TASKS']);
                        entry.taskData.forEach(function (task, index) {
                            participantData.push([`#${index + 1}`, removeTags(task.taskDescription)]);

                            participantData.push(['', 'Duration', `${task.duration} seconds`]);
                            participantData.push(['', 'Emotion', task.emotioncompleting]);
                            participantData.push(['', 'Satisfaction', task.howsatisfied]);
                            participantData.push(['', 'Ease', task.ratecompleting]);

                            entry.pages.forEach(function (page, pgIndex) {
                                page.templateId.content.forEach(function (questionContent, questionIndex) {
                                    // if pgIndex == index
                                    if (pgIndex == index) {
                                        if (questionIndex > 0) {
                                            let _answer = '';

                                            page.answers.forEach(function (answer, answerIndex) {
                                                if (answerIndex + 1 == questionIndex) {

                                                    switch (questionContent.contentType) {
                                                        case 'TextEntry':
                                                            _answer = answer;
                                                            break;
                                                        case 'Scale':
                                                            let ttl = 0;
                                                            questionContent.metadata.body.forEach(function (props) {
                                                                ttl = props.size;
                                                            });
                                                            _answer = `${answer}/${ttl}`;
                                                            break;
                                                        case 'MultiChoice':
                                                            questionContent.metadata.body.forEach(function (props, propsIndex) {
                                                                if (propsIndex == answer) {
                                                                    _answer += `${props.name}`;
                                                                }
                                                            });
                                                            break;
                                                        default:
                                                            _answer = answer;
                                                    }
                                                }
                                            });

                                            participantData.push(['', removeTags(questionContent.metadata.question), _answer]);
                                        }
                                    }
                                });
                            });
                        });
                    });

                    let postQuestionResults = participantResult.get('entries.PostSessionQuestions');
                    if (postQuestionResults) {
                        postQuestionResults.forEach(function (postQuestion) {
                            participantData.push(['POST SESSION QUESTIONS']);
                            postQuestion.pages.forEach(function (question, index) {
                                let answers = '';
                                question.answers.forEach(function (choice) {
                                    answers += `${choice} `;
                                });
                                participantData.push([`#${index + 1}`, removeTags(question.templateId.content[0].metadata.question), answers]);
                            });
                        });
                    }

                    participantData.push(['___________________']);
                    participantData.push(['']);
                });

                let sheets = [];
                if (this.get('youxscore')) {
                    sheets = [{
                        name: 'YouXScore',
                        data: scoreData
                    }, {
                        name: 'Participant Data',
                        data: participantData
                    }];
                } else {
                    sheets = [{
                        name: 'Participant Data',
                        data: participantData
                    }];
                }

                this.get('excel').export(sheets, { multiSheet: true, fileName: fileName });
            }
        }
    });
});