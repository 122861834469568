define('yxt-apps/components/account-profile-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            cancelChanges(user) {
                this.get('cancelChanges')(user);
            },
            updateUser(user) {
                this.get('updateUser')(user);
            },
            changePassword(user) {
                this.get('changePassword')(user);
            }
        }
    });
});