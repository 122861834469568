define('yxt-apps/serializers/project', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {

    attrs: {
      modifiedAt: { serialize: false },
      createdAt: { serialize: false },
      logo: { serialize: false },
      isSelected: { serialize: false }
    },
    modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'data') {
        let modelName = 'project';
        return this._super(payloadKey.replace('data', modelName));
      } else {
        return this._super(payloadKey);
      }
    },
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data) {
        payload = payload.data;
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });
});