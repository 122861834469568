define('yxt-apps/components/study-participant-manager-record', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'li',
        classNameBindings: ['participant.isEditing:editing'],
        isEditing: false,
        isValid: false,
        isRemoveParticipantFromStudyModal: false,
        myOwnTemplate: '',
        isEmailDirty: false,
        flagAsNewInvite: false,
        didInsertElement() {
            let myOwnTemplate = this.get('store').peekRecord('study-participant-template', this.get('config.participantMyOwnTemplateId'));
            this.set('myOwnTemplate', myOwnTemplate);

            $('#editing-first-name').focus();
        },
        actions: {
            onSuccess() {},
            onError() {},
            sendReminder(participant) {
                let self = this;

                function failure(reason) {
                    self.toast.error(reason);
                }

                function notifySuccess(participant) {
                    self.toast.success(`Reminder emailed successfully`);
                }
                let participantStatus = participant.get('status').toLowerCase();
                if (participantStatus == 'initial') {
                    participant.set('state', 'invited');
                    participant.save().then(() => {
                        participant.remind().then(notifySuccess).catch(failure);
                    });
                } else {
                    participant.remind().then(notifySuccess).catch(failure);
                }
                participant.set('wasReminderSent', true);
            },
            saveParticipant(participant) {
                participant.set('isEditing', false);
                let self = this;

                function failure(reason) {
                    self.toast.error(reason);
                }

                function closeEditing() {
                    self.get('participant').set('isEditing', false);
                    let newQty = self.get('study').get('participants.length');
                    self.get('study').set('participantQty', newQty);

                    if (self.get('study.isActive') && self.get('participant.isPreInvite')) {
                        inviteToStudy(self.get('participant'));
                    }
                }

                function saveToStudy(studyParticipant) {
                    self.get('study').get('participants').pushObject(studyParticipant);
                    self.get('study').save().then(closeEditing).catch(failure);
                }

                function assignToStudy(participant) {
                    self.get('participant').set('participant', participant);
                    self.get('participant').save().then(saveToStudy).catch(failure);
                }

                function inviteToStudy(participant) {
                    function reLaunchStudy() {
                        self.get('study').launch().then(() => {
                            self.toast.success(`Invitation email sent to ${participant.get('participant.email')}`);
                        });
                    }
                    function failure(reason) {
                        self.toast.error(reason);
                    }
                    // if study is complete we need to change it's state to 'active'
                    if (self.get('study.isComplete')) {
                        self.set('study.status', 'active');
                        self.get('study').save().then(reLaunchStudy).catch(failure);
                    } else {
                        reLaunchStudy();
                    }
                }

                self.get('participant').get('participant').then(function (participant) {
                    var curParticipantRecord = participant;
                    let changedAttrs = participant.changedAttributes();
                    let isThisEmailChanged = false;
                    if (changedAttrs.email) {
                        isThisEmailChanged = changedAttrs.email[0] !== participant.changedAttributes().email[1];
                    }
                    self.set('isEmailDirty', isThisEmailChanged);
                    self.set('flagAsNewInvite', isThisEmailChanged);
                    if (participant.get('profile.firstName') != "" && participant.get('email') != "") {
                        participant.set('template', self.get('myOwnTemplate'));

                        // does participant exist?
                        let participantEmail = participant.get('email');

                        curParticipantRecord.save().then(assignToStudy).catch(failure);
                    }
                });
                let newQty = self.get('study').get('participants.length');
                self.get('study').set('participantQty', newQty);
            },

            saveAndAddParticipant(participant) {
                let self = this;

                function failure(reason) {
                    self.toast.error(reason);
                }
                function inviteToStudy(participant) {
                    self.get('study').launch().then(() => {
                        self.toast.success(`Invitation email sent to ${participant.get('participant.email')}`);
                    });
                }
                function closeEditing() {
                    self.get('participant').set('isEditing', false);

                    let person = self.get('store').createRecord('participant', {
                        isEditing: true,
                        template: self.get('myOwnTemplate')
                    });
                    person.set('profile', self.get('store').createFragment('profile', {}));

                    self.get('store').createRecord('study-participant', {
                        study: self.get('study'),
                        participant: person,
                        isEditing: true
                    });

                    let newQty = self.get('study').get('participants.length');
                    self.get('study').set('participantQty', newQty);

                    if (self.get('study.isActive')) {
                        inviteToStudy(self.get('participant'));
                    }
                }

                function saveToStudy(studyParticipant) {
                    self.get('study').get('participants').pushObject(studyParticipant);
                    self.get('study').save().then(closeEditing).catch(failure);
                }

                function assignToStudy(participant) {
                    //
                    self.get('participant').set('participant', participant);
                    self.get('participant').save().then(saveToStudy).catch(failure);
                }
                self.get('participant').get('participant').then(function (participant) {
                    var curParticipantRecord = participant;
                    if (participant.get('profile.firstName') != "" && participant.get('email') != "") {
                        participant.set('template', self.get('myOwnTemplate'));

                        // does participant exist?
                        let participantEmail = participant.get('email');

                        curParticipantRecord.save().then(assignToStudy).catch(failure);
                    }
                });
            },

            editParticipant(participant) {
                participant.set('isEditing', true);
            },
            cancelParticipant(participant) {
                participant.set('isEditing', false);
                participant.set('isSelected', false);
                if (participant.get('participant').get('hasDirtyAttributes')) {
                    participant.rollbackAttributes();
                }
            },
            confirmDeleteParticipant(participant) {
                this.set('isRemoveParticipantFromStudyModal', true);
            },
            cancelModal() {
                this.set('isRemoveParticipantFromStudyModal', false);
                this.get('state').closeModal();
            },
            removeParticipant(participant) {
                let self = this;
                participant.set('isEditing', false);

                function deleteParticipant() {
                    participant.set('study', null);
                    if (participant.get('id') != null) {
                        participant.destroyRecord();
                    } else {
                        participant.deleteRecord();
                    }

                    let newQty = self.get('study').get('participants.length');
                    self.get('study').set('participantQty', newQty);
                }
                this.set('isRemoveParticipantFromStudyModal', false);
                this.get('state').closeModal();
                this.$().addClass('destroying');
                window.setTimeout(deleteParticipant, 500);
            },

            addParticipant() {
                let self = this;
                let person = self.get('store').createRecord('participant', {
                    isEditing: true,
                    template: this.get('myOwnTemplate')
                });
                person.set('profile', self.get('store').createFragment('profile', {}));

                self.get('store').createRecord('study-participant', {
                    study: self.get('study'),
                    participant: person,
                    isEditing: true
                });

                let newQty = self.get('study').get('participants.length');
                self.get('study').set('participantQty', newQty);
            }

        }
    });
});