define('yxt-apps/models/organization', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        createdAt: _emberData.default.attr(),
        modifiedAt: _emberData.default.attr(),
        tenantId: _emberData.default.attr('string'),
        numberOfEmployees: _emberData.default.attr('number', { defaultValue: 0 }),
        accountType: _emberData.default.attr('string', { defaultValue: 'light' }),
        owner: _emberData.default.belongsTo('user'),
        currentStorage: _emberData.default.attr('number', { defaultValue: 0 }),
        storageLimit: _emberData.default.attr('number', { defaultValue: 0 }),
        isStorageLimited: Ember.computed('storageLimit', {
            get() {
                if (this.get('storageLimit') == 0) {
                    return false;
                } else {
                    return true;
                }
            },
            set(key, value) {
                if (value == true) {
                    return this.set('storageLimit', 26843545600);
                } else {
                    return this.set('storageLimit', 0);
                }
            }
        }),
        storageLimitInGB: Ember.computed('storageLimit', {
            get() {
                return (this.get('storageLimit') / 1073741824).toFixed(0);
            },
            set(key, value) {
                let newVal = (value / 1073741824).toFixed(0);
                return this.set('storageLimit', newVal);
            }
        })
    });
});