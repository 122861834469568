define('yxt-apps/initializers/state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('model', 'state', 'service:state');
    application.inject('route', 'state', 'service:state');
    application.inject('controller', 'state', 'service:state');
    application.inject('component', 'state', 'service:state');
  }

  exports.default = {
    name: 'state',
    initialize: initialize
  };
});