define('yxt-apps/helpers/short-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shortUrl = shortUrl;
  function shortUrl(value) {
    value = value.toString().replace('https://', '');
    value = value.toString().replace('http://', '');
    value = value.toString().replace('www.', '');
    return `${value}`;
  }

  exports.default = Ember.Helper.helper(shortUrl);
});