define('yxt-apps/routes/reaction/account/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel() {
      this.transitionTo('reaction.account.profile');
    },
    afterModel: function (transition) {
      Ember.$('body > div.ember-view').animate({ scrollTop: 0 }, 500);
      this._super(transition);
    }
  });
});