define('yxt-apps/components/upload-custom-report', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        pdfReport: null,
        isUploading: false,
        actions: {
            uploadPDF() {
                let self = this;

                if (self.get('pdfReport')) {
                    self.set('isUploading', true);

                    function saveReportToStudy(asset) {

                        let study = self.get('study');
                        study.set('logo', self.get('store').createFragment('logo', {
                            url: asset.get('url'),
                            size: asset.get('size'),
                            key: asset.get('key'),
                            contentType: asset.get('contentType')
                        }));
                        study.set('status', 'completed');
                        study.save();
                        self.toast.success("Report uploaded successfully");
                        self.set('isUploading', false);
                        self.set('pdfReport', null);
                    }

                    let file = document.getElementById('pdf-field').files[0];
                    let newAsset = this.get('store').createRecord('asset', {
                        asset: file
                    });
                    newAsset.save().then(saveReportToStudy);
                }
            },

            uploadZip() {
                let self = this;
                let study = this.get('study');
                let project = this.get('study.project');
                let myUsername = this.get('me.username');
                let myOrg = this.get('me.organization');
                let yxtEnv = this.get('config.environment');
                function saveReportToStudy(asset) {
                    study.set('instructions.reportpath', asset.get('path'));
                    study.set('status', 'completed');
                    study.save();
                    self.set('isUploading', false);
                    self.set('pdfReport', null);
                    self.get('updateModel')(); // calls to parent route
                }

                let file = document.getElementById('zip-field').files[0];
                let newAsset = this.get('store').createRecord('ford-html-report', {
                    study: study,
                    project: project,
                    created_by: myUsername,
                    organization: myOrg,
                    yxtEnv: yxtEnv,
                    zip: file
                });
                self.set('isUploading', true);
                newAsset.save().then(saveReportToStudy);
            }
        }
    });
});