define('yxt-apps/routes/ford/projects/project/request-new-evaluation', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'moment'], function (exports, _authenticatedRouteMixin, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: {
            title: 'Request a New Evaluation'
        },
        model(params) {
            let self = this;
            let projects = this.get('store').findAll('project');
            let primaryProjectId = this.get('me.primaryProject');
            let primaryProject = this.get('store').peekRecord('project', primaryProjectId);

            let studyTypes = this.get('store').findAll('study-template');
            let study = this.get('store').createRecord('study', {
                title: null,
                template: self.get('config.reactionTypeId'),
                workflowType: 'myown',
                language: 'en',
                project: primaryProject,
                participantData: {
                    templateId: self.get('config.participantMyOwnTemplateId'),
                    quantity: 0
                },
                instructions: self.get('store').createFragment('study-meta', {
                    country: 'US',
                    deviceType: 'Desktop / Laptop',
                    recruit: 'General',
                    referrer: 'Ford Portal',
                    requestedBy: self.get('store').createFragment('profile', {
                        firstName: self.get('me.firstName'),
                        lastName: self.get('me.lastName')
                    })
                })
            });

            // WEBSITE TASKS
            let studyWebsiteTasks = this.get('store').createRecord('study-website-task', {
                study: study
            });
            // create 1 empty tasks
            let taskNamePrefix = `Task `;
            for (var i = 0; i < 1; i++) {
                let newItem = this.get('store').createRecord('page-template', {
                    title: `${taskNamePrefix}${i}`,
                    studyWebsiteTask: studyWebsiteTasks,
                    isEditing: true,
                    content: [{
                        name: null,
                        contentType: 'TextEntry',
                        metadata: {
                            isMandatory: true,
                            question: ''
                        }
                    }]
                });

                newItem.set('studyWebsiteTask', studyWebsiteTasks);
            }

            let studyPostSessionQuestions = this.get('store').createRecord('study-post-session-question', {
                study: study
            });
            study.set('studyPostSessionQuestions', studyPostSessionQuestions);
            // create 3 empty questions
            let questionNamePrefix = `Question `;
            for (var j = 0; j < 3; j++) {
                let newQuestion = this.get('store').createRecord('page-template', {
                    title: `${questionNamePrefix}${j}`,
                    studyPostSessionQuestion: studyPostSessionQuestions,
                    isEditing: true,
                    content: [{
                        name: `${questionNamePrefix}-Content-${j}`,
                        contentType: 'Scale',
                        metadata: {
                            isMandatory: true,
                            size: 6,
                            question: '',
                            body: [{
                                name: '',
                                size: 6
                            }]
                        }
                    }]
                });

                newQuestion.set('studyPostSessionQuestion', studyPostSessionQuestions);
            }
            return Ember.RSVP.hash({
                studyTypes: studyTypes,
                study: study,
                projects: projects,
                studyWebsiteTasks: studyWebsiteTasks,
                studyPostSessionQuestions: studyPostSessionQuestions,
                isSubmitted: false,
                isError: false
            });
        },
        actions: {
            submitStudy() {
                let self = this;

                function failure(reason) {
                    self.toast.error("Sorry, something went wrong with your request.");
                    self.controller.set('model.isError', true);
                    notifyUE(study);
                }
                function failureTasks(reason) {
                    self.toast.error("Sorry, something went wrong with your tasks request.");
                    self.controller.set('model.isError', true);
                    //notifyUE(study);
                }
                function notifySuccess() {
                    self.toast.success("Request submitted successfully");
                    self.controller.set('model.isSubmitted', true);
                }
                function notifyUE(study) {
                    //let study = self.get('study');
                    let apiUrl = `https://${self.get('config.domain')}/api3/sendgrid/study-request/index.php`;
                    let isError = self.controller.get('model.isError');
                    let emailErrorHTMLContent = '';
                    if (isError) {
                        emailErrorHTMLContent = `
                        Note: there was an error when submitting this form.
                    `;
                    }
                    let emailHTMLContent = `
                    <div>
                      <h1 style="color: #002F6C;">Request for a new study</h1>
                      <table>
                        <tbody>
                          <tr>
                            <td colspan="2" style="padding: 8px;">${emailErrorHTMLContent}</td>
                          </tr>
                          <tr>
                            <th style="text-align: left; padding: 8px;">Name</th>
                            <td style="text-align: left; padding: 8px;">${study.get('title')}</td>
                          </tr>
                          <tr>
                            <th style="text-align: left; padding: 8px;">ID</th>
                            <td style="text-align: left; padding: 8px;">${study.get('id')}</td>
                          </tr>
                          <tr>
                            <th style="text-align: left; padding: 8px;">Team</th>
                            <td style="text-align: left; padding: 8px;">${study.get('project.name')}</td>
                          </tr>
                          <tr>
                            <th style="text-align: left; padding: 8px;">Requested By</th>
                            <td style="text-align: left; padding: 8px;">${study.get('instructions.requestedBy.fullName')} (<a href="mailto:${study.get('instructions.requestedByEmail')}">${study.get('instructions.requestedByEmail')}</a>)</td>
                          </tr>
                          <tr>
                            <th style="text-align: left; padding: 8px;">Due Date</th>
                            <td style="text-align: left; padding: 8px;">${study.get('instructions.requestedEndTime')}</td>
                          </tr>
                          <tr>
                            <th style="text-align: left; padding: 8px;">Goals &amp; Objectives</th>
                            <td style="text-align: left; padding: 8px;">${study.get('description')}</td>
                          </tr>
                          <tr>
                            <th style="text-align: left; padding: 8px;">Country for Testing</th>
                            <td style="text-align: left; padding: 8px;">${study.get('instructions.country')}</td>
                          </tr>
                          <tr>
                            <th style="text-align: left; padding: 8px;">Recruiting</th>
                            <td style="text-align: left; padding: 8px;">${study.get('instructions.recruit')}</td>
                          </tr>
                          <tr>
                            <th style="text-align: left; padding: 8px;">Recruit Custom</th>
                            <td style="text-align: left; padding: 8px;">${study.get('instructions.recruitCustomDescription')}</td>
                          </tr>

                          <tr>
                            <th style="text-align: left; padding: 8px;">Preferred Device</th>
                            <td style="text-align: left; padding: 8px;">${study.get('instructions.deviceType')}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div>
                        <a href="https://${self.get('config.domain')}/reaction/studies/${study.get('id')}" style="display: inline-block; text-decoration: none; padding: 20px; font-size: 21px; color: white; background: #002F6C; margin-top: 20px; margin-bottom: 20px;">Login to Reaction to Configure this Study</a>
                      </div>
                      </div>
                `;
                    let plainTextContent = `A new Ford study has been requested.  Login to youXtools Reaction (https://${self.get('config.domain')}/reaction/studies//${study.get('id')}) to configure and launch this study.`;
                    let emailData = {
                        toEmail: "ford@uegroup.com",
                        toName: "Ford Researchers",
                        subject: `Ford Study Request (${study.get('title')})`,
                        fromEmail: "support@uegroup.com",
                        fromName: "youXtools",
                        content: {
                            htmlContent: emailHTMLContent,
                            plainTextContent: plainTextContent
                        }
                    };
                    Ember.$.ajax({
                        beforeSend: function (request) {
                            request.setRequestHeader("Content-Type", "application/json");
                        },
                        method: "POST",
                        url: apiUrl,
                        cache: false,
                        crossDomain: true,
                        processData: false,
                        data: JSON.stringify(emailData),
                        dataType: "json",
                        contentType: "application/json"

                    }).done(function (res) {
                        return res;
                    });
                }

                let study = this.controller.get('model.study');
                //let websiteTasks = this.controller.get('model.studyWebsiteTasks');
                //let postSessionQuestions = this.controller.get('model.studyPostSessionQuestions');

                study.save().then(function (study) {
                    let websiteTasks = self.controller.get('model.studyWebsiteTasks');
                    websiteTasks.set('study', study);
                    websiteTasks.set('name', `${study.get('title')} websiteTasks`);
                    if (websiteTasks.get('url') == '' || websiteTasks.get('url') == null || websiteTasks.get('url') == undefined) {
                        websiteTasks.set('url', 'https://www.ford.com');
                    }

                    let tasksQty = websiteTasks.get('pages.length');

                    // do we need to clear out any blank tasks?
                    // commented out by moving this concept to the adapter instead.
                    // see: adapters/study-website-task.js

                    /*websiteTasks.get('pages').forEach(function(page, index){
                        let name = page.get('content.firstObject.name');
                        if(!name || name == '' || name == null || name == undefined ){
                            //page.set('studyWebsiteTask',null); // kill association
                            page.destroyRecord();
                        }
                    });*/

                    websiteTasks.save().catch(failureTasks);

                    let studyPostSessionQuestions = self.controller.get('model.studyPostSessionQuestions');
                    studyPostSessionQuestions.set('study', study);
                    studyPostSessionQuestions.set('name', `${study.get('title')} studyPostSessionQuestions`);

                    // do we need to clear out any blank postQuestions?
                    /*studyPostSessionQuestions.get('pages').forEach(function(page, index){
                        let question = page.get('content.firstObject.metadata.question');
                        if(!question || question == '' || question == null || question == undefined ){
                            page.set('studyPostSessionQuestion',null); // kill association
                            page.destroyRecord();
                        }
                    });*/

                    studyPostSessionQuestions.save();
                    notifyUE(study);
                }).then(notifySuccess).catch(failure);
            },
            cancel(study) {
                history.back();
            },
            deleteTask(page) {
                page.destroyRecord();
            },
            addTask(websiteTasks) {
                let taskNamePrefix = `Task `;
                let tasksQty = websiteTasks.get('pages.length');

                let newItem = this.get('store').createRecord('page-template', {
                    title: `${taskNamePrefix}${tasksQty + 1}`,
                    studyWebsiteTask: websiteTasks,
                    isEditing: true,
                    content: [{
                        name: null,
                        contentType: 'TextEntry',
                        metadata: {
                            isMandatory: true,
                            question: ''
                        }
                    }]
                });

                newItem.set('studyWebsiteTask', websiteTasks);
            }
        }
    });
});