define('yxt-apps/helpers/humanize-satisfaction-rating', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeSatisfactionRating = humanizeSatisfactionRating;
  function humanizeSatisfactionRating(params /*, hash*/) {
    let [rating] = params;
    if (rating === 1) {
      return 'Very unsatisfied';
    } else if (rating === 2) {
      return 'Not satisfied';
    } else if (rating === 3) {
      return 'Neutral';
    } else if (rating === 4) {
      return 'Satisfied';
    } else if (rating === 5) {
      return 'Very satisfied';
    }
  }

  exports.default = Ember.Helper.helper(humanizeSatisfactionRating);
});