define('yxt-apps/routes/reaction/studies/study/results', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    breadCrumb: Ember.computed('controller.model.study.title', function () {
      const pageName = this.get('controller.model.study.title') || 'Study';
      const breadCrumbTitle = { title: `${pageName} Results` };
      return breadCrumbTitle;
    }),
    studyId: '',
    getYouXScore(studyId) {
      return this.get('store').queryRecord('you-x-score', { studyId: studyId }).catch(() => {
        return null;
      });
    },
    getSharelink(studyId) {
      return this.get('store').queryRecord('study-report-share-link', { studyId: studyId }).catch(() => {
        return null;
      });
    },
    beforeModel() {
      this.get('state').set('isCreationFlowActive', false);
    },
    model() {
      let parentModel = this.modelFor('reaction.studies.study');
      let studyId = parentModel.study.get('id');
      this.set('studyId', studyId);
      return Ember.RSVP.hash({
        studyTypes: parentModel.studyTypes,
        study: parentModel.study,
        participants: parentModel.participants,
        completedParticipants: parentModel.completedParticipants,
        studyWebsiteTasks: parentModel.studyWebsiteTasks,
        participantResults: parentModel.participantResults,
        youxscore: this.getYouXScore(studyId),
        sharelink: this.getSharelink(studyId)

      });
    },

    onPoll() {
      let studyId = this.get('studyId');
      return this.getYouXScore(studyId).then(youxscore => {
        this.set('currentModel.youxscore', youxscore);
      });
    },

    afterModel() {
      let usersPoller = this.get('usersPoller');

      // Make sure we only create one poller instance. Without this every time onPoll
      // is called afterModel would create a new poller causing us to have a growing list
      // of pollers all polling the same thing (which would result in more frequent polling).
      if (!usersPoller) {
        usersPoller = this.get('pollboy').add(this, this.onPoll, this.get('config.pollFrequency'));
        this.set('usersPoller', usersPoller);
      }
    },
    deactivate() {
      const usersPoller = this.get('usersPoller');
      this.get('pollboy').remove(usersPoller);
    }

  });
});