define('yxt-apps/routes/ford/reset-password', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'yxt-apps/routes/config/environment'], function (exports, _unauthenticatedRouteMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    let self = undefined;
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        isConfirmed: false,
        token: null,
        model(params) {
            self = this;
            this.get('state').set('pagetitle', 'Reset your youXtools password');
            this.set('token', params.activateAccount_id);

            let currentUser = this.get('store').createRecord('signup', {});
            return Ember.RSVP.hash({
                user: currentUser,
                password: null,
                token: params.activateAccount_id
            });
        },
        actions: {
            changePassword(password) {
                Ember.$.ajax({
                    beforeSend: function (request) {
                        request.setRequestHeader("Authorization", `Cactus token=${self.get('currentModel.token')}`);
                    },
                    url: `${_environment.default.host}/${_environment.default.namespace}/user/password/forgotten/modify`,
                    method: 'POST',
                    data: {
                        password: password,
                        token: self.get('currentModel.token')
                    }
                }).done(function () {
                    self.transitionTo('reset-password-success');
                });
            },
            cancelChangePassword() {
                // reset form.
                document.getElementById("reset-password-form").reset();

                // go back to previous route.
                history.back();
            }
        }
    });
});