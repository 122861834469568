define('yxt-apps/models/mail', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        tenantId: _emberData.default.attr(),
        createdAt: _emberData.default.attr(),
        modifiedAt: _emberData.default.attr(),
        state: _emberData.default.attr(),
        category: _emberData.default.attr(),
        thread: _emberData.default.attr(),
        from: _emberData.default.attr(),
        fromName: _emberData.default.attr(),
        to: _emberData.default.attr(),
        toName: _emberData.default.attr(),
        subject: _emberData.default.attr(),
        body: _emberData.default.attr(),
        bodyContentType: _emberData.default.attr()
    });
});