define('yxt-apps/models/asset', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        asset: _emberData.default.attr('file'),
        url: _emberData.default.attr('string'),
        size: _emberData.default.attr('number'),
        key: _emberData.default.attr('string'),
        contentType: _emberData.default.attr('string')
    });
});