define('yxt-apps/models/participant', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    template: _emberData.default.belongsTo("study-participant-template"),
    email: _emberData.default.attr("string"),
    profile: (0, _attributes.fragment)('profile'),
    modifiedAt: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    isViewing: _emberData.default.attr('boolean', { defaultValue: false }),
    isSelected: _emberData.default.attr('boolean', { defaultValue: false }), // for checkbox selection
    isEditable: Ember.computed('participantType', function () {
      let participantType = this.get('participantType');
      let isSuperAdmin = this.get('me.isSuperAdmin');
      if (participantType === 'express') {
        return false;
      } else if (participantType === 'premium' && isSuperAdmin) {
        return true;
      } else if (participantType === 'myown') {
        return true;
      } else {
        return false;
      }
    }),
    participantResult: _emberData.default.belongsTo('participantResult'),
    workerData: _emberData.default.attr(),
    participantType: Ember.computed('template', {
      get() {
        let template = this.get('template');
        if (template === '59c94b36d34d6d0a480896c7') {
          return 'premium';
        } else if (template === '59c94b36d34d6d0a480896c8') {
          return 'myown';
        } else if (template === '59c94b36d34d6d0a480896c5') {
          return 'express';
        }
      },
      set(key, value) {
        let template = this.get('template');
        if (value === 'premium') {
          this.set(template, "59c94b36d34d6d0a480896c7");
        } else if (value === 'myown') {
          this.set(template, "59c94b36d34d6d0a480896c8");
        } else if (value === 'express') {
          this.set(template, "59c94b36d34d6d0a480896c5");
        }
      }
    }),
    isEmailValid: Ember.computed('email', function () {
      let email = this.get('email');

      if (email) {
        if (email.length >= 5) {
          let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(email.toLowerCase());
        }
      }
    })
  });
});