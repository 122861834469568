define('yxt-apps/models/study-journal-task', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string', { defaultValue: 'Journal Prompts' }),
        pages: _emberData.default.hasMany('page-template'),
        study: _emberData.default.belongsTo('study'),
        createdAt: _emberData.default.attr(),
        modifiedAt: _emberData.default.attr(),
        areTasksValid: Ember.computed('pages.@each.isPageValid', 'pages.@each.isValid', 'pages.@each.content', function () {
            if (this.get('pages').filterBy('isPageValid', false).length > 0) {
                return false;
            } else {
                return true;
            }
        })
    });
});