define('yxt-apps/routes/journal/studies/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: {
            title: 'Dashboard'
        },
        areAllStudiesSelected: false,
        model() {
            return this.get('store').query('study', {
                filterByField: 'template',
                filterValue: this.get('config.journalTypeId')
            }).then(function (studies) {
                return studies.sortBy('modifiedAt').reverse();
            });
        },
        beforeModel() {
            this.set('headData.title', 'YouXJournal - Studies');
        },
        actions: {
            toggleSelectAll() {
                this.toggleProperty('areAllStudiesSelected');
                let isSelected = this.get('areAllStudiesSelected');
                let studies = this.controller.get('model');
                studies.toArray().forEach(function (study) {
                    study.set('isSelected', isSelected);
                });
            },
            createNewJournalStudy() {
                let self = this;
                let studiesQty = this.get('currentModel.length');
                let newStudy = self.get('store').createRecord('study', {
                    title: `New Study (${studiesQty})`,
                    description: '',
                    template: self.get('config.journalTypeId'),
                    workflowType: 'myown',
                    language: 'en',
                    participantData: {
                        templateId: self.get('config.participantMyOwnTemplateId'),
                        quantity: 0
                    }
                });

                function failure(reason) {
                    self.toast.error(reason);
                }

                function transitionToNewStudy(study) {
                    self.transitionTo('journal.studies.study', study.get('id'));
                }
                newStudy.save().then(transitionToNewStudy).catch(failure);
            },
            deleteSelectedStudies() {
                this.get('store').findAll('study').then(function (studies) {
                    studies.toArray().forEach(function (study) {
                        if (study.get('isSelected')) {
                            study.deleteRecord();
                            study.save();
                        }
                    });
                });
            }
        }
    });
});