define('yxt-apps/routes/admin/study-participant-templates/study-participant-template', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model(params) {
      return this.get('store').findRecord('study-participant-template', params.studyParticipantTemplate_id);
    }
  });
});