define('yxt-apps/components/chart/breakdown-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let easeColors = { 'red': '#E33E43', 'orange': '#F08601', 'yellow': '#F0E507', 'lime': '#81C23E', 'green': '#2A7F2A' };
  let emotionColors = { 'red': '#E33E43', 'orange': '#F08601', 'yellow': '#F0E507', 'lime': '#81C23E', 'green': '#2A7F2A' };
  let satisfactionColors = { 'red': '#E33E43', 'orange': '#F08601', 'yellow': '#F0E507', 'lime': '#81C23E', 'green': '#2A7F2A' };

  let scoreColors = emotionColors;
  let colors = { 'ease': easeColors, 'satisfaction': satisfactionColors, 'emotion': emotionColors };

  let width = 1100;
  let height = 265;
  let chartHeight = height - 80;
  let leftMargin = 30;
  let barWidth = 8;
  let barSpacing = 7;
  let groupSpacing = 80;
  let groupInnerWidth = 3 * barWidth + 2 * barSpacing;
  let groupOuterWidth = groupSpacing + groupInnerWidth;
  let groupLeftSpacing = 50;
  let cornerRadius = 6;
  let scoreInnerRadius = groupInnerWidth / 2 + 2;

  let yScale = d3.scaleLinear().domain([0, 5]).range([chartHeight, 0]);

  let yAxis = d3.axisLeft().scale(yScale).ticks(5).tickSize(-width);

  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['chart-widget', 'breakdown-bar-chart'],
    id: 'breakdown-bar-chart',
    data: [],
    taskScores: [],

    mapValueToColor(dimension, value) {
      let colorSet = colors[dimension];
      let result = value < 2 ? colorSet.red : value < 3 ? colorSet.orange : value < 6 ? colorSet.yellow : value < 8 ? colorSet.lime : colorSet.green;
      return result;
    },

    draw() {
      let taskScores = this.get('taskScores');
      this.set('data', taskScores);

      // Convert raw data to chartData structure which can be used for bars
      let chartData = [];
      let self = this;
      this.data.forEach(function (d) {
        chartData.push([{ value: d.ease, color: self.mapValueToColor('ease', d.score) }, { value: d.satisfaction, color: self.mapValueToColor('satisfaction', d.score) }, { value: d.emotion, color: self.mapValueToColor('emotion', d.score) }]);
      });

      let svg = d3.select(`#${this.id}`).attr("width", width).attr("height", height).style("margin-left", leftMargin + "px");

      // Horizontal grid
      if (svg.select(".breakdown-bar-grid").size() === 0) {
        svg.append("g").attr("class", "breakdown-bar-grid").attr("transform", "translate(10,5)").call(yAxis);
      }

      // Bars
      if (svg.selectAll("g.breakdown-bar-group").size() !== chartData.length) {
        // If the number of tasks is different than before, remove all bars and redraw everything
        svg.select("g.breakdown-bar-bars").remove();
        svg.append("g").attr("class", "breakdown-bar-bars").attr("transform", "translate(" + groupLeftSpacing + ",5)").selectAll("g").data(chartData).enter().append("g").attr("class", "breakdown-bar-group").attr("transform", function (d, i) {
          return "translate(" + groupOuterWidth * i + ",0)";
        }).selectAll("rect").data(function (d) {
          return d;
        }).enter().append("rect").attr("height", function () {
          return 0;
        }).attr("width", barWidth).attr("x", function (d, i) {
          return i * (barWidth + barSpacing);
        }).attr("y", function () {
          return yScale(0);
        }).attr("fill", function (d) {
          return d.color;
        }).attr("rx", cornerRadius).attr("ry", cornerRadius).transition().duration(2000).tween("updateBar", function (a) {
          let i = d3.interpolate(0, Number(a.value));
          this._current = i(1);
          let self = this;
          return function (t) {
            self.setAttribute("height", yScale(0) - yScale(i(t)) + 4);
            self.setAttribute("y", yScale(i(t)));
          };
        });
      } else {
        // The number of tasks is unchanged, so just update the bar sizes to match the new data
        svg.select("g.breakdown-bar-bars").data(chartData).selectAll("g.breakdown-bar-group").data(chartData).selectAll("rect").data(function (d) {
          return d;
        }).attr("fill", function (d) {
          return d.color;
        }).transition().duration(2000).tween("updateBar", function (a) {
          let i = d3.interpolate(this._current, Number(a.value) * 1);
          this._current = i(1);
          let self = this;
          return function (t) {
            self.setAttribute("height", yScale(0) - yScale(i(t)) + 4);
            self.setAttribute("y", yScale(i(t)));
          };
        });
      }

      // Score inner circles in mapped color
      svg.selectAll("circle.breakdown-bar-score-inner").remove();
      svg.selectAll("circle.breakdown-bar-score-inner").data(this.data).enter().append("circle").attr("cx", function (d, i) {
        return groupLeftSpacing + groupOuterWidth * i + groupInnerWidth / 2;
      }).attr("cy", chartHeight + 52).attr("r", scoreInnerRadius).attr("class", "breakdown-bar-score-inner").attr("fill", function (d) {
        if (d.score < 2) {
          return scoreColors.red;
        } else if (d.score < 4) {
          return scoreColors.orange;
        } else if (d.score < 6) {
          return scoreColors.yellow;
        } else if (d.score < 8) {
          return scoreColors.lime;
        } else return scoreColors.green;
      });

      // Score outer gray circles
      svg.selectAll("circle.breakdown-bar-score-outer").remove();
      svg.selectAll("circle.breakdown-bar-score-outer").data(this.data).enter().append("circle").attr("cx", function (d, i) {
        return groupLeftSpacing + groupOuterWidth * i + groupInnerWidth / 2;
      }).attr("cy", chartHeight + 52).attr("r", scoreInnerRadius + 3).attr("class", "breakdown-bar-score-outer").attr("stroke", "#EFEFEF").attr("stroke-width", 3).attr("fill", "none");

      // Score values inside circles
      svg.selectAll("text.breakdown-bar-score-value").remove();
      svg.selectAll("text.breakdown-bar-score-value").data(this.data).enter().append("text").attr("y", chartHeight + 58).attr("x", function (d, i) {
        return groupLeftSpacing + groupOuterWidth * i + groupInnerWidth / 2;
      }).attr("text-anchor", "middle").attr("class", "breakdown-bar-score-value").text(function (d) {
        return d.score / 2;
      });

      // Tooltip container
      svg.select("div.breakdown-bar-task-tooltip");
      let tooltipContainer = d3.select("body").append("div").attr("class", "breakdown-bar-task-tooltip").style("opacity", 0);

      // Task names along horizontal axis
      svg.selectAll("text.breakdown-bar-task-name").remove();
      svg.selectAll("text.breakdown-bar-task-name").data(this.data).enter().append("text").attr("y", chartHeight + 20).attr("x", function (d, i) {
        return groupLeftSpacing + groupOuterWidth * i + groupInnerWidth / 2;
      }).attr("text-anchor", "middle").attr("class", "breakdown-bar-task-name").text(function (d, i) {
        return "Task #" + (i + 1);
      }).on("mouseover", function (d) {
        tooltipContainer.transition().duration(200).style("opacity", .85);
        tooltipContainer.html(d.task).style("left", d3.event.pageX + "px").style("top", d3.event.pageY - 28 + "px");
      }).on("mouseout", function () {
        tooltipContainer.transition().duration(500).style("opacity", 0);
      });
    },

    didInsertElement() {
      this.draw();
    },

    didUpdateAttrs() {
      this.draw();
    }

  });
});