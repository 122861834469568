define('yxt-apps/routes/application', ['exports', 'ember-body-class/mixins/body-class', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _bodyClass, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Ember.Route.reopen({
    actions: {
      didTransition() {
        this._super(...arguments);
        Ember.$('body > div.ember-view').animate({ scrollTop: 0 }, 500);
        this.set('state.isLoading', false);
      },
      error() {
        this._super(...arguments);
        Ember.$('body').removeClass('loading');
        this.set('state.isLoading', false);
      },

      loading(transition) {
        this._super(...arguments);
        let self = this;
        Ember.$('body').addClass('loading');
        this.set('state.isLoading', true);

        transition.promise.finally(function () {
          Ember.$('body').removeClass('loading');
          self.set('state.isLoading', false);
        });
      }
    }
  });

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _bodyClass.default, {
    session: Ember.inject.service('session'),
    preloader: Ember.inject.service('preloader'),
    breadCrumb: null,
    beforeModel() {
      this.set('headData.title', 'YouXTools');
      this.get('store').findAll('study-participant-template');
      this.get('store').findAll('study-template');
    },
    actions: {
      didTransition() {
        this.get('preloader').addLoadedClass('fade-out');
        this.get('preloader').removePreloader(250);
      }
    }
  });
});