define('yxt-apps/transforms/file', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize: function (serialized) {
      return serialized;
    },

    serialize: function (deserialized) {
      return deserialized;
    }
  });
});