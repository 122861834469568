define('yxt-apps/config/ember-spinner/standard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    lines: 10, // The number of lines to draw
    length: 0, // The length of each line
    width: 10, // The line thickness
    radius: 21, // The radius of the inner circle
    scale: 1, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    color: '#393E4E', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    opacity: 0.65, // Opacity of the lines
    rotate: 0, // The rotation offset
    direction: 1, // 1: clockwise, -1: counterclockwise
    speed: 2, // Rounds per second
    trail: 60, // Afterglow percentage
    fps: 32, // Frames per second when using setTimeout() as a fallback in IE 9
    zIndex: 999999999999999, // The z-index (defaults to 2000000000)
    className: 'spinner', // The CSS class to assign to the spinner
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    position: 'fixed'
  };
});