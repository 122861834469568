define('yxt-apps/components/ford/request-new-evaluation-form', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        easeCompletionQuestion: 'Please rate the ease of completing this task',
        easeCompletionFormat: 'Scale',
        easeCompletionScalePoints: 5,
        easeCompletionIsRequired: true,

        satisfactionQuestion: 'How satisfied were you in completing this task?',
        satisfactionFormat: 'Scale',
        satisfactionScalePoints: 5,
        satisfactionIsRequired: true,

        emotionQuestion: 'What emotion did you feel while completing this task?',
        emotionFormat: 'Scale',
        emotionScalePoints: 5,
        emotionIsRequired: true,

        isSubmitDisabled: Ember.computed('study.title', 'study.isReactionSetupWorkflowComplete', 'study.description', 'projects', 'studyWebsiteTasks.urlPath', 'studyWebsiteTasks.@each.pages', 'studyWebsiteTasks.@each.pages.isPageAdvancedValid', 'studyWebsiteTasks.pages.@each.isPageAdvancedValid', 'studyWebsiteTasks.@each.pages.@each.content.@each.metadata', 'studyWebsiteTasks.@each.pages.@each.content.isQuestionValid', 'studyWebsiteTasks.@each.pages.@each.content.@each.metadata.question', 'studyWebsiteTasks.@each.pages.@each.content.@each.metadata.validChoices', 'study.instructions.requestedBy.firstName', 'study.instructions.requestedBy.lastName', 'study.instructions.requestedByEmail', function () {
            let title = this.get('study.title');
            let description = this.get('study.description');
            let url = this.get('studyWebsiteTasks.urlPath');
            let requestedByFN = this.get('study.instructions.requestedBy.firstName');
            let requestedByLN = this.get('study.instructions.requestedBy.lastName');
            let requestedByEmail = this.get('study.instructions.requestedByEmail');
            let studyWebsiteTasks = this.get('studyWebsiteTasks');
            let validQuestions2 = this.get('studyWebsiteTasks.pages').filterBy('isPageAdvancedValid', true);
            let invalidQuestions = this.get('studyWebsiteTasks.pages').filterBy('isPageAdvancedValid', false);
            let areAllQuestionsValid = invalidQuestions.length < 1;

            if (!url || !title || !description || title.length < 2 || !requestedByFN || !requestedByLN || !requestedByEmail || !areAllQuestionsValid) {
                return true;
            } else {
                return false;
            }
        }),
        actions: {
            submitStudy(study, websiteTasks, postSessionQuestions) {
                this.get('submitStudy')(study);
            },
            cancel(study) {
                this.get('cancel')(study);
            },
            formatUrl() {
                let urlPath = this.get('studyWebsiteTasks.urlPath');
                if (urlPath.startsWith('http')) {
                    // then they inserted a urlPath that included the protocol
                    let a = document.createElement('a');
                    a.href = urlPath;

                    urlPath = urlPath.replace(/(^\w+:|^)\/\//, '');
                    this.set('studyWebsiteTasks.urlPath', urlPath);
                    this.set('studyWebsiteTasks.urlProtocol', `${a.protocol}//`);
                }
            },
            addTask(websiteTask) {
                this.get('addTask')(websiteTask);
            },
            deleteTask(page) {
                this.get('deleteTask')(page);
            },
            addTaskQuestion(page) {
                let pageContents = page.get('content');

                let ranArray = new Uint32Array(1);
                window.crypto.getRandomValues(ranArray);
                let uId = `${moment()}_${ranArray[0]}`;
                let newtaskQuestionName = `Task_Question_${uId}`;

                pageContents.createFragment({
                    name: newtaskQuestionName,
                    contentType: 'Scale',
                    metadata: {
                        isMandatory: true,
                        question: '',
                        body: [{
                            name: '',
                            size: 6
                        }]
                    }
                });
            }
        }
    });
});