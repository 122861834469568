define('yxt-apps/components/study-participant-anon-generator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    let studyParticipants = [];
    exports.default = Ember.Component.extend({
        quantity: 10,
        actions: {
            onSuccess() {},
            onError() {},
            generateAnonParticipants() {
                let self = this;
                let study = this.get('study');
                let qty = this.get('quantity') * 1;
                let myOwnTemplate = self.get('store').peekRecord('study-participant-template', self.get('config.participantMyOwnTemplateId'));
                let anonEmailPrefix = 'ueparticipant+';
                let anonEmailDomain = '@gmail.com';
                for (var i = 1; i < qty + 1; i++) {
                    let person = self.get('store').createRecord('participant', {
                        isEditing: true,
                        template: myOwnTemplate,
                        email: `${anonEmailPrefix}${i}${anonEmailDomain}`
                    });
                    person.set('profile', self.get('store').createFragment('profile', {
                        firstName: 'Test',
                        lastName: i
                    }));

                    person.save().then(function (person) {
                        let newParticipant = self.get('store').createRecord('study-participant', {
                            study: study,
                            participant: person
                        });

                        newParticipant.save().then(function (newParticipant) {
                            studyParticipants.pushObject(newParticipant);
                            study.set('participantQty', study.get('participants.length'));

                            study.save();
                        });
                    });
                }
            }
        }
    });
});