define('yxt-apps/components/report-card/study-entry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    entry: null,
    pages: null,
    index: null,
    selectedIndex: null,
    isSelected: Ember.computed('selectedIndex', function () {
      if (this.get('index') == this.get('selectedIndex')) return true;else {
        return false;
      }
    }),
    thumbnailStyleReady: Ember.computed('', function () {
      /*let firstMediaQuestionName = '';
      let result = '';
      this.get('entry.pages').forEach(function(page) {
        let contentTemp = page.templateId.content.get('firstObject');
        if(contentTemp.contentType == 'Media' && result == '') {
          firstMediaQuestionName = contentTemp.name;
          result = page.content[firstMediaQuestionName].url;
        }
      });
       //sanitize the results
      if(result != '') {
        let tmp = result.split(".");
        let ext = tmp[tmp.length-1];
        if(ext != "jpg" && ext != "png" && ext != "gif")
        {
          return '';
        }
        else
          return new Ember.String.htmlSafe("background-image: url(" + result + ")");
      }
       return '';*/
      if (this.entry.thumbnail != '') return new Ember.String.htmlSafe("background-image: url(" + this.entry.thumbnail + ")");else return '';
    }),
    indexHuman: Ember.computed('index', function () {
      return this.get('index') + 1;
    }),
    didInsertElement() {},
    actions: {
      viewDetail(index) {
        this.set('pages', this.get('entry.pages'));
        this.set('selectedIndex', index);
      }
    }
  });
});