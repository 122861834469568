define('yxt-apps/models/study-participant-template', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    tenantId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    icon: _emberData.default.attr(),
    cost: _emberData.default.attr('string'),
    required: _emberData.default.attr(),
    demographics: _emberData.default.attr(),
    qualificationRequirements: _emberData.default.attr(),
    participantType: _emberData.default.attr('string'),
    autoApprovalDelayInSeconds: _emberData.default.attr('number'),
    template: _emberData.default.hasMany('study-template'),
    createdAt: _emberData.default.attr(),
    modifiedAt: _emberData.default.attr()
  });
});