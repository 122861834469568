define('yxt-apps/routes/ford/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    breadCrumb: Ember.computed('session.isAuthenticated', function () {
      const isAuthenticated = this.get('session.isAuthenticated') || false;
      if (isAuthenticated) {
        const dashTitle = {
          title: 'Studies'
        };
        return dashTitle;
      } else {
        return null;
      }
    }),
    afterModel() {
      if (this.get('session.isAuthenticated')) {
        let self = this;
        let teamProjectKey = this.get('me.userNotes');
        let teamProjectId;
        let teamProjectName;
        if (teamProjectKey == 'team-edison') {
          teamProjectName = 'Team Edison';
        } else if (teamProjectKey == 'ford-pass') {
          teamProjectName = 'Ford Pass';
        }

        if (teamProjectName) {
          let teamProject = this.get('store').query('project', {
            filterByField: 'name',
            filterValue: teamProjectName
          }).then(function (proj) {
            let myProj = proj.get('firstObject');
            self.get('me').savePrimaryProject(myProj.get('id'));
            self.transitionTo('ford.projects.project', myProj.get('id'));
          });
        } else {
          this.transitionTo('ford.projects');
        }
      } else {
        this.transitionTo('ford.login');
      }
    }

  });
});