define('yxt-apps/helpers/video-poster-src', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.videoPosterSrc = videoPosterSrc;
  function videoPosterSrc(params /*, hash*/) {
    let [pageanswers] = params;
    return pageanswers[0].thumbnail.url;
  }

  exports.default = Ember.Helper.helper(videoPosterSrc);
});