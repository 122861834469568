define('yxt-apps/components/reaction-study-list-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'li',
        classNameBindings: ['study.isSelected:selected'],
        classNames: ['list-item'],
        actions: {
            deleteStudy(study) {
                if (window.confirm(`Are you sure you want to delete ${study.get('title')}`)) {
                    study.destroyRecord(); // => DELETE to /study/{{id}}
                }
            }
        }
    });
});