define('yxt-apps/services/excel', ['exports', 'ember-spreadsheet-export/services/excel'], function (exports, _excel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _excel.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _excel.initialize;
    }
  });
});