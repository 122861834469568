define('yxt-apps/models/study-participant-responses-tasks', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    pages: _emberData.default.hasMany('page'),
    study: _emberData.default.belongsTo('study'),
    properties: _emberData.default.attr(),
    status: _emberData.default.attr('string'),
    studyParticipant: _emberData.default.belongsTo('study-participant'),
    studyParticipantEntryType: _emberData.default.attr(),
    templateId: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr(),
    modifiedAt: _emberData.default.attr(),
    __t: _emberData.default.attr()
  });
});