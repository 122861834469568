define('yxt-apps/models/study-template', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    tenantId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    study: _emberData.default.hasMany('study'),
    modifiedAt: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('string'),
    reward: _emberData.default.attr('string'),
    participants: _emberData.default.hasMany('study-participant-template'),
    features: _emberData.default.hasMany('study-feature-template'),
    studyType: _emberData.default.attr('string'),
    appName: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    tool: Ember.computed('studyType', function () {
      let studyType = this.get('studyType');
      return studyType.toLowerCase();
    }),
    alias: Ember.computed('name', function () {
      let name = this.get('name');
      return name;
    }),
    studyTypeAlias: Ember.computed('name', function () {
      let name = this.get('name');
      return name;
    }),
    cost: Ember.computed(function () {
      return 0;
    }),
    isTypeReaction: Ember.computed('studyType', function () {
      let studyType = this.get('studyType').toLowerCase();
      if (studyType === 'reaction') {
        return true;
      }
    }),
    isTypeFreePromo: Ember.computed('id', function () {
      let id = this.get('id');
      if (id === '59c94b36d34d6d0a480896cd') {
        return true;
      }
    }),
    isTypeReactionOrQualification: Ember.computed('studyType', function () {
      let studyType = this.get('studyType').toLowerCase();
      if (studyType === 'reaction') {
        return true;
      } else {
        return false;
      }
    }),
    isTypeJournal: Ember.computed('studyType', function () {
      let studyType = this.get('studyType').toLowerCase();
      if (studyType === 'journal') {
        return true;
      } else {
        return false;
      }
    }),
    isTypeComparison: Ember.computed('studyType', function () {
      let studyType = this.get('studyType').toLowerCase();
      if (studyType === 'comparison') {
        return true;
      }
    }),
    isTypeConfidential: Ember.computed('studyType', function () {
      let studyType = this.get('studyType').toLowerCase();
      if (studyType === 'confidential') {
        return true;
      }
    }),
    isTypeCustom: Ember.computed('studyType', function () {
      let studyType = this.get('studyType').toLowerCase();
      if (studyType === 'custom') {
        return true;
      }
    }),
    isTypeQualification: Ember.computed('name', function () {
      let name = this.get('name').toLowerCase();
      if (name === 'qualification') {
        return true;
      }
    }),
    isTypeDisabled: Ember.computed('name', function () {
      let name = this.get('name').toLowerCase();
      if (name === 'custom' || name === 'comparison' || name === 'confidential' || name === 'a/b testing') {
        return true;
      }
    }),
    isTypeVisible: Ember.computed('name', function () {
      let name = this.get('name').toLowerCase();
      if (name === 'boomerang' || name === 'a/b testing' || name === 'journal') {
        return true;
      }
    }),
    isTypeAdminOnly: Ember.computed('name', function () {
      let name = this.get('name').toLowerCase();
      if (name === 'qualification') {
        return true;
      }
    }),
    title: Ember.computed('name', function () {
      let name = this.get('name');
      return name;
    }),
    subtitle: Ember.computed('name', function () {
      let name = this.get('name').toLowerCase();
      if (name === 'boomerang') {
        return 'Remote Usability Testing';
      } else if (name === 'free express boomerang') {
        return 'Remote Usability Testing';
      } else if (name === 'journal') {
        return 'Diary Study';
      } else if (name === 'a/b testing') {
        return 'A/B Testing';
      } else if (name === 'confidential') {
        return 'In-Lab Study';
      } else if (name === 'custom') {
        return 'Custom';
      } else if (name === 'qualification') {
        return 'Express Qualification HIT';
      }
    }),
    descriptionAlias: Ember.computed('name', function () {
      let name = this.get('name').toLowerCase();
      if (name === 'reaction') {
        return 'Ask users to complete a series of tasks in order to measure how easy or difficult it is for them to use your website or prototype.';
      } else if (name === 'journal') {
        return 'Ask users to document how they use your product, or a similar product, in photos, videos and by answering your questions over a certain period of time.';
      } else if (name === 'comparison') {
        return 'Run a test with 2 different URL’s, or 2 versions of the same URL, in order to compare their results and determine which performed best.';
      } else if (name === 'confidential') {
        return 'Protect your information with live studies which store your videos on private servers and guarantee that participants can’t share what they see with others.';
      } else if (name === 'custom') {
        return 'Custom';
      } else if (name === 'qualification') {
        return 'Create a qualification training HIT for mTurk workers to use for approving mTurk workers';
      }
    })

  });
});