define('yxt-apps/routes/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        breadCrumb: null,
        beforeModel() {
            //this.get('me').saveAppName('tools');
            Ember.$('body').attr('appName', 'tools');
            if (this.get('session.isAuthenticated')) {
                // figure out which app we are in.
                if (this.get('me').appName() == 'reaction') {
                    this.transitionTo('reaction');
                } else if (this.get('me').appName() == 'journal') {
                    this.transitionTo('journal');
                } else if (this.get('me').appName() == 'admin') {
                    this.transitionTo('admin');
                } else if (this.get('me').appName() == 'ford') {
                    this.transitionTo('ford');
                }
            } else {
                // figure out which app we are in.
                if (this.get('me').appName() == 'reaction') {
                    this.transitionTo('reaction.login');
                } else if (this.get('me').appName() == 'journal') {
                    this.transitionTo('journal.login');
                } else if (this.get('me').appName() == 'admin') {
                    this.transitionTo('admin.login');
                } else if (this.get('me').appName() == 'ford') {
                    this.transitionTo('ford.login');
                }
            }
        }
    });
});