define('yxt-apps/serializers/registration-profile', ['exports', 'yxt-apps/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    serializeAttribute(snapshot, json, key) {
      if (snapshot.attr(key) != null && snapshot.attr(key) != '') {
        this._super(...arguments);
      }
    }
  });
});