define('yxt-apps/models/question-choice', ['exports', 'ember-data', 'ember-data-model-fragments/fragment', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _fragment, _attributes) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _fragment.default.extend({
        name: _emberData.default.attr('string'),
        size: _emberData.default.attr('number'),
        isChoiceValid: Ember.computed('name', function () {
            if (this.get('name')) {
                return true;
            } else {
                return false;
            }
        })
    });
});