define('yxt-apps/helpers/journal-duration-percent', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.journalDurationPercent = journalDurationPercent;
  function journalDurationPercent(params /*, hash*/) {
    let [startDate, numberOfDays] = params;
    let endDate = (0, _moment.default)(startDate).add(numberOfDays, 'days');
    let now = (0, _moment.default)();
    let nowBefore = (0, _moment.default)(now).diff(startDate, 'minutes');
    let nowAfter = (0, _moment.default)(now).diff(endDate, 'minutes');

    let totalDuration = Math.abs(nowBefore) + Math.abs(nowAfter);
    let endPercent = nowBefore / totalDuration * 100;
    if (endPercent < 3.5) {
      endPercent = 3.5;
    } else if (now > endDate) {
      endPercent = 100;
    }
    return endPercent;
  }

  exports.default = Ember.Helper.helper(journalDurationPercent);
});