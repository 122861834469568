define('yxt-apps/services/browser', ['exports', 'ember-browser-info/services/browser', 'yxt-apps/config/environment'], function (exports, _browser, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const config = _environment.default['ember-browser-info'] || {};

  exports.default = _browser.default.extend({
    geoService: config.geoService || 'freegeoip'
  });
});