define('yxt-apps/routes/reaction/studies/study/participants/participant-upload', ['exports', 'yxt-apps/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _environment, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        token: null,
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = { title: `${pageName} Overview` };
            return breadCrumbTitle;
        }),
        model(params) {
            let parentModel = this.modelFor('reaction.studies.study');

            let self = this;
            // first we want to auth this studyparticipant
            // get token
            let token = this.get('store').findRecord('participant-token', params.participant_id);

            // start assignment
            // send token in header
            // https://backend-qa.youxtools.com/api/v1/organization/5a8ba41f749a5b0001a984d8/studyParticipants/5d72fcffbe4e386454c4fcae/startAssignment

            let studyParticipant = this.get('store').findRecord('study-participant', params.participant_id);

            let studyTasks = [];
            let taskYXTproperties = {
                "howeasy": "0",
                "howsatisfied": "0",
                "overallemotion": "0" };

            // loop through all tasks and create this placeholder response data
            parentModel.studyWebsiteTasks.forEach(function (websiteTask) {
                websiteTask.get('pages').forEach(function (task) {
                    let taskContent = [];
                    task.get('content').forEach(function (content) {
                        taskContent.push({ id: content.get('name'), value: content.get('value') });
                    });
                    studyTasks.push({
                        properties: taskYXTproperties,
                        content: taskContent
                    });
                });
            });

            let studyParticipantTaskAnswers = this.get('store').createRecord('study-participant-responses-tasks', {
                study: parentModel.study.id,
                studyParticipant: params.participant_id,
                studyParticipantEntryType: "StudyEntry",
                pages: studyTasks
            });

            //let studyParticipantPostAnswers = this.get('store').createRecord('study-participant-responses-post-questions', {});
            //let studyParticipantScoreAnswers = this.get('store').createRecord('study-participant-responses-youxscore-post-questions', {});
            return Ember.RSVP.hash({
                studyParticipant: studyParticipant,
                studyTypes: parentModel.studyTypes,
                study: parentModel.study,
                studyWebsiteTasks: parentModel.studyWebsiteTasks,
                studyPostSessionQuestions: parentModel.studyPostSessionQuestions,
                answerTasks: studyParticipantTaskAnswers,
                //answerPostSessionQuestions: studyParticipantPostAnswers,
                //answerScores: studyParticipantScoreAnswers,
                token: token
            });
        }
    });
});