define('yxt-apps/routes/reaction/studies/study/participants', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = {
                title: `${pageName} Participants`
            };
            return breadCrumbTitle;
        }),
        beforeModel() {
            this.get('state').set('isCreationFlowActive', false);
        },
        afterModel: function (transition) {
            Ember.$('body > div.ember-view').animate({
                scrollTop: 0
            }, 500);
            this._super(transition);
        },
        actions: {
            editSelectedParticipants(participants) {
                // for each.
                participants.forEach(function (participant) {
                    if (participant.get('isSelected')) {
                        participant.set('isEditing', true);
                    }
                });
            },
            deleteSelectedParticipants(participants) {
                // for each.
                participants.forEach(function (participant) {
                    if (participant.get('isSelected')) {
                        participant.destroyRecord();
                    }
                });
            },
            toggleUploadEditor() {
                this.toggleProperty('currentModel.isUploadEditorOpen');
            },
            relaunch(study) {
                study.launch().then(() => {
                    this.toast.success('Invitations sent');
                });
            },
            sendReminder(participant) {
                let self = this;

                function failure(reason) {
                    self.toast.error(reason);
                }

                function notifySuccess(participant) {
                    self.toast.success(`Reminder emailed successfully`);
                }
                let participantStatus = participant.get('status').toLowerCase();
                if (participantStatus == 'initial') {
                    participant.set('state', 'invited');
                    participant.save().then(() => {
                        participant.remind().then(notifySuccess).catch(failure);
                    });
                } else {
                    participant.remind().then(notifySuccess).catch(failure);
                }
            },
            closeModal() {
                this.get('state').closeModal();
            },
            chooseParticipants() {
                function failure() {
                    self.transitionTo('reaction.studies.study.create.review');
                }

                function transitionToReview() {
                    self.transitionTo('reaction.studies.study.create.review');
                }
                let study = this.controller.get('model.study');
                study.save().then(transitionToReview).catch(failure);
            },
            addParticipant() {
                let study = this.controller.get('model.study');
                let self = this;
                let person = self.get('store').createRecord('participant', {
                    isEditing: true,
                    template: this.get('store').peekRecord('study-participant-template', this.get('config.participantMyOwnTemplateId'))
                });
                person.set('profile', self.get('store').createFragment('profile', {}));

                self.get('store').createRecord('study-participant', {
                    study: study,
                    participant: person,
                    isEditing: true
                });

                let newQty = study.get('participants.length');
                study.set('participantQty', newQty);
                let contentHeight = Ember.$('body > div.ember-view').height();
                Ember.$('body > div.ember-view').animate({ scrollTop: contentHeight }, 500);
            },
            readCSVfile(file) {
                let study = this.controller.get('model.study');
                let self = this;
                let participantTemplate = self.get('store').peekRecord('study-participant-template', self.get('config.participantMyOwnTemplateId'));

                Ember.$("input[type=file]").parse({
                    config: {
                        header: true,
                        complete: function (results, file) {
                            results.data.forEach(function (newPerson) {
                                let person = self.get('store').createRecord('participant', {
                                    isEditing: false,
                                    template: participantTemplate,
                                    email: newPerson.email
                                });
                                person.set('profile', self.get('store').createFragment('profile', {
                                    firstName: newPerson.firstName,
                                    lastName: newPerson.lastName,
                                    email: newPerson.email
                                }));

                                person.save().then(function (newb) {
                                    let studyParticipantRecord = self.get('store').createRecord('study-participant', {
                                        study: study,
                                        participant: newb,
                                        isEditing: false
                                    });
                                    studyParticipantRecord.save();
                                });
                            });
                        }
                    }
                });
            }
        }
    });
});