define('yxt-apps/serializers/page', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      modifiedAt: { serialize: false },
      createdAt: { serialize: false },
      isEditing: { serialize: false },
      content: { embedded: 'always' },
      studyWebsiteTask: { serialize: false },
      studyJournalTask: { serialize: false },
      studyPostSessionQuestion: { serialize: false },
      studyPostSessionQuestionResponses: { serialize: false },
      studyParticipantTaskAnswers: { serialize: false },
      studyParticipantPostAnswers: { serialize: false },
      studyParticipantScoreAnswers: { serialize: false }
    }

  });
});