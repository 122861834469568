define('yxt-apps/routes/admin/study-participant-templates', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return this.get('store').findAll('study-participant-template').then(function (studyParticipantTempate) {
        return studyParticipantTempate.sortBy('name');
      });
    },
    actions: {}
  });
});