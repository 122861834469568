define('yxt-apps/routes/journal/studies/study/create/participants', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    let self = undefined;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = { title: `${pageName} - Add Participants` };
            return breadCrumbTitle;
        }),
        beforeModel() {
            this.get('state').set('isCreationFlowActive', true);
        },

        afterModel(transition) {
            self = this;
            this.get('state').set('studyCreationStep', 'participants');
            let parentModel = this.modelFor('journal.studies.study');
            let study = parentModel.study;
            let participantData = study.get('participantData');

            if (participantData) {
                study.set('participantType', 'myown');

                if (participantData.quantity) {
                    study.set('participantQty', participantData.quantity);
                }
            }

            Ember.$('body > div.ember-view').animate({
                scrollTop: 0
            }, 500);
            this._super(transition);
        },
        actions: {

            addParticipant() {
                let study = this.controller.get('model.study');
                let person = this.get('store').createRecord('participant', {
                    isEditing: true
                });
                this.get('store').createFragment('profile', {
                    participant: person
                });
                this.get('store').createRecord('study-participant', {
                    study: study,
                    participant: person,
                    isEditing: true
                });
            },
            closeModal() {
                this.get('state').closeModal();
            },
            chooseParticipants() {
                function failure() {
                    self.transitionTo('journal.studies.study.create.review');
                }

                function transitionToReview() {
                    self.transitionTo('journal.studies.study.create.review');
                }
                let study = this.controller.get('model.study');
                study.save().then(transitionToReview).catch(failure);
            },
            readCSVfile(file) {
                let study = this.controller.get('model.study');
                let self = this;
                let participantTemplate = self.get('store').peekRecord('study-participant-template', self.get('config.participantMyOwnTemplateId'));

                Ember.$("input[type=file]").parse({
                    config: {
                        header: true,
                        complete: function (results, file) {
                            results.data.forEach(function (newPerson) {
                                let person = self.get('store').createRecord('participant', {
                                    isEditing: false,
                                    template: participantTemplate,
                                    email: newPerson.email
                                });
                                person.set('profile', self.get('store').createFragment('profile', {
                                    firstName: newPerson.firstName,
                                    lastName: newPerson.lastName,
                                    email: newPerson.email
                                }));

                                person.save().then(function (newb) {
                                    let studyParticipantRecord = self.get('store').createRecord('study-participant', {
                                        study: study,
                                        participant: newb,
                                        isEditing: false
                                    });
                                    studyParticipantRecord.save();
                                });
                            });
                        }
                    }
                });
            },
            willTransition() {
                let self = this;
                function cleanup() {
                    let allParticipants = self.get('store').peekAll('study-participant');
                    allParticipants.toArray().forEach(function (participant) {
                        if (participant.get('id') !== null) {
                            // do nothing
                        } else {
                            participant.unloadRecord();
                        }
                    });
                }

                let study = this.controller.get('model.study');
                let qty = study.get('participants.length');
                qty = Number(qty);
                study.set('participantData', {
                    "templateId": this.get('config.participantMyOwnTemplateId'),
                    "quantity": qty
                });

                study.save().then(cleanup);
            }
        }
    });
});