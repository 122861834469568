define('yxt-apps/routes/ford/projects/create', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: {
            title: 'Projects'
        },
        model() {

            /*this.get('store').createRecord('project',{
                name: 'Ford Pass',
                description: '26'
            }).save();*/

            /*this.get('store').findRecord('project','5c8da4a2be4e38febfc4f32d').then(function(project){
                project.set('name','Team Edison');
                project.set('description','26');
                project.save();
            });*/

        },
        beforeModel() {
            this.set('headData.title', 'Ford - Projects');
        }
    });
});