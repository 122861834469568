define('yxt-apps/routes/reaction/account', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      let userId = this.get('me.id');
      let user = this.get('store').findRecord('user', userId);

      return user;
    },

    breadCrumb: null,
    displayEmailError: false,
    displayPasswordError: false,
    displayConfirmPasswordError: false,
    actions: {
      updateUser(user) {
        let self = this;
        function failure(reason) {
          self.toast.error(reason);
        }
        function notifyUserSuccess(user) {
          self.toast.success(`User (${user.get('username')}) updated succesfully`);
        }
        let _user = this.get('store').peekRecord('user', user.get('id'));
        _user.save().then(notifyUserSuccess).catch(failure);
      },
      cancelChanges(user) {
        user.rollbackAttributes();
        history.back();
      },
      changePassword(user) {
        function passwordUpdated() {
          user.set('passwordUpdated', true);
        }
        user.save().then(passwordUpdated);
      },
      blurPassword() {
        let username = this.get('currentModel.username');
        let isValid = this.get('currentModel.isPasswordValid');
        if (!this.get('currentModel.isPasswordValid')) {
          this.set('displayPasswordError', true);
        } else {
          this.set('displayPasswordError', false);
        }
      },
      blurConfirmPassword() {
        if (!this.get('currentModel.isPasswordMatched')) {
          this.set('displayConfirmPasswordError', true);
        } else {
          this.set('displayConfirmPasswordError', false);
        }
      }
    }
  });
});