define('yxt-apps/models/mail-template', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    contentType: _emberData.default.attr('string'),
    type: _emberData.default.attr(),
    study: _emberData.default.belongsTo('study'),
    name: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    mailType: _emberData.default.attr('string'),
    modifiedAt: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    tenantId: _emberData.default.attr(),
    layout: _emberData.default.attr('string'),
    messageInputId: Ember.computed('name', function () {
      return `${this.get('name')}_message`;
    }),
    subjectInputId: Ember.computed('name', function () {
      return `${this.get('name')}_subject`;
    }),

    isEditing: _emberData.default.attr('boolean', { defaultValue: false })
  });
});