define('yxt-apps/helpers/full-lang', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fullLang = fullLang;
  function fullLang(params /*, hash*/) {
    let [language] = params;
    if (language === 'en') {
      return 'English';
    } else if (language === 'es') {
      return 'Spanish';
    } else if (language === 'fr') {
      return 'French';
    } else if (language === 'pt') {
      return 'Portuguese';
    } else if (language === 'it') {
      return 'Italian';
    } else if (language === 'sv') {
      return 'Swedish';
    } else if (language === 'de') {
      return 'German';
    } else {
      return 'English';
    }
  }

  exports.default = Ember.Helper.helper(fullLang);
});