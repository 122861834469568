define('yxt-apps/serializers/participant-token', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONSerializer.extend({
        modelNameFromPayloadKey(payloadKey) {
            return 'participantToken';
        },
        normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            let token = payload;
            payload = {};
            payload.token = token;
            payload.id = token;
            return this._super(store, primaryModelClass, payload, id, requestType);
        }
    });
});