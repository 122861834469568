define('yxt-apps/adapters/mail', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),
    authorize(xhr) {
      let { token } = this.get('session.data.authenticated');
      if (Ember.isPresent(token)) {
        xhr.setRequestHeader('Authorization', `Cactus token=${token}`);
      }
    },
    host: `${undefined.get('config.host')}/${undefined.get('config.namespace')}`,
    pathForType: function () {
      return 'mail';
    },
    findAll() {
      const url = `${this.get('config.host')}/${this.get('config.namespace')}/mail/search`;
      return this.ajax(url, "POST", {});
    }
  });
});