define('yxt-apps/components/score-card-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function setColorDefinition(props) {
    props = props || {};
    props.score = props.score || 1;
    props.multiplier = props.multiplier || 1;
    props.score = props.score * props.multiplier;
    return props.score < 1 ? '#E33E43' : props.score < 2 ? '#F08601' : props.score < 3 ? '#F0E507' : props.score < 4 ? '#81C23E' : '#2A7F2A';
  }

  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['score-card'],
    score: {},
    id: '',
    svgID: '',

    willRender() {
      this.svgID = this.id + "-svg";
    },
    didInsertElement() {
      //let widget = d3.select(`#${this.id}`);
      let widget = Ember.$(`#${this.id}`);
      let svg = d3.select(`#${this.svgID}`);

      let easeColors = { 'red': '#E33E43', 'orange': '#F08601', 'yellow': '#F0E507', 'lime': '#81C23E', 'green': '#2A7F2A' };
      let emotionColors = { 'red': '#E33E43', 'orange': '#F08601', 'yellow': '#F0E507', 'lime': '#81C23E', 'green': '#2A7F2A' };
      let satisfactionColors = { 'red': '#E33E43', 'orange': '#F08601', 'yellow': '#F0E507', 'lime': '#81C23E', 'green': '#2A7F2A' };

      let scoreColors = emotionColors;
      let baseColor = setColorDefinition({ score: this.score.score, multiplier: 1 });
      let easeColor = setColorDefinition({ score: this.score.ease });
      let satisfactionColor = setColorDefinition({ score: this.score.howsatisfied });
      let emotionColor = setColorDefinition({ score: this.score.emotioncompleting });
      let scoreColor = setColorDefinition({ score: this.score.score, multiplier: 1 });

      widget.find(".chart-legend .ease-legend-item .indicator").css("background", easeColor);
      widget.find(".chart-legend .satisfaction-legend-item .indicator").css("background", satisfactionColor);
      widget.find(".chart-legend .emotion-legend-item .indicator").css("background", emotionColor);

      let scoreWidth = widget.find('.score').width();
      let scoreHeight = widget.find('.score').height();
      let scoreCircleX = scoreWidth / 2;
      let scoreCircleY = scoreHeight / 2 + 10;
      let scoreCircleRadius = 40;

      // Score inner circles in mapped color
      svg.append("circle").attr("cx", scoreCircleX).attr("cy", scoreCircleY).attr("r", scoreCircleRadius).attr("class", "breakdown-bar-score-inner").attr("fill", scoreColor);

      // Score outer gray circles
      svg.append("circle").attr("cx", scoreCircleX).attr("cy", scoreCircleY).attr("r", scoreCircleRadius + 6).attr("class", "breakdown-bar-score-outer").attr("stroke", "#EFEFEF").attr("stroke-width", 5).attr("fill", "none");

      // Score values inside circles
      svg.append("text").attr("x", scoreCircleX).attr("y", scoreCircleY + 8).attr("text-anchor", "middle").attr("class", "breakdown-bar-score-value").text(this.score.score / 2);
    }
  });
});