define('yxt-apps/components/password-confirm-validator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'span',
        classNames: ['addon'],
        classNameBindings: ['isPasswordMatched:ok'],
        password: '',
        confirmPassword: '',

        isPasswordMatched: Ember.computed('password', 'confirmPassword', function () {
            let pwd = this.get('password');
            let pwdConfirm = this.get('confirmPassword');
            if (pwd && pwdConfirm) {
                if (pwd.length > 5 && pwdConfirm.length > 5) {
                    if (pwd === pwdConfirm) {
                        return true;
                    }
                }
            }
        })
    });
});