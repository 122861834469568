define('yxt-apps/helpers/filter-count', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.filterCount = filterCount;
    function filterCount(params /*, hash*/) {
        let [obj, filter, value] = params;
        let filteredObject = obj.filterBy(filter, value);

        return filteredObject.length;
    }

    exports.default = Ember.Helper.helper(filterCount);
});