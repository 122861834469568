define('yxt-apps/routes/journal/sign-up', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let self;
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model() {
      self = this;
      let newUser, newProfile;
      // create a new profile
      newProfile = this.get('store').createRecord('registration-profile', {
        signup: newUser,
        address: {}
      });

      // create a new user
      newUser = this.get('store').createRecord('signup', {
        profile: newProfile
      });

      return newUser;
    },
    actions: {
      authenticate() {
        let { username, password } = this.getProperties('username', 'password');

        this.get('session').authenticate('authenticator:custom', username, password).catch(reason => {
          self.get('session').set('errorMessage', reason.message || reason);
        });
      },
      registerUser() {
        let self = this;

        function transitionToLogin() {
          self.transitionTo('journal.login');
        }

        function waitForAccountActivation() {
          // store some of this user data in the localStorage
          let newUser = self.controller.get('model');
          self.get('me').saveUsername(newUser.get('username'));
          self.get('me').saveFirstName(newUser.get('profile.firstName'));
          self.get('me').saveLastName(newUser.get('profile.lastName'));

          self.transitionTo('journal.pending-activation');
        }

        let newUser = this.controller.get('model');
        let email = newUser.get('email');
        self.get('me').saveEmail(email);
        newUser.set('username', email);
        if (newUser.get('organization') == null || !newUser.get('organization')) {
          newUser.set('organization', email);
          self.get('state').set('isError', false);
          self.get('state').set('errorMessage', '');
        }
        newUser.save().then(transitionToLogin).catch(reason => {
          self.get('session').set('errorMessage', reason || reason);
          self.get('state').set('isError', true);
          if (reason) {
            if (reason.errors) {
              if (reason.errors[0].detail.detail == 'DuplicatedItemIdError') {
                self.get('state').set('errorMessage', 'This account already exists.  Login or create a new account.');
              } else {
                self.get('state').set('errorMessage', reason.errors[0].detail.detail);
              }
            } else {
              self.get('state').set('errorMessage', reason);
              waitForAccountActivation(reason);
            }
          } else {
            transitionToLogin();
            self.get('state').set('errorMessage', '');
          }
        });
      },
      cancelSignup(user) {
        // reset form.
        user.rollbackAttributes();

        // go back to previous route.
        history.back();
      }
    }
  });
});