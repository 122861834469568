define('yxt-apps/components/report-card/participant-task-results', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['card'],
    currentTask: 0,
    actions: {
      viewTask(num) {
        this.set('currentTask', num);
      }
    }
  });
});