define('yxt-apps/serializers/question-choice', ['exports', 'yxt-apps/serializers/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        attrs: {
            metaparent: { serialize: false }
        }
    });
});