define('yxt-apps/serializers/study-website-task', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            modifiedAt: {
                serialize: false
            },
            createdAt: {
                serialize: false
            },
            pages: {
                embedded: 'always'
            },
            url: {
                serialize: true
            },
            urlProtocol: {
                serialize: false
            },
            urlPath: {
                serialize: false
            }
        },
        modelNameFromPayloadKey(payloadKey) {
            if (payloadKey === 'data') {
                let modelName = 'study-website-task';
                return this._super(payloadKey.replace('data', modelName));
            } else {
                return this._super(payloadKey);
            }
        },

        normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            if (!payload.data) {
                payload = {
                    data: payload
                };
            }
            if (payload.data) {
                payload.data.forEach(function (val) {
                    val.type = 'study-website-task';

                    let studyWebsiteTaskId = val.id;
                    if (val.url) {
                        let studyURL = new URL(val.url);

                        val.urlProtocol = `${studyURL.protocol}//`;
                        val.urlPath = `${val.url.toString().replace(val.urlProtocol, '')}`;
                    }
                    if (val.pages) {
                        if (val.pages.length > 0) {
                            val.pages.forEach(function (page, key) {

                                if (typeof page == 'object') {
                                    if (!page.id) {
                                        page.id = `${studyWebsiteTaskId}_wtpg_${key}`;
                                    }
                                    if (!page.type) {
                                        page.type = 'page-template';
                                    }

                                    if (page.content) {
                                        page.content.forEach(function (content, key) {

                                            if (content.metadata) {
                                                content.metadata.type = "content-meta";

                                                if (content.metadata.body) {
                                                    content.metadata.body.forEach(function (choice, key) {
                                                        if (!choice.type) {
                                                            choice.type = 'question-choice';
                                                        }
                                                    });
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
            }

            if (requestType == 'updateRecord') {
                payload = payload.data[0];
            }
            if (requestType == 'saveRecord') {
                payload = payload.data[0];
            }
            if (requestType == 'save') {
                payload = payload.data[0];
            }
            if (requestType == 'createRecord') {
                payload = payload.data[0];
            }
            return this._super(store, primaryModelClass, payload, id, requestType);
        }

    });
});