define('yxt-apps/adapters/study', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),
    authorize(xhr) {
      let { token } = this.get('session.data.authenticated');
      if (Ember.isPresent(token)) {
        xhr.setRequestHeader('Authorization', `Cactus token=${token}`);
      }
    },
    host: function () {
      return `${this.get('config.host')}/${this.get('config.namespace')}/organization/${window.localStorage.getItem('organizationId')}`;
    }.property().volatile(),
    pathForType: function () {
      // hardcode
      return 'studies';
    },
    updateRecord(store, type, snapshot) {
      // we need to manipulate the data before sending to the server.
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      const url = `${this.get('config.host')}/${this.get('config.namespace')}/organization/${window.localStorage.getItem('organizationId')}/studies/${data.id}`;
      if (data.features) {
        data.features.forEach(function (val, index, object) {
          if (val.enabled === false) {
            object.splice(index, 1);
          }
        });

        data.features.forEach(function (val) {
          delete val.id;
          delete val.study;
          delete val.enabled;
        });
      }

      if (data.instructions) {
        data.instructions = JSON.stringify(data.instructions);
      }
      if (data.participantData) {
        if (!data.participantData.quantity) {
          data.participantData.quantity = 1;
        } else if (data.participantData.quantity) {
          if (data.participantData.quantity == undefined) {
            data.participantData.quantity = 1;
          }
        }
      }
      if (data.workerData) {
        if (data.workerData.id) {
          delete data.workerData.id;
        }
        if (data.workerData.requirements) {
          data.workerData.requirements.forEach(function (val) {
            if (val.id) {
              delete val.id;
            }
          });
        }
      }
      return this.ajax(url, "PUT", { data: data });
    },
    createRecord(store, type, snapshot) {
      // we need to manipulate the data before sending to the server.
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      const url = `${this.get('config.host')}/${this.get('config.namespace')}/organization/${window.localStorage.getItem('organizationId')}/studies`;
      if (!data.project) {
        data.project = this.get('me.primaryProject');
      }
      if (data.id) {
        delete data.id;
      }
      if (data.instructions == null || data.instructions == '') {
        delete data.instructions;
      }
      if (data.instructions) {
        data.instructions = JSON.stringify(data.instructions);
      }
      if (data.template) {
        // 2020-09-09 [TAD] commenting out this case
        // so that we can allow the user to opt in/out of these features (yxscore and voiceprompts)
        //
        //
        /*if(data.template == "59c94b36d34d6d0a480896c8"){
          data.features = [{"template": "59c94b36d34d6d0a480896c4","name": "youxscore"},{"template": "59c94b36d34d6d0a480896c5","name": "voiceprompts"}];
        }
        else */
        //
        //
        //
        //


        if (data.template == "59c94b36d34d6d0a480896ca") {
          data.features = [{ "template": "59c94b36d34d6d0a480896cb", "name": "emotions" }];
        }
      }
      if (data.features) {
        data.features.forEach(function (val) {
          delete val.id;
          delete val.study;
          delete val.enabled;
        });
      }

      return this.ajax(url, "POST", { data: data });
    }
  });
});