define('yxt-apps/adapters/study-website-task', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'yxt-apps/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),
    authorize(xhr) {
      let { token } = this.get('session.data.authenticated');
      if (Ember.isPresent(token)) {
        xhr.setRequestHeader('Authorization', `Cactus token=${token}`);
      }
    },
    host: `${_environment.default.host}/${_environment.default.namespace}/organization/${window.localStorage.getItem('organizationId')}`,
    pathForType: function () {
      // hardcode
      return 'studyWebsiteTasks';
    },
    createRecord(store, type, snapshot) {
      // we need to manipulate the data before sending to the server.
      // server needs this wrapped in [] as if it's multiple records.
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      const apiurl = `${_environment.default.host}/${_environment.default.namespace}/organization/${window.localStorage.getItem('organizationId')}/studyWebsiteTasks/save`;

      data.pages.forEach(function (page) {

        page.content.forEach(function (content, index) {
          if (content.id) {
            delete content.id;
          }
          if (content.metadata) {
            if (content.metadata.id) {
              delete content.metadata.id;
            }
            delete content.metadata.size;

            if (content.contentType == "MultiChoice") {
              content.metadata.body.forEach(function (body) {
                delete body.size;
                if (body.id) {
                  delete body.id;
                }
              });
            }
            if (content.contentType == "TextEntry") {
              if (content.metadata.body) {
                delete content.metadata.body;
              }
            }
            if (content.value === "DELETED") {
              page.content.splice(index, 1);
            }
            if (content.contentType == "TextEntry" || content.contentType == "Instruction" || content.contentType == "Scale") {
              if (content.metadata.question == null || content.metadata.question == "" || content.metadata.question == undefined) {
                page.isDeleted = true;
              }
            }
          }
        });
      });

      let filteredPages = data.pages.filter(page => !page.isDeleted);
      data.pages = filteredPages;
      return this.ajax(apiurl, "POST", { data: [data] });
    },
    updateRecord(store, type, snapshot) {
      // we need to manipulate the data before sending to the server.
      // server needs this wrapped in [] as if it's multiple records.
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      const apiurl = `${_environment.default.host}/${_environment.default.namespace}/organization/${window.localStorage.getItem('organizationId')}/studyWebsiteTasks/save`;

      data.pages.forEach(function (page) {
        page.content.forEach(function (content, index) {
          if (content.id) {
            delete content.id;
          }
          if (content.metadata) {
            if (content.metadata.id) {
              delete content.metadata.id;
            }
            delete content.metadata.size;

            if (content.contentType == "MultiChoice") {
              content.metadata.body.forEach(function (body) {
                delete body.size;
                if (body.id) {
                  delete body.id;
                }
              });
            }
            if (content.contentType == "TextEntry") {
              if (content.metadata.body) {
                delete content.metadata.body;
              }
            }
            if (content.value === "DELETED") {
              page.content.splice(index, 1);
            }
            if (content.contentType == "TextEntry" || content.contentType == "Instruction" || content.contentType == "Scale") {
              if (content.metadata.question == null || content.metadata.question == "" || content.metadata.question == undefined) {
                page.isDeleted = true;
              }
            }
          }
        });
      });
      let filteredPages = data.pages.filter(page => !page.isDeleted);
      data.pages = filteredPages;
      return this.ajax(apiurl, "POST", { data: [data] });
    }
  });
});