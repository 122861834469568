define('yxt-apps/adapters/signup', ['exports', 'ember-data', 'yxt-apps/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const {
        errorsHashToArray
    } = _emberData.default;

    exports.default = _emberData.default.RESTAdapter.extend({
        host: `${_environment.default.host}/${_environment.default.namespace}`, // no organization
        pathForType: function (type) {
            // don't pluralize
            var camelized = Ember.String.camelize(type);
            return camelized;
        },
        handleResponse: function (status, headers, payload, requestData) {
            if (status === 400 && payload) {
                let errors = new Array();
                let errorsPayload = {
                    "detail": payload.name,
                    "status": status,
                    "title": payload.title,
                    "code": payload.technicalMessage
                };

                payload.errors = new Array();
                payload.errors.push(errorsPayload);
                errors.push(errorsPayload);
                delete payload.name;
                delete payload.technicalMessage;

                payload.errors = errorsHashToArray(payload.errors);
                payload = {
                    payload
                };
            }
            return this._super(...arguments);
        }
    });
});