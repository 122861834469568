define('yxt-apps/routes/admin/organizations/organization/studies/study/mail-templates/mail-template', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.name', function () {
            const mailTemplateName = this.get('controller.model.name') || 'Mail Template';

            const mTemplate = {
                title: mailTemplateName
            };

            return mTemplate;
        }),
        model(params) {
            return this.get('store').findRecord('mail-template', params.mailTemplate_id);
        },
        beforeModel() {
            this._super(...arguments);
            if (typeof tinymce == 'undefined') {
                return Ember.$.getScript('//cdn.tiny.cloud/1/5mcmvyu2zalcpwn06bpu3zv1ilgjp5jryo938qv7h84ijl22/tinymce/5/tinymce.min.js');
            }
        }
    });
});