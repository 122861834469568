define('yxt-apps/models/study-feature', ['exports', 'ember-data', 'ember-data-model-fragments'], function (exports, _emberData, _emberDataModelFragments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    name: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean', { defaultValue: true }),
    results: _emberData.default.attr(),
    template: _emberData.default.attr(),
    study: _emberData.default.belongsTo('study'),
    studyTemplate: _emberData.default.belongsTo('study-template')
  });
});