define('yxt-apps/models/permission', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        view: _emberData.default.attr(),
        edit: _emberData.default.attr(),
        delete: _emberData.default.attr()
    });
});