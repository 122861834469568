define('yxt-apps/routes/admin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        beforeModel() {
            this.set('headData.title', 'YouXTools Admin');
            this.get('me').saveAppName('admin');
            Ember.$('body').attr('appName', 'admin');
        }
    });
});