define('yxt-apps/routes/admin/organizations/organization/members/create', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model(params) {
            let orgModel = this.modelFor('admin.organizations.organization');
            let currentOrg = orgModel.get('id');
            let memberUser = this.get('store').createRecord('user', {
                profile: {
                    firstName: null,
                    lastName: null
                }
            });
            let member = this.get('store').createRecord('organization-member', {
                organization: orgModel,
                user: memberUser
            });
            return Ember.RSVP.hash({
                orgMember: member,
                user: memberUser
            });
        },
        actions: {
            updateMember(orgMember) {
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }
                function notifyMemberSuccess(orgMember) {
                    self.toast.success(`Member (${orgMember.get('user.username')}) updated succesfully`);
                }
                orgMember.save().then(notifyMemberSuccess).catch(failure);
            },
            saveUser(memberUser) {
                let curMember = this.get('currentModel.orgMember');
                let curUser = this.get('currentModel.user');
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }
                function notifyUserSuccess(memberUser) {
                    self.toast.success(`User (${memberUser.get('username')}) updated succesfully`);
                }
                curUser.save().then(function (user) {
                    curMember.set('user', user);
                    curMember.save().then(notifyUserSuccess).catch(failure);
                });
            },
            cancelChanges(orgMember) {
                orgMember.rollbackAttributes();
            }
        }
    });
});