define('yxt-apps/helpers/preview-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.previewUrl = previewUrl;
  function previewUrl(params) {
    let [domain, studyId, lang, studyType] = params;
    let orgId = window.localStorage.getItem('organizationId');
    studyType = studyType.toLowerCase();
    lang = lang.toLowerCase();
    if (!lang || lang == null) {
      lang = 'en';
    }
    if (studyType === 'journal') {
      studyType = 'discovery';
    }
    return `${domain}/${studyType}/?oid=${orgId}&lang=${lang}&sid=${studyId}`;
  }

  exports.default = Ember.Helper.helper(previewUrl);
});