define('yxt-apps/components/title-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['title-bar'],
    session: Ember.inject.service('session')
  });
});