define('yxt-apps/components/org-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            cancelChanges(org) {
                this.get('cancelChanges')(org);
            },
            updateOrg(org) {
                this.get('updateOrg')(org);
            }
        }
    });
});