define('yxt-apps/adapters/study-participant-template', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),
    authorize(xhr) {
      let { token } = this.get('session.data.authenticated');
      if (Ember.isPresent(token)) {
        xhr.setRequestHeader('Authorization', `Cactus token=${token}`);
      }
    },
    host: function () {
      return `${this.get('config.host')}/${this.get('config.namespace')}`;
    },
    pathForType: function () {
      return 'studyParticipantTemplates';
    },
    urlForFindAll() {
      return `${this.get('config.host')}/${this.get('config.namespace')}/studyParticipantTemplates`;
    },
    urlForFindRecord(id) {
      return `${this.get('config.host')}/${this.get('config.namespace')}/studyParticipantTemplates/${id}`;
    }
  });
});