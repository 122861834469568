define('yxt-apps/components/preview-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['preview-link'],
    targetModal: '',

    actions: {}
  });
});