define('yxt-apps/serializers/signup', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    serializeAttribute(snapshot, json, key) {
      if (snapshot.attr(key) != null && snapshot.attr(key) != '') {
        this._super(...arguments);
      }
    },
    attrs: {
      passwordrepeat: { serialize: false },
      username: { serialize: true },
      organization: { serialize: true },
      email: { serialize: true },
      profile: { embedded: 'always' },
      passwordConfirm: { serialize: false }
    },
    modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'data') {
        let modelName = 'signup';
        return this._super(payloadKey.replace('data', modelName));
      } else {
        return this._super(payloadKey);
      }
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data) {
        payload.data.forEach(function (val) {
          if (val.user) {
            val.id = val.user;
            id = val.user;
          }
        });
        payload = payload.data;
      } else {
        if (payload.user) {
          payload.id = payload.user;
          id = payload.user;
        }
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});