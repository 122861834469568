define('yxt-apps/routes/admin/users/user', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model(params) {
            return this.get('store').findRecord('user', params.user_id);
        },
        breadCrumb: Ember.computed('controller.model.name', function () {
            const dynTitle = {
                title: this.get('controller.model.username') || 'User'
            };
            return dynTitle;
        }),
        actions: {
            updateUser(user) {
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }
                function notifyUserSuccess(user) {
                    self.toast.success(`User (${user.get('username')}) updated succesfully`);
                }
                let _user = this.get('store').peekRecord('user', user.get('id'));
                _user.save().then(notifyUserSuccess).catch(failure);
            },
            cancelChanges(user) {
                user.rollbackAttributes();
            }
        }
    });
});