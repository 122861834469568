define('yxt-apps/routes/journal/studies/study/create/launch', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = {
                title: `${pageName} - Launch`
            };
            return breadCrumbTitle;
        }),
        beforeModel() {
            this.get('state').set('studyCreationStep', 'launch');
            this._super(...arguments);
            if (typeof tinymce == 'undefined') {
                return Ember.$.getScript('//cdn.tiny.cloud/1/5mcmvyu2zalcpwn06bpu3zv1ilgjp5jryo938qv7h84ijl22/tinymce/5/tinymce.min.js');
            }
        },
        afterModel: function (transition) {
            let parentModel = this.modelFor('journal.studies.study');
            let study = parentModel.study;
            let invitationMailTemplate = parentModel.invitationMailTemplate;

            if (study.get('status') === 'incomplete') {
                study.submit().then(() => {
                    study.set('status', 'ready');
                }); // mark it as ready
            }
            if (invitationMailTemplate.get('body') == '<p class="body-text">You have been invited to participate in a remote usability session!</p><br><p class="body-text">You will be asked to complete a series of tasks and will give feedback on them for about 15 minutes.</p><br><p class="body-text">The link below will take you to the study page. Watch the short video before you begin. If you do not already have the required browser and screen recorder, you will be guided through 3 quick and secure steps before the actual recording begins. When asked for permission to record your screen, make sure you select the browser “YouXtools-Pick this browser to record”. At the end of the session, you may be asked to complete a few post-session questions while your video is uploading. Make sure not to navigate away from the study until you have finished.</p><br><p class="body-text">Give your best effort and make sure to say all of your feedback out loud during testing, your input matters!</p><br><p class="body-text">Alright, it’s time to start your study! Click the link below to get started.</p><br><p class="body-text"><a clicktracking="off" href="{{invitation_link}}">Get started</a></p>') {

                invitationMailTemplate.set('body', `<style>.body-text{margin-bottom: 20px;} h2,p{overflow-wrap: normal;}</style><h2 class="body-text">You have been invited to participate in a Journal Study.</h2><p class="body-text">You will be asked to complete 1 entry each day for the next ${study.get('numberOfDays')} days.  <em>Entries are moments you document over time about a particular subject or activity.</em></p><p class="body-text">Every day, select the link below to open your journal and complete your entries.</p><div class="body-text" style="text-align: center;"><a style="color: #ffffff; background-color: #2CB1D0; padding: 20px 30px; font-size: 18px; width=100%; display: block; text-decoration: none; font-weight: bold; margin-bottom: 45px;" href="{{invitation_link}}">My Journal</a></div><div><br><br><br><p class="body-text">If you have any questions, please contact <a href="mailto:${this.get('me.email')}">${this.get('me.email')}</a></p><br><br><br></div>`);
            }

            if (invitationMailTemplate.get('subject') == 'Participate in our Study!') {
                invitationMailTemplate.set('subject', `Participate in our study (${study.get('title')})`);
            }

            Ember.$('body > div.ember-view').animate({
                scrollTop: 0
            }, 500);
            this._super(transition);
        },
        actions: {
            launchNow() {

                // send request to backend api.
                let study = this.controller.get('model.study');
                let invitationMailTemplate = this.controller.get('model.invitationMailTemplate');

                // if no link exists in their email
                if (!invitationMailTemplate.get('body').includes('{{invitation_link}}')) {
                    let tempBody = invitationMailTemplate.get('body');
                    let invitationLinkHtml = '<p></p><div class="body-text" style="text-align: center;"><a style="color: #ffffff; background: #2CB1D0; background-color: #2CB1D0; padding: 20px 30px; font-size: 18px; width=100%; display: block; text-decoration: none; font-weight: bold; margin-bottom: 45px;" href="{{invitation_link}}">Get started</a></div>';
                    let newBody = `<style>.body-text,p{margin-bottom: 20px!important;} h2,p{overflow-wrap: normal;}</style>${tempBody}${invitationLinkHtml}`;
                    invitationMailTemplate.set('body', newBody);
                }

                let self = this;

                function failure(reason) {
                    self.toast.error(reason);
                }

                function updateStartDate() {
                    let now = new Date().toISOString();
                    study.set('startDate', now);
                    study.save().then(launchStudy).catch(failure);
                }

                function launchStudy() {
                    study.processpayment().then(() => {
                        study.launch().then(() => {
                            study.set('status', 'Active');
                            self.transitionTo('journal.studies.study.overview');
                        });
                    });
                }

                function saveMailTemplates() {
                    invitationMailTemplate.save().then(updateStartDate).catch(failure);
                }
                saveMailTemplates();
            }
        }
    });
});