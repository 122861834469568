define('yxt-apps/routes/reaction/studies/study/create/setup/tasks', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  let self = undefined;
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    breadCrumb: Ember.computed('controller.model.study.title', function () {
      const pageName = this.get('controller.model.study.title') || 'Study';
      const breadCrumbTitle = { title: `${pageName} - Add Tasks` };
      return breadCrumbTitle;
    }),
    model() {
      self = this;
      this.get('state').set('studyCreationStep', 'tasks');
      let studyModel = this.modelFor('reaction.studies.study');
      let study = studyModel.study;

      let studyWebsiteTasks = this.get('store').query('study-website-task', { studyId: study.id }).then(function (studyWebsiteTasks) {
        studyWebsiteTasks.forEach(function (studyWebsiteTask) {
          let pages = studyWebsiteTask.get('pages');

          let ranArray = new Uint32Array(1);
          window.crypto.getRandomValues(ranArray);
          let uId = `${moment()}_${ranArray[0]}`;
          let newtaskName = `Task_${uId}`;

          if (!pages || pages.get('length') < 1) {
            // if there are no tasks, lets create one now.
            let newPage = self.get('store').createRecord('page-template', {
              name: 'Task_1',
              title: newtaskName,
              isEditing: true,
              studyWebsiteTask: studyWebsiteTask
            });
            let newPageContent = newPage.get('content');
            newPageContent.createFragment({
              name: newtaskName,
              contentType: 'Instruction',
              metadata: {
                isMandatory: true,
                question: ''
              }
            });
          } else {
            let pageContent = pages.get('firstObject').get('content');
            if (pageContent.get('length') == 0) {

              let ranArray = new Uint32Array(1);
              window.crypto.getRandomValues(ranArray);
              let uId = `${moment()}_${ranArray[0]}`;
              let newtaskName2 = `Task_${uId}`;

              let pg = pages.get('firstObject');
              pg.set('name', newtaskName2);
              pg.set('title', newtaskName2);
              pg.set('isEditing', true);
              pg.set('studyWebsiteTask', studyWebsiteTask);

              let newPageContent = pg.get('content');
              newPageContent.createFragment({
                name: newtaskName2,
                contentType: 'Instruction',
                metadata: {
                  isMandatory: true,
                  question: ''
                }
              });
            }
          }
        });
        return studyWebsiteTasks;
      });

      return Ember.RSVP.hash({
        study: study,
        studyWebsiteTasks: studyWebsiteTasks
      });
    },
    afterModel: function (transition) {
      Ember.$('body > div.ember-view').animate({ scrollTop: 0 }, 500);
      this._super(transition);
    },
    actions: {
      willTransition() {
        let self = this;
        function cleanup() {
          let allPages = self.get('store').peekAll('page-template');
          allPages.toArray().forEach(function (page) {
            if (page.get('id')) {
              //page.unloadRecord();
            } else {
              page.unloadRecord();
            }
          });
        }
        let studyWebsiteTasks = this.controller.get('model.studyWebsiteTasks');
        studyWebsiteTasks.save().then(cleanup);
      }
    }
  });
});