define('yxt-apps/components/user-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        roleOptions: '',
        willInsertElement() {
            this.set('roleOptions', this.get('store').findAll('role'));
        },
        actions: {
            cancelChanges(user) {
                this.get('cancelChanges')(user);
            },
            updateUser(user) {
                this.get('updateUser')(user);
            },
            addTag(role) {
                this.get('addTag')(role);
            },
            removeTagAtIndex(role) {
                this.get('removeTagAtIndex')(role);
            }
        }
    });
});