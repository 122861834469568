define('yxt-apps/routes/admin/organizations/organization/projects', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    breadCrumb: Ember.computed('controller.model.name', function () {
      const projectsLength = this.get('controller.model.length') || 1;

      const studies = {
        title: `Projects (${projectsLength})`
      };

      return studies;
    }),
    beforeModel() {
      this.get('store').unloadAll('study');
    },
    model() {
      return this.get('store').findAll('project').then(function (project) {
        return project.sortBy('modifiedAt').reverse();
      });
    }
  });
});