define('yxt-apps/helpers/format-score', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatScore = formatScore;
  function formatScore(params /*, hash*/) {
    let [score, multiplier] = params;
    if (!multiplier) {
      multiplier = 1;
    }
    //FF: don't know why but this function was receiving numbers that were strings, this way we make sure the function handles this cases
    if (score != undefined && score != null) {
      score = Number(score) * multiplier;
      return score.toFixed(1);
    } else return score;
  }

  exports.default = Ember.Helper.helper(formatScore);
});