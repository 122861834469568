define('yxt-apps/routes/admin/organizations/organization/members/member', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model(params) {
            let member = this.get('store').findRecord('organization-member', params.member_id);
            return member;
        },
        actions: {
            updateMember(orgMember) {
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }
                function notifyMemberSuccess(orgMember) {
                    self.toast.success(`Member (${orgMember.get('user.username')}) updated succesfully`);
                }
                orgMember.save().then(notifyMemberSuccess).catch(failure);
            },
            updateUser(memberUser) {
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }
                function notifyUserSuccess(memberUser) {
                    self.toast.success(`User (${memberUser.get('username')}) updated succesfully`);
                }
                let user = this.get('store').peekRecord('user', memberUser.get('id'));
                user.save().then(notifyUserSuccess).catch(failure);
            },
            cancelChanges(orgMember) {
                orgMember.rollbackAttributes();
            }
        }
    });
});