define('yxt-apps/routes/journal/studies/study/create/setup/questions', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    let self = undefined;
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = { title: `${pageName} - Add Questions` };
            return breadCrumbTitle;
        }),
        model() {
            self = this;
            this.get('state').set('studyCreationStep', 'follow-up-questions');
            let studyModel = this.modelFor('journal.studies.study');
            let study = studyModel.study;
            let postSessionQuestions = this.get('store').query('study-post-session-question', {
                studyId: study.id
            }).then(function (postSessionQuestions) {
                if (postSessionQuestions.get('length') == 0) {

                    let newPostSessionQuestion = self.get('store').createRecord('study-post-session-question', {
                        name: 'question_1',
                        study: study
                    });
                    return [newPostSessionQuestion];
                }
                return postSessionQuestions;
            });

            return Ember.RSVP.hash({
                study: study,
                studyPostSessionQuestions: postSessionQuestions
            });
        },
        afterModel: function (transition) {
            Ember.$('body > div.ember-view').animate({
                scrollTop: 0
            }, 500);
            this._super(transition);
        },
        actions: {
            definePostSessionQuestions() {
                self.transitionTo('journal.studies.study.create.participants');
            },
            willTransition() {

                function cleanup() {
                    let allPages = self.get('store').peekAll('page-template');
                    allPages.toArray().forEach(function (page) {
                        if (page.get('id')) {
                            // do nothing
                        } else {
                            page.unloadRecord();
                        }
                    });
                    let allContent = self.get('store').peekAll('content');
                    allContent.toArray().forEach(function (content) {
                        if (content.get('id')) {
                            // do nothing
                        } else {
                            content.unloadRecord();
                        }
                    });
                    let allContentMeta = self.get('store').peekAll('content-meta');
                    allContentMeta.toArray().forEach(function (meta) {
                        if (meta.get('id')) {
                            // do nothing
                        } else {
                            meta.unloadRecord();
                        }
                    });
                    let allChoices = self.get('store').peekAll('question-choice');
                    allChoices.toArray().forEach(function (choice) {
                        if (choice.get('id')) {
                            // do nothing
                        } else {
                            choice.unloadRecord();
                        }
                    });
                }

                let studyPostSessionQuestions = this.controller.get('model.studyPostSessionQuestions');
                studyPostSessionQuestions.forEach(function (ps) {
                    if (ps.get('pages.length') > 0) {
                        ps.save().then(cleanup);
                    }
                });
            }
        }
    });
});