define('yxt-apps/authenticators/admin', ['exports', 'ember-simple-auth/authenticators/base', 'yxt-apps/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const { RSVP, isEmpty, run } = Ember;

  exports.default = _base.default.extend({
    authenticate(identification, password, scope = []) {
      return new RSVP.Promise((resolve, reject) => {
        const data = JSON.stringify({ "username": identification, "password": password });
        const serverTokenEndpoint = `${_environment.default.apiHost}/auth`;
        const scopesString = Ember.makeArray(scope).join(' ');
        if (!Ember.isEmpty(scopesString)) {
          data.scope = scopesString;
        }
        this.makeRequest(serverTokenEndpoint, data).then(response => {
          run(() => {
            const expiresAt = this._absolutizeExpirationTime(response['expires']);
            this._scheduleAccessTokenRefresh(response['expires'], expiresAt, response['refresh_token']);
            if (!isEmpty(expiresAt)) {
              response = Ember.merge(response, { 'expires_at': expiresAt });
            }
            resolve(response);
            if (response['identity']['data']['organizations'].length > 0) {
              let usernameVal = response['identity']['data']['username'];
              let emailVal = response['identity']['data']['email'];
              let firstName = response['identity']['data']['profile']['firstName'];
              let lastName = response['identity']['data']['profile']['lastName'];
              let useridVal = response['identity']['data']['id'];
              let orgId = response['identity']['data']['organizations'][0];
              this.get('me').saveUsername(usernameVal);
              this.get('me').saveEmail(emailVal);
              this.get('me').saveFirstName(firstName);
              this.get('me').saveLastName(lastName);
              this.get('me').saveId(useridVal);
              this.get('me').saveOrganizationId(orgId);
            }
          });
        }, xhr => {
          run(null, reject, xhr.responseJSON || xhr.responseText);
        });
      });
    },

    restore(data) {
      return new RSVP.Promise(resolve => {
        resolve(data);
      });
    },

    invalidate(data) {
      const serverTokenRevocationEndpoint = this.get('serverTokenRevocationEndpoint');
      this.get('me').invalidateAll();
      function success(resolve) {
        run.cancel(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
        resolve();
      }
      return new RSVP.Promise(resolve => {
        if (isEmpty(serverTokenRevocationEndpoint)) {
          success.apply(this, [resolve]);
        } else {
          const requests = [];
          Ember.A(['access_token', 'refresh_token']).forEach(tokenType => {
            const token = data[tokenType];
            if (!isEmpty(token)) {
              requests.push(this.makeRequest(serverTokenRevocationEndpoint, {
                'token_type_hint': tokenType, token
              }));
            }
          });
          const succeed = () => {
            success.apply(this, [resolve]);
          };

          RSVP.all(requests).then(succeed, succeed);
        }
      });
    },

    makeRequest(url, data) {
      const options = {
        url,
        data,
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json'
      };
      const clientId = this.get('clientId');
      if (!isEmpty(clientId)) {
        const base64ClientId = window.btoa(clientId.concat(':'));
        Ember.merge(options, {
          headers: {
            Authorization: `Basic ${base64ClientId}`
          }
        });
      }

      return Ember.$.ajax(options);
    },

    _scheduleAccessTokenRefresh(expiresIn, expiresAt, refreshToken) {
      const refreshAccessTokens = this.get('refreshAccessTokens');
      if (refreshAccessTokens) {
        const now = new Date().getTime();
        if (isEmpty(expiresAt) && !isEmpty(expiresIn)) {
          expiresAt = new Date(now + expiresIn * 1000).getTime();
        }
        const offset = (Math.floor(Math.random() * 5) + 5) * 1000;
        if (!isEmpty(refreshToken) && !isEmpty(expiresAt) && expiresAt > now - offset) {
          run.cancel(this._refreshTokenTimeout);
          delete this._refreshTokenTimeout;
          if (!Ember.testing) {
            this._refreshTokenTimeout = run.later(this, this._refreshAccessToken, expiresIn, refreshToken, expiresAt - now - offset);
          }
        }
      }
    },

    _refreshAccessToken(expiresIn, refreshToken) {
      const data = { 'grant_type': 'refresh_token', 'refresh_token': refreshToken };
      const serverTokenEndpoint = this.get('serverTokenEndpoint');
      return new RSVP.Promise((resolve, reject) => {
        this.makeRequest(serverTokenEndpoint, data).then(response => {
          run(() => {
            expiresIn = response['expires'] || expiresIn;
            refreshToken = response['refresh_token'] || refreshToken;
            const expiresAt = this._absolutizeExpirationTime(expiresIn);
            const data = Ember.merge(response, { 'expires_in': expiresIn, 'expires_at': expiresAt, 'refresh_token': refreshToken });
            this._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);
            this.trigger('sessionDataUpdated', data);
            resolve(data);
          });
        }, (xhr, status, error) => {
          Ember.Logger.warn(`Access token could not be refreshed - server responded with ${error}.`);
          reject();
        });
      });
    },

    _absolutizeExpirationTime(expiresIn) {
      if (!isEmpty(expiresIn)) {
        return new Date(new Date().getTime() + expiresIn * 1000).getTime();
      }
    }

  });
});