define('yxt-apps/routes/ford/forgot-password', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'yxt-apps/routes/config/environment'], function (exports, _unauthenticatedRouteMixin, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    let self = undefined;

    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        isEmailSent: false,
        isError: false,
        errorMsg: '',
        breadCrumb: {
            title: 'To Reset Your Password'
        },
        model() {
            self = this;
            let currentUser = this.get('store').createRecord('user', {
                email: this.get('me.email')
            });

            return Ember.RSVP.hash({
                isEmailSent: this.get('isEmailSent'),
                isError: this.get('isError'),
                errorMsg: this.get('errorMessage'),
                user: currentUser
            });
        },

        actions: {
            forgotPassword(email) {
                self = this;
                Ember.$.ajax({
                    url: `${_environment.default.host}/${_environment.default.namespace}/user/password/forgotten/?username=${email}`,
                    method: 'POST',
                    data: {
                        username: email
                    }
                }).done(function () {
                    self.set('currentModel.isEmailSent', true);
                }).catch(function (response) {
                    self.set('currentModel.isEmailSent', false);
                    self.set('currentModel.isError', true);

                    if (response.responseJSON) {
                        self.set('currentModel.errorMsg', response.responseJSON.message);
                    } else {
                        self.set('currentModel.errorMsg', response);
                    }
                });
            },
            dismissMessage() {
                self.set('currentModel.errorMsg', null);
                self.set('currentModel.isError', false);
                self.set('currentModel.isEmailSent', false);
            }
        }
    });
});