define('yxt-apps/components/question-row', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'li',
        classNames: ['task-question', 'question-row'],
        choicesQty: 3,
        actions: {
            deleteQuestion(item, index) {
                item.set('value', 'DELETED');
            },
            setQuestionType(questionType) {
                this.set('content.contentType', questionType);
                let curChoices = this.get('content').get('metadata');
                let choicesQty = this.get('choicesQty');
                let curChoicesQty = curChoices.get('body.length') || 0;
                if (questionType == 'MultiChoice' || questionType == 'Scale') {
                    let curChoices = this.get('content').get('metadata');
                    if (questionType == 'MultiChoice') {
                        for (let i = curChoicesQty; i < choicesQty; i++) {
                            let choice = this.get('store').createRecord('question-choice', {
                                name: ''
                            });
                            curChoices.get('body').pushObject(choice);
                        }
                        // remove 'size' from any existing choices
                        curChoices.get('body').forEach(function (choice) {
                            choice.set('size', undefined);
                        });
                    } else if (questionType == 'Scale') {
                        for (let i = 0; i < curChoicesQty; i++) {
                            curChoices.get('body').popObject();
                        }
                        let choice = this.get('store').createRecord('question-choice', {
                            name: '',
                            size: 6
                        });
                        curChoices.get('body').pushObject(choice);
                    }
                } else if (questionType == 'TextEntry') {
                    //curChoices.set('body',null);
                    for (let i = 0; i < curChoicesQty; i++) {
                        curChoices.get('body').popObject();
                    }
                }
            },
            setChoiceQty(qty) {
                let curChoices = this.get('content').get('metadata');
                let curChoicesQty = curChoices.get('body.length');
                this.set('choicesQty', qty);
                if (qty > curChoicesQty) {
                    // then we'll add some choices
                    for (let i = curChoicesQty; i < qty; i++) {
                        let choice = this.get('store').createRecord('question-choice', {
                            name: ''
                        });
                        curChoices.get('body').pushObject(choice);
                    }
                } else if (qty < curChoicesQty) {
                    // how many do we need to pop?
                    let popOff = curChoicesQty - qty;
                    // then we'll add delete choices
                    for (let i = 0; i < popOff; i++) {
                        curChoices.get('body').popObject();
                    }
                }
            }
        }
    });
});