define('yxt-apps/serializers/user', ['exports', 'yxt-apps/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      tenantId: { serialize: false },
      passwordConfirm: { serialize: false },
      passwordUpdated: { serialize: false },
      organizationMember: { serialize: false },
      currency: { serialize: false },
      language: { serialize: false },
      modifiedAt: { serialize: false },
      createdAt: { serialize: false }
    },
    serializeAttribute(snapshot, json, key) {
      if (snapshot.attr(key) != null) {
        this._super(...arguments);
      }
    },
    modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'data') {
        let modelName = 'user';
        return this._super(payloadKey.replace('data', modelName));
      } else {
        return this._super(payloadKey);
      }
    }

  });
});