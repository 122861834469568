define('yxt-apps/routes/admin/study-templates', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return this.get('store').findAll('study-template').then(function (studyTempate) {
        return studyTempate.sortBy('name');
      });
    },
    actions: {}
  });
});