define('yxt-apps/serializers/study-entry', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data) {
        payload.data.forEach(function (val) {
          val.status = val.state;
          delete val.state;

          if (val.properties) {
            val.taskData = [];
            for (var i in val.properties) {
              val.taskData.unshift(val.properties[i]);
            }
          }
        });
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});