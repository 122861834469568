define('yxt-apps/helpers/days-remaining', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.daysRemaining = daysRemaining;
  function daysRemaining(params /*, hash*/) {
    return params;
  }

  exports.default = Ember.Helper.helper(daysRemaining);
});