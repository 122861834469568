define('yxt-apps/serializers/study', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {

        attrs: {
            tenantId: { serialize: false },
            owner: { serialize: false },
            modifiedAt: { serialize: false },
            createdAt: { serialize: false },
            isSelected: { serialize: false },
            studyTemplate: { serialize: false },
            participantType: { serialize: false },
            percentComplete: { serialize: false },
            studyWebsiteTasks: { serialize: false },
            studyJournalTasks: { serialize: false },
            participantResult: { serialize: false },
            participantData: { serialize: true },
            studyPostSessionQuestions: { serialize: false },
            workflowType: { serialize: false },
            autoPayParticipants: { serialize: false },
            autoPayParticipantsAmount: { serialize: false },
            autoPayParticipantsCurrency: { serialize: false },
            features: { embedded: 'always' }
            //project: { serialize: false },
        },
        serializeAttribute(snapshot, json, key, attributes) {
            if (key === 'participantData' || snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
                this._super(snapshot, json, key, attributes);
            }
        },
        modelNameFromPayloadKey(payloadKey) {
            if (payloadKey === 'data') {
                let modelName = 'study';
                return this._super(payloadKey.replace('data', modelName));
            } else {
                return this._super(payloadKey);
            }
        },

        normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            if (payload.data) {
                payload.data.forEach(function (val) {
                    let studyId = val.id;
                    if (val.status) {
                        val.status = val.status.toLowerCase();
                    }
                    if (val.state) {
                        val.status = val.state.toLowerCase();
                    }
                    if (!val.type) {
                        val.type = 'study';
                    }
                    if (val.template) {
                        val.studyTemplate = val.template;
                    }
                    if (val.instructions) {
                        try {
                            val.instructions = JSON.parse(val.instructions);
                        } catch (e) {
                            val.instructions = { instructions: val.instructions };
                        }
                    }
                    if (val.percentComplete) {
                        val.percentComplete = Math.floor(val.percentComplete * 100);
                        if (val.percentComplete > 100) {
                            val.percentComplete = 100;
                        }
                    } else {
                        val.percentComplete = 0;
                    }
                    if (val.workerData) {
                        if (val.workerData.hitId) {
                            val.workerData.id = val.workerData.hitId;
                        } else {
                            val.workerData.id = `${studyId}_wdata`;
                        }
                        val.workerData.type = 'study-worker-data';

                        //val.numberOfDays = val.numberOfDays/7;
                        if (val.workerData.requirements) {
                            val.workerData.requirements.forEach(function (requirement) {
                                if (!requirement.id) {
                                    requirement.id = `${studyId}_wdata_requirement_${requirement.key}`;
                                }
                                if (!requirement.type) {
                                    requirement.type = 'mturk-requirement';
                                }
                                if (!requirement.workerData) {
                                    requirement.workerData = val.workerData.id;
                                }
                            });
                        }
                    }
                    if (val.features) {
                        val.features.forEach(function (feature) {
                            if (!feature.id) {
                                feature.id = `${studyId}_ft_${feature.template}`;
                            }
                            if (!feature.study) {
                                feature.study = studyId;
                            }
                            if (!feature.type) {
                                feature.type = 'study-feature';
                            }
                            if (feature.name) {
                                if (feature.name === 'uegscore') {
                                    feature.name = 'youxscore';
                                }
                            }
                        });
                    }
                });
                payload = payload.data;
            } else {
                //payload.numberOfDays = payload.numberOfDays/7;

                let studyId = payload.id;
                if (payload.status) {
                    payload.status = payload.status.toLowerCase();
                }
                if (payload.state) {
                    payload.status = payload.state.toLowerCase();
                }
                if (!payload.type) {
                    payload.type = 'study';
                }
                if (payload.template) {
                    payload.studyTemplate = payload.template;
                }
                if (payload.instructions) {
                    try {
                        payload.instructions = JSON.parse(payload.instructions);
                    } catch (e) {
                        payload.instructions = { instructions: payload.instructions };
                    }
                }
                if (payload.percentComplete) {
                    payload.percentComplete = Math.floor(payload.percentComplete * 100);
                    if (payload.percentComplete > 100) {
                        payload.percentComplete = 100;
                    }
                } else {
                    payload.percentComplete = 0;
                }
                if (payload.workerData) {
                    if (payload.workerData.hitId) {
                        payload.workerData.id = payload.workerData.hitId;
                    } else {
                        payload.workerData.id = `${studyId}_wdata`;
                    }
                    payload.workerData.type = 'study-worker-data';

                    if (payload.workerData.requirements) {
                        payload.workerData.requirements.forEach(function (requirement) {
                            if (!requirement.id) {
                                requirement.id = `${studyId}_wdata_requirement_${requirement.key}`;
                            }
                            if (!requirement.type) {
                                requirement.type = 'mturk-requirement';
                            }
                            if (!requirement.workerData) {
                                requirement.workerData = payload.workerData.id;
                            }
                        });
                    }
                }
                if (payload.features) {
                    payload.features.forEach(function (feature) {
                        if (!feature.id) {
                            feature.id = `${studyId}_ft_${feature.template}`;
                        }
                        if (!feature.study) {
                            feature.study = studyId;
                        }
                        if (!feature.type) {
                            feature.type = 'study-feature';
                        }
                        if (feature.name) {
                            if (feature.name === 'uegscore') {
                                feature.name = 'youxscore';
                            }
                        }
                    });
                }
            }
            return this._super(store, primaryModelClass, payload, id, requestType);
        }

    });
});