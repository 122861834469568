define('yxt-apps/routes/reaction/account/change-password', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        breadCrumb: {
            title: 'Change Password'
        }
    });
});