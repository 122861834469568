define('yxt-apps/utils/required-method', ['exports', 'ember-cli-adapter-pattern/utils/required-method'], function (exports, _requiredMethod) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _requiredMethod.default;
    }
  });
});