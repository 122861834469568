define('yxt-apps/helpers/increment-alpha', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.incrementAlpha = incrementAlpha;
  function incrementAlpha(params) {
    let [value, startingValue] = params;
    let incVal = value * 1 + startingValue;
    return (incVal + 9).toString(36).toUpperCase();
  }

  exports.default = Ember.Helper.helper(incrementAlpha);
});