define('yxt-apps/routes/journal/studies/study/create/setup', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    breadCrumb: Ember.computed('controller.model.study.title', function () {
      const pageName = this.get('controller.model.study.title') || 'Study';
      const breadCrumbTitle = { title: `${pageName} Setup` };
      return breadCrumbTitle;
    })
  });
});