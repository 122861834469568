define('yxt-apps/models/study-entry', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    modifiedAt: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    studyParticipant: _emberData.default.belongsTo('study-participant'),
    studyParticipantEntryType: _emberData.default.attr(),
    templateId: _emberData.default.attr(),
    //state: DS.attr('string'),
    status: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    //__t: DS.attr(),
    pages: _emberData.default.hasMany('page'),
    properties: _emberData.default.attr(),
    taskData: _emberData.default.attr()
  });
});