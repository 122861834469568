define('yxt-apps/components/study-participant-manager', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    let studyParticipants = [];
    exports.default = Ember.Component.extend({
        isEditMode: false,
        myOwnTemplate: '',
        isCreateMode: true,
        selectedParticipants: Ember.computed('participants.@each.isSelected', function () {
            return this.get('participants').filterBy('isSelected', true);
        }),
        isEditingDisabled: Ember.computed('selectedParticipants', function () {
            if (this.get('selectedParticipants').length < 1) {
                return true;
            }
        }),
        isDeletingDisabled: Ember.computed('selectedParticipants', function () {
            if (this.get('selectedParticipants').length < 1) {
                return true;
            }
        }),

        areAllParticipantsSelected: Ember.computed('selectedParticipants', 'participants', {
            get() {
                if (this.get('selectedParticipants').length == this.get('participants').length) {
                    return true;
                }
            },
            set(key, value) {
                if (value == true) {
                    this.get('participants').forEach(function (participant) {
                        participant.set('isSelected', true);
                    });
                } else {
                    this.get('participants').forEach(function (participant) {
                        participant.set('isSelected', false);
                    });
                }
            }
        }),

        didInsertElement() {
            let self = this;
            let study = this.get('study');
            let myOwnTemplate = this.get('store').peekRecord('study-participant-template', this.get('config.participantMyOwnTemplateId'));
            this.set('myOwnTemplate', myOwnTemplate);

            if (this.get('isCreateMode')) {

                this.get('store').query('study-participant', {
                    filterByField: 'study',
                    filterValue: study.get('id')
                }).then(function (studyParticipants) {

                    let curIndex = studyParticipants.get('length');
                    let startingGenerator = 1;
                    if (curIndex < startingGenerator) {
                        for (var i = curIndex; i < startingGenerator; i++) {
                            let person = self.get('store').createRecord('participant', {
                                isEditing: true,
                                template: myOwnTemplate
                            });
                            person.set('profile', self.get('store').createFragment('profile', {}));

                            studyParticipants[i] = self.get('store').createRecord('study-participant', {
                                study: study,
                                participant: person,
                                isEditing: true
                            });

                            study.set('participantQty', i);
                        }
                    }
                });
            }
        },
        actions: {
            editSelectedParticipants(participants) {
                // for each.
                participants.forEach(function (participant) {
                    if (participant.get('isSelected')) {
                        participant.set('isEditing', true);
                    }
                });
                this.set('isEditMode', true);
            },

            cancelSelectedParticipants(participants) {
                let self = this;
                // for each.
                participants.forEach(function (participant) {
                    if (participant.get('isSelected')) {
                        if (participant.get('participant').get('hasDirtyAttributes')) {
                            let participantId = participant.get('participant.id');
                            let storeParticipant = self.get('store').peekRecord('participant', participantId);
                            storeParticipant.rollbackAttributes();
                        }

                        participant.set('isEditing', false);
                        participant.set('isSelected', false);
                    }
                });
                this.set('isEditMode', false);
            },

            saveSelectedParticipants(participants) {
                let self = this;

                let reminderEmailTemplateSubject = this.get('reminderMailTemplate.subject');
                let reminderEmailTemplateBody = this.get('reminderMailTemplate.body');
                let invitationEmailTemplateSubject = this.get('invitationMailTemplate.subject');
                let invitationEmailTemplateBody = this.get('invitationMailTemplate.body');

                function failure(reason) {
                    self.toast.error(reason);
                    self.set('reminderMailTemplate.subject', reminderEmailTemplateSubject);
                    self.set('reminderMailTemplate.body', reminderEmailTemplateBody);
                    self.get('reminderMailTemplate').save();
                }

                function notifySuccess(participant) {
                    self.toast.success(`Invitation emailed successfully`);
                    self.set('reminderMailTemplate.subject', reminderEmailTemplateSubject);
                    self.set('reminderMailTemplate.body', reminderEmailTemplateBody);
                    self.get('reminderMailTemplate').save();
                }
                // for each.
                participants.forEach(function (participant) {
                    if (participant.get('isSelected')) {
                        participant.set('isEditing', false);
                        participant.set('isSelected', false);
                        let participantId = participant.get('participant.id');
                        let studyParticipantId = participant.get('id');
                        let storeParticipant = self.get('store').peekRecord('participant', participantId);
                        let storeStudyParticipant = self.get('store').peekRecord('study-participant', studyParticipantId);
                        if (storeParticipant.get('hasDirtyAttributes')) {
                            //if participant's email address changed, store it so we can send them an invite
                            let participantEmailChanged = storeParticipant.changedAttributes();

                            storeParticipant.save();

                            // if participant's email address changed...
                            if (participantEmailChanged.email) {
                                // update the reminder email template to use the invitation content.
                                self.set('reminderMailTemplate.subject', invitationEmailTemplateSubject);
                                self.set('reminderMailTemplate.body', invitationEmailTemplateBody);
                                self.get('reminderMailTemplate').save().then(function () {
                                    // then save the participant and send the 'reminder'
                                    participant.save().then(() => {
                                        storeStudyParticipant.remind().then(notifySuccess).catch(failure);
                                    });
                                });
                            } else {
                                participant.save();
                            }
                        }
                    }
                });
                this.set('isEditMode', false);
            },

            deleteSelectedParticipants(participants) {
                // for each.
                participants.forEach(function (participant) {
                    if (participant.get('isSelected')) {
                        participant.destroyRecord();
                    }
                });
                this.set('isRemoveParticipantsFromStudyModal', false);
            },
            removeParticipants(participants) {
                // for each.
                participants.forEach(function (participant) {
                    if (participant.get('isSelected')) {
                        participant.destroyRecord();
                    }
                });
                this.set('isRemoveParticipantsFromStudyModal', false);
            },
            cancelModal() {
                this.set('isRemoveParticipantsFromStudyModal', false);
            },
            promptDeleteSelectedParticipants(participants) {
                this.set('isRemoveParticipantsFromStudyModal', true);
            },

            addParticipant() {
                let self = this;
                let person = self.get('store').createRecord('participant', {
                    isEditing: true,
                    template: this.get('myOwnTemplate')
                });
                person.set('profile', self.get('store').createFragment('profile', {}));

                self.get('store').createRecord('study-participant', {
                    study: self.get('study'),
                    participant: person,
                    isEditing: true
                });

                let newQty = self.get('study').get('participants.length');
                self.get('study').set('participantQty', newQty);
            }

        }
    });
});