define('yxt-apps/components/change-password', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'section',
        classNames: ['form-content'],
        displayEmailError: false,
        displayPasswordError: false,
        displayConfirmPasswordError: false,
        actions: {
            cancelChanges(user) {
                user.rollbackAttributes();
                history.back();
            },
            blurPassword() {
                if (!this.get('user.isPasswordValid')) {
                    this.set('displayPasswordError', true);
                } else {
                    this.set('displayPasswordError', false);
                }
            },
            blurConfirmPassword() {
                if (!this.get('user.isPasswordMatched')) {
                    this.set('displayConfirmPasswordError', true);
                } else {
                    this.set('displayConfirmPasswordError', false);
                }
            }
        }
    });
});