define('yxt-apps/routes/ford/projects/project', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: {
            title: 'Project Studies'
        },
        getStudies(params) {
            return this.get('store').query('study', {
                filterByField: 'project',
                filterValue: params.project_id
            }).then(function (studies) {
                return studies.sortBy('modifiedAt').reverse();
            });
        },
        getProject(params) {
            return this.get('store').findRecord('project', params.project_id);
        },
        getAllProjects(params) {
            return this.get('store').findAll('project');
        },
        model(params) {

            return Ember.RSVP.hash({
                studies: this.getStudies(params),
                project: this.getProject(params),
                projects: this.getAllProjects(params)
            });
        },
        beforeModel() {
            this.set('headData.title', 'Project Studies');
        },
        actions: {
            reloadModel: function () {
                this.refresh();
            },
            updateRemainingCount(project) {
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }
                function notifySuccess(project) {
                    self.toast.success(`Team (${project.get('name')}) remaining studies updated succesfully`);
                }
                project.save().then(notifySuccess).catch(failure);
            }
        }
    });
});