define('yxt-apps/components/report-card/share-data', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			onSuccess() {},
			onError() {}
		}
	});
});