define('yxt-apps/routes/journal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        breadCrumb: {
            title: 'Journal'
        },
        getStorage() {
            let self = this;
            if (this.get('session.isAuthenticated')) {
                // then fetch for our organization to retrieve storage, etc.
                return this.get('store').findRecord('organization', this.get('me.organizationId')).then(function (org) {

                    let currentStorage = org.get('currentStorage');
                    let storageLimit = org.get('storageLimit');
                    if (currentStorage) {
                        self.get('me').saveStorageUsed(currentStorage);
                        self.set('me.orgStored', currentStorage);
                    } else {
                        self.get('me').saveStorageUsed('0');
                        self.set('me.orgStored', 0);
                    }

                    if (storageLimit) {
                        self.get('me').saveStorageCapacity(storageLimit);
                        self.set('me.orgCapacity', storageLimit);
                    } else {
                        self.get('me').saveStorageCapacity('Unlimited');
                        self.set('me.orgCapacity', 'Unlimited');
                    }
                });
            }
        },
        beforeModel() {
            this.get('me').saveAppName('journal');
            Ember.$('body').attr('appName', 'journal');
            this.set('headData.title', 'YouXJournal');
        },
        afterModel() {
            this.getStorage();
            let usersPoller = this.get('usersPoller');
            //this.set('headData.title', `YouXReaction - ${model.study.get('title')}`);
            // Make sure we only create one poller instance. Without this every time onPoll
            // is called afterModel would create a new poller causing us to have a growing list
            // of pollers all polling the same thing (which would result in more frequent polling).
            if (!usersPoller) {
                usersPoller = this.get('pollboy').add(this, this.onPoll, this.get('config.pollFrequency'));
                this.set('usersPoller', usersPoller);
            }
        },
        onPoll() {
            return this.getStorage();
        },
        deactivate() {
            const usersPoller = this.get('usersPoller');
            this.get('pollboy').remove(usersPoller);
        }
    });
});