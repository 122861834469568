define('yxt-apps/components/chart/emotion-graph', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['emotion-chart'],
    yLabels: ["Excited", "Satisfied", "Indifferent", "Confused", "Frustrated"],
    //data: [3, 2, 1, 3, 4, 5, 1], //testing
    entries: [],
    data: [],
    entriesPosition: [],
    xWidth: 98,
    yHeight: 90,
    pages: [],
    selectedIndex: null,
    didUpdateAttrs() {
      this.draw();
    },
    willRender() {
      this.set("entriesPosition", []);

      let data = this.get("data");
      let dataTotal = data.length;
      for (let i = 0; i < dataTotal; i++) {
        let left = i * this.xWidth + 9;
        let top = (5 - data[i]) * this.yHeight + 5;
        let thumbSanitized = new Ember.String.htmlSafe("background-image: url(" + this.entries[i].thumbnail + ")");
        let coords = new Ember.String.htmlSafe("left:" + left + "px; top:" + top + "px");

        this.entriesPosition.push({
          coords: coords,
          thumbnail: thumbSanitized,
          data: data[i]
        });
      }
    },
    didInsertElement() {
      this.draw();
    },
    draw() {
      /*
      let xWidth = 98;
      let yHeight = 90;
       //create Y axis
      let html = '<div class="y-axis">';
      let ylabels = this.get("yLabels");
      let ylabelsTotal = ylabels.length;
       for(let i = ylabelsTotal - 1; i >= 0; i--) {
        html += `<div class="item">${ylabels[i]}</div>`;
      }
      html += '</div>';
      //create container for x-axis and data viewport
      html += `<div class="viewport">
                 <div class="x-axis"></div>
               </div>`;
      Ember.$(this.element).html(html);
       let data = this.get("data");
      let dataTotal = data.length;
       //populate x-axis
      html = '';
      for(let i = 1; i <= dataTotal; i++) {
        html += `<div class="item">Entry ${i}</div>`;
      }
       Ember.$(this.element).find(".x-axis").html(html);
       //create data
      html = '';
      for(let i = 0; i < dataTotal; i++) {
        let left = i*xWidth + 9;
        let top = (5 - data[i])*yHeight + 5;
         //html += `<div class="value value${data[i]}" style="left: ${left}px; top: ${top}px"></div>`;
        let thumb = this.entries[i].thumbnail;
        html += `<div class="value" style="left: ${left}px; top: ${top}px;background-image: url(${thumb})"></div>`;
      }
      let viewport = Ember.$(this.element).children(".viewport");
      viewport.append(html);
       let viewportWidth = xWidth*dataTotal;
      if(viewportWidth < viewport.width())
        viewportWidth = viewport.width();
       let svg = d3.select("#" + this.element.id + " .viewport")
        .append("svg")
        .attr("width", viewportWidth)
        .attr("height", yHeight*5);
       //draw fancy background behind the chart
      for(let i = 0; i < ylabelsTotal; i++) {
        let color = (i%2 == 0) ? "#EAEEF2" : "#F2F6F9";
        svg.append("rect")
          .attr("x", 0)
          .attr("y", i*yHeight)
          .attr("width", viewportWidth)
          .attr("height", yHeight)
          .attr("fill", color);
      }
       //draw the lines
      for(let i = 0; i < dataTotal - 1; i++) {
        svg.append("line")
          .attr("x1", i*xWidth + xWidth/2)
          .attr("y1", (5 - data[i])*yHeight + yHeight/2)
          .attr("x2", (i+1)*xWidth + xWidth/2)
          .attr("y2", (5 - data[i+1])*yHeight + yHeight/2)
          .attr("stroke-width", 1)
          .attr("stroke", "#393E4E");
      }*/
      //let xWidth = 98;
      //let yHeight = 90;

      //create Y axis
      let ylabels = this.get("yLabels");
      let ylabelsTotal = ylabels.length;

      let data = this.get("data");
      let dataTotal = data.length;

      //create data
      //let html = '';
      let viewport = Ember.$(this.element).children(".viewport");
      //viewport.append(html);

      let viewportWidth = this.xWidth * dataTotal;
      if (viewportWidth < viewport.width()) viewportWidth = viewport.width();

      let svg = d3.select("#" + this.element.id + " .viewport .dots").append("svg").attr("width", viewportWidth).attr("height", this.yHeight * 5);

      //draw fancy background behind the chart
      for (let i = 0; i < ylabelsTotal; i++) {
        let color = i % 2 == 0 ? "#EAEEF2" : "#F2F6F9";
        svg.append("rect").attr("x", 0).attr("y", i * this.yHeight).attr("width", viewportWidth).attr("height", this.yHeight).attr("fill", color);
      }

      //draw the lines
      for (let i = 0; i < dataTotal - 1; i++) {
        svg.append("line").attr("x1", i * this.xWidth + this.xWidth / 2).attr("y1", (5 - data[i]) * this.yHeight + this.yHeight / 2).attr("x2", (i + 1) * this.xWidth + this.xWidth / 2).attr("y2", (5 - data[i + 1]) * this.yHeight + this.yHeight / 2).attr("stroke-width", 1).attr("stroke", "#393E4E");
      }
    },
    actions: {
      viewDetail(index) {
        this.set('pages', this.get('entries')[index].pages);
        this.set('selectedIndex', index);
      }
    }

  });
});