define('yxt-apps/components/task-generator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'section',
    classNames: ['control-generator', 'task-creation-panel'],
    pages: [],
    studyWebsiteTask: null,
    store: null,
    activeTask: null,
    easeCompletionQuestion: 'Please rate the ease of completing this task',
    easeCompletionFormat: 'Scale',
    easeCompletionScalePoints: 5,
    easeCompletionIsRequired: true,

    satisfactionQuestion: 'How satisfied were you in completing this task?',
    satisfactionFormat: 'Scale',
    satisfactionScalePoints: 5,
    satisfactionIsRequired: true,

    emotionQuestion: 'What emotion did you feel while completing this task?',
    emotionFormat: 'Scale',
    emotionScalePoints: 5,
    emotionIsRequired: true,

    didInsertElement() {
      this.get('pages').setEach('isEditing', false);
      this.get('pages').get('firstObject').set('isEditing', true);
      this.set('activeQuestion', this.get('pages').get('firstObject'));
    },
    actions: {
      editItem(item) {
        this.get('pages').setEach('isEditing', false);
        let allPages = this.get('store').peekAll('page-template');
        allPages.setEach('isEditing', false);
        item.set('isEditing', true);
        this.set('activeQuestion', item);
      },
      deleteItem(item) {
        item.deleteRecord();
        let studyWebsiteTask = this.get('studyWebsiteTask');
      },

      addItem() {

        let self = this;

        this.get('pages').setEach('isEditing', false);
        let allPages = this.get('store').peekAll('page-template');
        allPages.setEach('isEditing', false);

        let ranArray = new Uint32Array(1);
        window.crypto.getRandomValues(ranArray);
        let uId = `${moment()}_${ranArray[0]}`;
        let newtaskName = `Task_${uId}`;
        let newItem = this.get('store').createRecord('page-template', {
          title: newtaskName,
          isEditing: true
        });
        let newPageContent = newItem.get('content');
        newPageContent.createFragment({
          name: newtaskName,
          contentType: 'TextEntry',
          metadata: {
            isMandatory: true,
            question: ''
          }
        });

        let studyWebsiteTask = this.get('studyWebsiteTask');
        studyWebsiteTask.get('pages').pushObject(newItem);
        self.set('activeQuestion', newItem);
      },
      reorderItems(groupModel, itemModels) {
        groupModel.set('pages', itemModels);
        this.set('pages', groupModel);
        this.get('studyWebsiteTask').save();
      },
      addTaskQuestion(page) {
        let pageContents = page.get('content');

        let ranArray = new Uint32Array(1);
        window.crypto.getRandomValues(ranArray);
        let uId = `${moment()}_${ranArray[0]}`;
        let newtaskQuestionName = `Task_Question_${uId}`;

        pageContents.createFragment({
          name: newtaskQuestionName,
          contentType: 'Scale',
          metadata: {
            isMandatory: true,
            question: '',
            body: [{
              name: '',
              size: 6
            }]
          }
        });
      }
    }
  });
});