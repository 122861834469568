define('yxt-apps/services/question-validator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    validateQuestion(page) {
      let is_ok = true;
      let content = page.get('content').get('firstObject');

      if (content != undefined && content.get('metadata').get('question') != "") {
        if (content.get('contentType') == 'MultiChoice') {
          let valid_ones = 0;
          let total = 0;

          content.get('metadata.body').forEach(function (item) {
            total++;
            if (item.get('name') != '') valid_ones++;
          });

          if (valid_ones != total || valid_ones < 2) is_ok = false;
        }
      } else {
        is_ok = false;
      }

      page.set('isValid', is_ok);
      return is_ok;
    }
  });
});