define('yxt-apps/routes/reset-password-success', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        breadCrumb: {
            title: 'youXtools Password'
        }
    });
});