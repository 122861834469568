define('yxt-apps/routes/reaction/studies/study/overview', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    breadCrumb: Ember.computed('controller.model.study.title', function () {
      const pageName = this.get('controller.model.study.title') || 'Study';
      const breadCrumbTitle = { title: `${pageName} Overview` };
      return breadCrumbTitle;
    }),
    beforeModel() {
      this.get('state').set('isCreationFlowActive', false);
    },
    afterModel: function (transition) {
      let parentModel = this.modelFor('reaction.studies.study');
      let study = parentModel.study;
      let invitationMailTemplate = parentModel.invitationMailTemplate;
      let reminderMailTemplate = parentModel.reminderMailTemplate;

      if (invitationMailTemplate.get('body') == '<p class="body-text">You have been invited to participate in a remote usability session!</p><br><p class="body-text">You will be asked to complete a series of tasks and will give feedback on them for about 15 minutes.</p><br><p class="body-text">The link below will take you to the study page. Watch the short video before you begin. If you do not already have the required browser and screen recorder, you will be guided through 3 quick and secure steps before the actual recording begins. When asked for permission to record your screen, make sure you select the browser “YouXtools-Pick this browser to record”. At the end of the session, you may be asked to complete a few post-session questions while your video is uploading. Make sure not to navigate away from the study until you have finished.</p><br><p class="body-text">Give your best effort and make sure to say all of your feedback out loud during testing, your input matters!</p><br><p class="body-text">Alright, it’s time to start your study! Click the link below to get started.</p><br><p class="body-text"><a clicktracking="off" href="{{invitation_link}}">Get started</a></p>') {

        invitationMailTemplate.set('body', '<style>.body-text,p{margin-bottom: 20px!important;} h2,p{overflow-wrap: normal;}</style><h2>You have been invited to participate in a Reaction Study for usability testing!</h2><p class="body-text">You will be asked to complete a series of tasks and will give feedback out loud for 15 minutes.</p><p class="body-text">The link below will take you to the study page and needs to be opened on Google Chrome. If you do not already have the required browser and screen recorder, you will be guided through 3 secure and easy steps before the study begins. When asked for permission to record your screen, choose <strong>Share</strong>.</p><p class="body-text">Give your best effort and remember to think out loud!</p><p class="body-text">It&rsquo;s time to start your study!</p><p></p><div class="body-text" style="text-align: center;"><a style="color: #ffffff; background: #2CB1D0; background-color: #2CB1D0; padding: 20px 30px; font-size: 18px; width=100%; display: block; text-decoration: none; font-weight: bold; margin-bottom: 45px;" href="{{invitation_link}}" clicktracking="off">Get started</a></div>');
      }

      if (invitationMailTemplate.get('subject') == 'Participate in our Study!') {
        invitationMailTemplate.set('subject', `Participate in our study (${study.get('title')})`);
      }

      if (reminderMailTemplate.get('body') == '<p class="body-text">Just a reminder that you have not completed your remote usability study yet. Click the link below to provide your feedback.</p><br><p class="body-text">Thank you!</p><br><p class="body-text"><a clicktracking="off" href="{{invitation_link}}">Get started</a></p>') {
        reminderMailTemplate.set('body', '<style>.body-text,p{margin-bottom: 20px!important;} h2,p{overflow-wrap: normal;}</style><h2>Just a reminder that you have not completed your Reaction Study for usability testing yet.</h2><p class="body-text">You will be asked to complete a series of tasks and will give feedback out loud for 15 minutes.</p><p class="body-text">The link below will take you to the study page and needs to be opened on Google Chrome. If you do not already have the required browser and screen recorder, you will be guided through 3 secure and easy steps before the study begins. When asked for permission to record your screen, choose <strong>Share</strong>.</p><p class="body-text">Give your best effort and remember to think out loud!</p><p class="body-text">It&rsquo;s time to start your study!</p><p></p><div class="body-text" style="text-align: center;"><a style="color: #ffffff; background: #2CB1D0; background-color: #2CB1D0; padding: 20px 30px; font-size: 18px; width=100%; display: block; text-decoration: none; font-weight: bold; margin-bottom: 45px;" href="{{invitation_link}}" clicktracking="off">Get started</a></div>');
      }
      if (reminderMailTemplate.get('body') == '<style>.body-text,p{margin-bottom: 20px!important;} h2,p{overflow-wrap: normal;}</style><h2>Just a reminder that have not completed your Reaction Study for usability testing yet.</h2><p class="body-text">You will be asked to complete a series of tasks and will give feedback out loud for 15 minutes.</p><p class="body-text">The link below will take you to the study page and needs to be opened on Google Chrome. If you do not already have the required browser and screen recorder, you will be guided through 3 secure and easy steps before the study begins. When asked for permission to record your screen, choose <strong>Share</strong>.</p><p class="body-text">Give your best effort and remember to think out loud!</p><p class="body-text">It&rsquo;s time to start your study!</p><p></p><div class="body-text" style="text-align: center;"><a style="color: #ffffff; background: #2CB1D0; background-color: #2CB1D0; padding: 20px 30px; font-size: 18px; width=100%; display: block; text-decoration: none; font-weight: bold; margin-bottom: 45px;" href="{{invitation_link}}" clicktracking="off">Get started</a></div>') {

        reminderMailTemplate.set('body', '<style>.body-text,p{margin-bottom: 20px!important;} h2,p{overflow-wrap: normal;}</style><h2>Just a reminder that you have not completed your Reaction Study for usability testing yet.</h2><p class="body-text">You will be asked to complete a series of tasks and will give feedback out loud for 15 minutes.</p><p class="body-text">The link below will take you to the study page and needs to be opened on Google Chrome. If you do not already have the required browser and screen recorder, you will be guided through 3 secure and easy steps before the study begins. When asked for permission to record your screen, choose <strong>Share</strong>.</p><p class="body-text">Give your best effort and remember to think out loud!</p><p class="body-text">It&rsquo;s time to start your study!</p><p></p><div class="body-text" style="text-align: center;"><a style="color: #ffffff; background: #2CB1D0; background-color: #2CB1D0; padding: 20px 30px; font-size: 18px; width=100%; display: block; text-decoration: none; font-weight: bold; margin-bottom: 45px;" href="{{invitation_link}}" clicktracking="off">Get started</a></div>');
      }

      reminderMailTemplate.set('subject', `Reminder about our study (${study.get('title')})`);

      if (invitationMailTemplate.get('hasDirtyAttributes')) {
        invitationMailTemplate.save();
      }
      if (reminderMailTemplate.get('hasDirtyAttributes')) {
        reminderMailTemplate.save();
      }

      Ember.$('body > div.ember-view').animate({
        scrollTop: 0
      }, 500);
      this._super(transition);
    },
    actions: {
      editStudy() {
        this.get('state').set('isInfoExpanded', true);
        this.get('state').set('isEditMode', true);
      },

      launchStudy(study) {
        study.processpayment().then(() => {
          study.launch().then(() => {
            study.set('status', 'Active');
          });
        }).catch(() => {
          study.launch().then(() => {
            study.set('status', 'Active');
          });
        });
      },
      completeStudyWorkflow(study) {
        // figure out where to go.
        if (study.get('isReviewWorkflowComplete')) {
          this.transitionTo('reaction.studies.study.create.launch');
        } else if (study.get('isTestWorkflowComplete')) {
          this.transitionTo('reaction.studies.study.create.review.purchase');
        } else if (study.get('isParticipantsWorkflowComplete')) {
          this.transitionTo('reaction.studies.study.create.create');
        } else if (study.get('isCreateWorkflowComplete')) {
          this.transitionTo('reaction.studies.study.create.participants');
        } else {
          this.transitionTo('reaction.studies.study.create.setup');
        }
      }
    }
  });
});