define('yxt-apps/initializers/model-fragments', ['exports', 'ember-data-model-fragments'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'fragmentTransform',
    before: 'ember-data',

    initialize(application) {
      application.inject('transform', 'store', 'service:store');
    }
  };
});