define('yxt-apps/models/study', ['exports', 'ember-data', 'ember-api-actions', 'ember-data-model-fragments/attributes', 'moment'], function (exports, _emberData, _emberApiActions, _attributes, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        tenantId: _emberData.default.attr('string'),
        owner: _emberData.default.belongsTo('user'),
        title: _emberData.default.attr('string', {
            defaultValue: 'New Study'
        }),
        logo: (0, _attributes.fragment)('logo'),
        status: _emberData.default.attr('string', {
            defaultValue: 'incomplete'
        }),
        startDate: _emberData.default.attr('string', {
            defaultValue: new Date().toISOString()
        }),
        endDate: Ember.computed('startDate', 'numberOfDays', function () {
            let startDate = this.get('startDate');
            let numberOfDays = this.get('numberOfDays');
            return (0, _moment.default)(startDate).add(numberOfDays, 'days');
        }),
        description: _emberData.default.attr('string', {
            defaultValue: ''
        }),
        template: _emberData.default.attr('string'),
        project: _emberData.default.belongsTo('project'),
        workflowType: _emberData.default.attr('string'),
        participantData: (0, _attributes.fragment)('participant-data'),
        participants: _emberData.default.hasMany('study-participant'),
        features: (0, _attributes.fragmentArray)('study-feature'),

        isYouXScoreFeatureEnabled: Ember.computed('features', 'features.@each.enabled', function () {
            if (this.get('features').filterBy('name', 'youxscore').get('length') > 0) {
                return this.get('features').filterBy('name', 'youxscore').get('firstObject').get('enabled');
            } else {
                return false;
            }
        }),
        isVoicePromptFeatureEnabled: Ember.computed('features', 'features.@each.enabled', function () {
            if (this.get('features').filterBy('name', 'voiceprompts').get('length') > 0) {
                return this.get('features').filterBy('name', 'voiceprompts').get('firstObject').get('enabled');
            } else {
                return false;
            }
        }),
        youXScoreFeature: Ember.computed('features', 'features.@each.enabled', function () {
            return this.get('features').filterBy('name', 'youxscore').get('firstObject');
        }),

        voicePromptsFeature: Ember.computed('features', 'features.@each.enabled', function () {
            return this.get('features').filterBy('name', 'voiceprompts').get('firstObject');
        }),

        studyPostSessionQuestions: _emberData.default.belongsTo('study-post-session-question'),
        language: _emberData.default.attr('string', {
            defaultValue: 'en'
        }),
        paymentState: _emberData.default.attr('string', {
            defaultValue: 'unpaid'
        }),
        workerData: _emberData.default.belongsTo('study-worker-data'),
        numberOfDays: _emberData.default.attr('number', {
            defaultValue: 1
        }),
        entriesPerDay: _emberData.default.attr('number', {
            defaultValue: 1
        }),
        intro: _emberData.default.attr('string', {
            defaultValue: 'Read through tasks and verbally give your feedback and answers out loud. Speak clearly into the microphone and make sure there is not surrounding noise. Screen recording and audio must be on to complete this session.'
        }),
        instructions: (0, _attributes.fragment)('study-meta'),
        studyWebsiteTasks: _emberData.default.hasMany('study-website-task'),
        studyJournalTasks: _emberData.default.belongsTo('study-journal-task'),
        modifiedAt: _emberData.default.attr(),
        createdAt: _emberData.default.attr(),
        participantResult: _emberData.default.belongsTo('participant-result'),
        autoPayParticipants: _emberData.default.attr('boolean', {
            defaultValue: false
        }),
        autoPayParticipantsAmount: _emberData.default.attr('string', {
            defaultValue: 0.00
        }),
        autoPayParticipantsCurrency: _emberData.default.attr('string', {
            defaultValue: 'USD'
        }),
        linkToAltText: Ember.computed('status', 'studyType', 'title', function () {
            let status = this.get('status').toLowerCase();
            let studyType = this.get('studyType');
            let title = this.get('title');

            if (status === 'incomplete') {
                if (studyType) {
                    if (studyType.get('isTypeFreePromo')) {
                        return 'Create your free study';
                    } else {
                        return 'Finish creating this study';
                    }
                } else {
                    return 'Finish creating this study';
                }
            } else if (status === 'active') {
                return `View reports for ${title}`;
            } else if (status === 'ready') {
                return `Review and launch ${title}`;
            } else if (status === 'complete') {
                return `View reports for ${title}`;
            }
        }),
        participantQty: Ember.computed('participantData', {
            get() {
                return this.get('participantData.quantity');
            },
            set(key, value) {
                value = this.set('participantData.quantity', Number(value));
                this.save();
                return this.set('participantData.quantity', Number(value));
            }
        }),

        isActive: Ember.computed('status', function () {
            let status = this.get('status').toLowerCase();
            if (status === 'active' || status === 'complete' || status === 'completed') {
                return true;
            } else {
                return false;
            }
        }),

        isInProgress: Ember.computed('status', function () {
            let status = this.get('status').toLowerCase();
            if (status === 'active') {
                return true;
            } else {
                return false;
            }
        }),

        isComplete: Ember.computed('status', function () {
            let status = this.get('status').toLowerCase();
            if (status === 'complete' || status === 'completed') {
                return true;
            } else {
                return false;
            }
        }),
        isExpired: Ember.computed('status', 'startDate', 'numberOfDays', function () {
            let status = this.get('status').toLowerCase();
            let startDate = this.get('startDate');
            let numberOfDays = this.get('numberOfDays');
            let endDate = (0, _moment.default)(startDate).add(numberOfDays, 'days');
            if ((0, _moment.default)() > endDate) {
                return true;
            }
        }),
        isIncomplete: Ember.computed('status', function () {
            let status = this.get('status').toLowerCase();
            if (status === 'incomplete') {
                return true;
            } else {
                return false;
            }
        }),

        isReady: Ember.computed('status', function () {
            let status = this.get('status').toLowerCase();
            if (status === 'ready' || status === 'pendingpayment') {
                return true;
            } else {
                return false;
            }
        }),
        isSelected: _emberData.default.attr('boolean', { defaultValue: false }),
        // local props.
        studyTemplate: _emberData.default.belongsTo('study-template'),
        studyType: Ember.computed('template', function () {
            let template = this.get('template');
            let templateId = this.get('template.id');
            if (templateId) {
                return this.get('store').peekRecord('study-template', templateId);
            } else {
                return this.get('store').peekRecord('study-template', template);
            }
        }),
        studyTemplateOptions: Ember.computed(function () {
            return this.get('store').peekAll('study-template');
        }),

        // participants.
        participantType: _emberData.default.attr('string'),
        participantTypeComputed: Ember.computed('participantData', {
            get() {
                let participantDataTemplate = this.get('participantData.templateId');
                if (participantDataTemplate === '59c94b36d34d6d0a480896c5') {
                    return 'express';
                } else if (participantDataTemplate === '59c94b36d34d6d0a480896cc') {
                    return 'qualification';
                } else if (participantDataTemplate === '59c94b36d34d6d0a480896ca') {
                    return 'qualification';
                } else if (participantDataTemplate === '59c94b36d34d6d0a480896c6') {
                    return 'targeted';
                } else if (participantDataTemplate === '59c94b36d34d6d0a480896c7') {
                    return 'premium';
                } else if (participantDataTemplate === '59c94b36d34d6d0a480896c8') {
                    return 'myown';
                } else if (participantDataTemplate === '59c94b36d34d6d0a480896c9') {
                    return 'custom';
                } else if (participantDataTemplate === '59c94b36d34d6d0a480896cb') {
                    return 'free-express';
                }
            },
            set(key, value) {
                let participantData = this.get('participantData');
                if (value === 'express') {
                    this.set(participantData.templateId, "59c94b36d34d6d0a480896c5");
                } else if (value === 'qualification') {
                    this.set(participantData.templateId, "59c94b36d34d6d0a480896cc");
                } else if (value === 'targeted') {
                    this.set(participantData.templateId, "59c94b36d34d6d0a480896c6");
                } else if (value === 'premium') {
                    this.set(participantData.templateId, "59c94b36d34d6d0a480896c7");
                } else if (value === 'myown') {
                    this.set(participantData.templateId, "59c94b36d34d6d0a480896c8");
                } else if (value === 'custom') {
                    this.set(participantData.templateId, "59c94b36d34d6d0a480896c9");
                } else if (value === 'free-express') {
                    this.set(participantData.templateId, "59c94b36d34d6d0a480896cb");
                }
            }
        }),

        percentComplete: _emberData.default.attr('number', {
            defaultValue: 0
        }),

        // work-flow progress
        isCreateReactionWorkflowComplete: Ember.computed('title', 'studyWebsiteTasks.@each.isUrlValid', function () {
            let studyWebsiteTasks = this.get('studyWebsiteTasks');
            let validUrls = studyWebsiteTasks.filterBy('isUrlValid', true);
            let title = this.get('title');
            if (title && validUrls.length > 0) {
                return true;
            } else {
                return false;
            }
        }),

        isCreateJournalWorkflowComplete: Ember.computed('title', function () {
            let title = this.get('title');
            if (title) {
                return true;
            } else {
                return false;
            }
        }),

        isCreateWorkflowComplete: Ember.computed('isCreateReactionWorkflowComplete', 'isCreateJournalWorkflowComplete', 'studyTemplate', function () {
            let studyType = this.get('studyTemplate.studyType').toLowerCase();
            let isCreateReactionWorkflowComplete = this.get('isCreateReactionWorkflowComplete');
            let isCreateJournalWorkflowComplete = this.get('isCreateJournalWorkflowComplete');

            if (studyType == 'reaction' && isCreateReactionWorkflowComplete) {
                return true;
            } else if (studyType == 'journal' && isCreateJournalWorkflowComplete) {
                return true;
            } else {
                return false;
            }
        }),

        isParticipantsWorkflowComplete: Ember.computed('participants', 'participantQty', function () {
            let participants = this.get('participants');
            let howManyStudyParticipants = 0;
            if (participants) {
                howManyStudyParticipants = participants.get('length');

                participants.forEach(function (participant) {
                    if (participant.get("id") != null) {
                        howManyStudyParticipants++;
                    }
                });

                if (howManyStudyParticipants > 0) {
                    return true;
                }
            }
        }),

        isReactionSetupWorkflowComplete: Ember.computed('studyWebsiteTasks.@each.isUrlValid', 'studyWebsiteTasks.@each.pages', 'isReactionTasksDone', 'isPostSessionQuestionsDone', function () {
            let studyWebsiteTasks = this.get('studyWebsiteTasks');

            if (studyWebsiteTasks) {

                if (studyWebsiteTasks.get('firstObject')) {

                    if (studyWebsiteTasks.get('firstObject').get('pages')) {
                        let pagesLength = studyWebsiteTasks.get('firstObject').get('pages.length');
                        let validUrls = studyWebsiteTasks.filterBy('isUrlValid', true);
                        if (validUrls.length > 0 && this.get('isReactionTasksDone') && this.get('isPostSessionQuestionsDone')) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            }
        }),

        isJournalSetupWorkflowComplete: Ember.computed('isJournalEntriesDone', 'isPostSessionQuestionsDone', function () {
            if (this.get('isJournalEntriesDone') && this.get('isPostSessionQuestionsDone')) {
                return true;
            } else {
                return false;
            }
        }),

        isTestWorkflowComplete: Ember.computed('studyWebsiteTasks.@each.isUrlValid', 'isReactionTasksDone', 'isJournalEntriesDone', 'isPostSessionQuestionsDone', 'studyTemplate', function () {
            let studyType = this.get('studyTemplate.studyType').toLowerCase();
            if (studyType == 'reaction') {
                let studyWebsiteTasks = this.get('studyWebsiteTasks');
                let validUrls = studyWebsiteTasks.filterBy('isUrlValid', true);
                if (validUrls.length > 0 && this.get('isReactionTasksDone') && this.get('isPostSessionQuestionsDone')) {
                    return true;
                } else {
                    return false;
                }
            } else if (studyType == 'journal') {
                if (this.get('isJournalEntriesDone') && this.get('isPostSessionQuestionsDone')) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }),

        isReviewWorkflowComplete: Ember.computed('status', function () {
            let status = this.get('status');
            if (status.toLowerCase() !== 'incomplete') {
                return true;
            }
        }),

        isLaunchWorkflowComplete: Ember.computed('status', function () {
            let status = this.get('status');
            if (status == 'active') {
                return true;
            }
            return false;
        }),

        isParticipantDisabled: Ember.computed('isCreateWorkflowComplete', 'isTestWorkflowComplete', function () {
            let createComplete = this.get('isCreateWorkflowComplete');
            let testComplete = this.get('isTestWorkflowComplete');
            if (createComplete && testComplete) {
                return false;
            } else {
                return true;
            }
        }),

        isTestDisabled: Ember.computed('isCreateWorkflowComplete', function () {
            let createComplete = this.get('isCreateWorkflowComplete');
            if (createComplete) {
                return false;
            } else {
                return true;
            }
        }),

        isReviewDisabled: Ember.computed('isCreateWorkflowComplete', 'isParticipantsWorkflowComplete', 'isTestWorkflowComplete', function () {
            let createComplete = this.get('isCreateWorkflowComplete');
            let participantsComplete = this.get('isParticipantsWorkflowComplete');
            let testComplete = this.get('isTestWorkflowComplete');
            if (createComplete && participantsComplete && testComplete) {
                return false;
            } else {
                return true;
            }
        }),
        isLaunchDisabled: Ember.computed('isCreateWorkflowComplete', 'isParticipantsWorkflowComplete', 'isTestWorkflowComplete', 'isReviewWorkflowComplete', function () {
            let createComplete = this.get('isCreateWorkflowComplete');
            let participantsComplete = this.get('isParticipantsWorkflowComplete');
            let testComplete = this.get('isTestWorkflowComplete');
            let reviewComplete = this.get('isReviewWorkflowComplete');
            if (createComplete && participantsComplete && testComplete && reviewComplete) {
                return false;
            } else {
                return true;
            }
        }),
        isReactionTasksDone: Ember.computed('studyWebsiteTasks.@each.areTasksValid', 'studyWebsiteTasks.@each.pages', function () {
            let invalidTasks = this.get('studyWebsiteTasks').filterBy('areTasksValid', false);
            if (this.get('studyWebsiteTasks').filterBy('areTasksValid', false).length > 0) {
                return false;
            } else {
                return true;
            }
        }),
        isJournalEntriesDone: Ember.computed('studyJournalTasks.areTasksValid', 'studyJournalTasks.@each.pages', function () {
            if (this.get('studyJournalTasks.areTasksValid')) {
                return true;
            } else {
                return false;
            }
        }),

        isPostSessionQuestionsDone: Ember.computed('studyPostSessionQuestions.@each.pages', 'studyPostSessionQuestions.areQuestionsValid', function () {
            if (this.get('studyPostSessionQuestions.areQuestionsValid') != 'undefined' && this.get('studyPostSessionQuestions.areQuestionsValid') != null) {
                if (this.get('studyPostSessionQuestions.areQuestionsValid')) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }),

        // endpoint special actions
        clone: (0, _emberApiActions.memberAction)({
            path: 'clone',
            type: 'post'
        }),
        completeprocessing: (0, _emberApiActions.memberAction)({
            path: 'completeprocessing',
            type: 'post'
        }),
        launch: (0, _emberApiActions.memberAction)({
            path: 'launch',
            type: 'post'
        }),
        processpayment: (0, _emberApiActions.memberAction)({
            path: 'processpayment',
            type: 'post'
        }),
        requestpayment: (0, _emberApiActions.memberAction)({
            path: 'requestpayment',
            type: 'post'
        }),
        submit: (0, _emberApiActions.memberAction)({
            path: 'submit',
            type: 'post'
        })

    });
});