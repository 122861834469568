define('yxt-apps/serializers/study-participant', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      modifiedAt: { serialize: false },
      createdAt: { serialize: false },
      tempKey: { serialize: false },
      isViewing: { serialize: false },
      isEditing: { serialize: false },
      isSelected: { serialize: false },
      workflowType: { serialize: false },
      status: { serialize: false },
      wasReminderSent: { serialize: false }
    },
    serializeAttribute(snapshot, json, key) {
      if (snapshot.attr(key) != null) {
        this._super(...arguments);
      }
    },
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data) {
        payload.data.forEach(function (val) {
          if (val.state) {
            val.status = val.state.toLowerCase();
            delete val.state;
          }
          if (val.status) {
            val.status = val.status.toLowerCase();
            if (val.status === 'pendingapproval') {
              val.status = 'pending';
            }
          }
          if (val.studyData) {
            if (val.studyData.sessionVideo) {
              if (val.studyData.sessionVideo.presets) {
                if (val.studyData.sessionVideo.presets[0]) {
                  val.studyData.sessionVideo.mp4 = {};
                  val.studyData.sessionVideo.mp4.url = val.studyData.sessionVideo.presets[0].asset.url;
                  val.studyData.sessionVideo.mp4.thumbnail = val.studyData.sessionVideo.presets[0].thumbnail.url;
                }
              }
            }
          }
        });
        payload = payload.data;
      } else {
        if (payload.state) {
          payload.status = payload.state;
          delete payload.state;
        }
        if (payload.status) {
          payload.status = payload.status.toLowerCase();
          if (payload.status === 'pendingapproval') {
            payload.status = 'pending';
          }
        }
        if (payload.studyData) {
          if (payload.studyData.sessionVideo) {
            if (payload.studyData.sessionVideo.presets) {
              if (payload.studyData.sessionVideo.presets[0]) {
                payload.studyData.sessionVideo.mp4 = {};
                payload.studyData.sessionVideo.mp4.url = payload.studyData.sessionVideo.presets[0].asset.url;
                payload.studyData.sessionVideo.mp4.thumbnail = payload.studyData.sessionVideo.presets[0].thumbnail.url;
              }
            }
          }
        }
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }

  });
});