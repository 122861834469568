define('yxt-apps/models/project', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    logo: _emberData.default.attr(),
    description: _emberData.default.attr('string'),
    tags: _emberData.default.attr(),
    studies: _emberData.default.hasMany('study'),
    modifiedAt: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('string'),

    // for front-end app only
    isSelected: _emberData.default.attr('boolean', { defaultValue: false }),
    studyCount: Ember.computed('studies', function () {
      let studies = this.get('studies');
      return studies.get('length');
    }),
    isLaunchPromoProject: Ember.computed('name', function () {
      let name = this.get('name');
      if (name === 'Promotion Boomerang project') {
        return true;
      }
    })
  });
});