define('yxt-apps/helpers/pn', ['exports', 'ember-l10n/helpers/pn'], function (exports, _pn) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _pn.default;
    }
  });
  Object.defineProperty(exports, 'pn', {
    enumerable: true,
    get: function () {
      return _pn.pn;
    }
  });
});