define('yxt-apps/serializers/study-report-share-link', ['exports', 'yxt-apps/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'data') {
        let modelName = 'study-report-share-link';
        return this._super(payloadKey.replace('data', modelName));
      } else {
        return this._super(payloadKey);
      }
    },

    normalizeQueryRecordResponse(store, primaryModelClass, payload) {
      if (!payload.id || payload.id == null) {
        let randomString = Math.random().toString(36).slice(-8);
        payload.id = `sharelink_${randomString}`;
      }
      if (!payload.type) {
        payload.type = 'study-report-share-link';
      }
      if (payload.url) {
        if (!payload.token) {
          let lastSlash = payload.url.lastIndexOf("token=");
          payload.token = payload.url.substring(lastSlash + 6);
        }
      }
      return this.normalizeSingleResponse(...arguments);
    }
  });
});