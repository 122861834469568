define('yxt-apps/routes/ford/logout', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
        beforeModel() {
            this.get('session').invalidate();
        }
    });
});