define('yxt-apps/helpers/t-var', ['exports', 'ember-l10n/helpers/t-var'], function (exports, _tVar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _tVar.default;
    }
  });
  Object.defineProperty(exports, 'tVar', {
    enumerable: true,
    get: function () {
      return _tVar.tVar;
    }
  });
});