define('yxt-apps/routes/admin/organizations/organization/participants/participant', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model(params) {
            let participant = this.get('store').findRecord('participant', params.participant_id);
            return participant;
        },
        actions: {
            updateParticipant(participant) {
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }
                function notifyParticipantSuccess(participant) {
                    self.toast.success(`Participant (${participant.get('profile.firstName')}) updated succesfully`);
                }
                let person = this.get('store').peekRecord('participant', participant.get('id'));
                person.save().then(notifyParticipantSuccess).catch(failure);
            },
            cancelChanges(participant) {
                participant.rollbackAttributes();
            }
        }
    });
});