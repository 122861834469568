define('yxt-apps/models/ford-html-report', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        created_by: _emberData.default.attr('string', { defaultValue: 'Geoff' }),
        path: _emberData.default.attr('string'),
        project: _emberData.default.belongsTo('project'),
        study: _emberData.default.belongsTo('study'),
        title: _emberData.default.attr('string'),
        originFileType: _emberData.default.attr('string', { defaultValue: 'zip' }),
        organization: _emberData.default.belongsTo('organization'),
        yxtEnv: _emberData.default.attr('string'),
        zip: _emberData.default.attr('file')
    });
});