define('yxt-apps/routes/admin/users/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        actions: {
            resetPassword(username) {
                let self = this;
                function failure(reason) {
                    self.toast.error(reason);
                }

                function notifySuccess(username) {
                    self.toast.success(`Reset link emailed to (${username}) `);
                }
                Ember.$.ajax({
                    url: `${this.get('config.host')}/${this.get('config.namespace')}/user/password/forgotten/?username=${username}`,
                    method: 'POST',
                    data: {
                        'username': username
                    }
                }).done(function () {
                    notifySuccess(username);
                }).catch(function (response) {
                    failure(response);
                });

                return false;
            }

        }
    });
});