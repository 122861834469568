define('yxt-apps/helpers/journal-percent', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.journalPercent = journalPercent;
    function journalPercent(params /*, hash*/) {
        let [expectedParticipants, participantResults, numberOfDays, entriesPerDay] = params;

        let totalEntries = 0;
        participantResults.forEach(function (result) {
            result.get('entries.StudyEntry').forEach(function (entry) {
                totalEntries++;
            });
        });

        return totalEntries / (entriesPerDay * numberOfDays) * expectedParticipants * 10;
    }

    exports.default = Ember.Helper.helper(journalPercent);
});