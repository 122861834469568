define('yxt-apps/components/icon/journal-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  let arc, color;

  exports.default = Ember.Component.extend({
    tagName: 'div',
    percentComplete: 0,
    status: 'incomplete',

    didInsertElement() {
      let status = this.get('status');
      let ringColor;
      if (status === 'active') {
        ringColor = '#4937B1';
      } else if (status === 'complete') {
        ringColor = '#4937B1';
      } else if (status === 'ready') {
        ringColor = '#2CB1D0';
      } else {
        ringColor = '#E0E6EC';
      }
      arc = d3.arc().outerRadius(36).innerRadius(30).cornerRadius(4);
      color = d3.scaleOrdinal().range([ringColor, 'rgba(57,62,78,0.01)']); // Match border color for percentRemaining portion

      let percentComplete = this.get('percentComplete');
      if (status === 'incomplete') {
        percentComplete = 0;
      }
      let percentRemaining = 100 - percentComplete;
      let data = [percentComplete, percentRemaining];

      let svg = d3.select("#" + this.elementId + " svg.journal-icon-donut");

      let group = svg.append("g").attr("transform", "translate(37,37)");

      let pie = d3.pie().sort(null)(data);

      var g = group.selectAll("arc").data(pie).enter().append("g").attr("class", "arc");

      g.append("path").attr("d", arc).attr("fill", function (d, i) {
        return color(i);
      });
    }
  });
});