define('yxt-apps/models/study-meta', ['exports', 'ember-data', 'ember-data-model-fragments', 'ember-data-model-fragments/attributes', 'moment'], function (exports, _emberData, _emberDataModelFragments, _attributes, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function addWeekdays(date, days) {
    date = (0, _moment.default)(date); // use a clone
    while (days > 0) {
      date = date.add(1, 'days');
      // decrease "days" only if it's a weekday.
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        days -= 1;
      }
    }
    return date;
  }

  exports.default = _emberDataModelFragments.default.Fragment.extend({
    referrer: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    deviceType: _emberData.default.attr('string'),
    instructions: _emberData.default.attr('string'),
    recruit: _emberData.default.attr('string'),
    recruitCustomDescription: _emberData.default.attr('string'),
    segments: _emberData.default.attr('string'),
    requestedEndTime: _emberData.default.attr('string', {
      defaultValue() {
        return addWeekdays((0, _moment.default)(), 5);
      }
    }),
    requestedBy: (0, _attributes.fragment)('profile'),
    requestedByEmail: _emberData.default.attr('string'),
    dueDate: _emberData.default.attr(),
    study: _emberData.default.belongsTo('study'),
    reportpath: _emberData.default.attr()
  });
});