define('yxt-apps/helpers/increment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.increment = increment;
  function increment(value) {
    let incVal = value * 1 + 1;
    return incVal;
  }

  exports.default = Ember.Helper.helper(increment);
});