define('yxt-apps/router', ['exports', 'yxt-apps/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    // GENERAL - AT TOP LEVEL
    this.route('index');
    this.route('index', { path: '/' });
    this.route('login');
    this.route('forgot-password');
    this.route('not-found', { path: '/*wildcard' });
    this.route('reset-password', { path: '/reset-password/:activateAccount_id' });
    this.route('reset-password-success');
    this.route('results', { path: 'results/:result_id/organization/:organization_id/study/:study_id' }, function () {
      this.route('participant', { path: 'participant/:participant_id' });
    });
    this.route('pending-activation');
    this.route('confirm-email', { path: '/confirm-email/:activateAccount_id' });

    // ADMIN APP
    this.route('admin', function () {
      this.route('index', { path: '/' });
      this.route('dashboard');
      this.route('organizations', function () {
        this.route('organization', { path: '/:organization_id' }, function () {
          this.route('members', function () {
            this.route('member', { path: '/:member_id' });
            this.route('create');
          });
          this.route('participants', function () {
            this.route('participant', { path: '/:participant_id' });
          });
          this.route('projects', function () {});
          this.route('studies', function () {
            this.route('study', { path: '/:study_id' }, function () {
              this.route('mail-templates', function () {
                this.route('mail-template', { path: '/:mailTemplate_id' });
              });
              this.route('edit');
              this.route('results', function () {
                this.route('participant', { path: '/:participant_id' });
              });
            });
          });
          this.route('projects', function () {
            this.route('project', { path: '/:project_id' }, function () {
              this.route('create');
            });
            this.route('create');
          });
        });
        this.route('create');
      });
      this.route('study-participant-templates', function () {
        this.route('study-participant-template', { path: '/:studyParticipantTemplate_id' });
      });
      this.route('users', function () {
        this.route('user', { path: '/:user_id' });
      });
      this.route('roles', function () {
        this.route('role', { path: '/:role_id' });
      });
      this.route('study-templates', function () {
        this.route('study-template', { path: '/:studyTemplate_id' });
      });
      this.route('study-feature-templates', function () {
        this.route('study-feature-template', { path: '/:studyFeatureTemplate_id' });
        this.route('create');
      });
      this.route('mails', function () {
        this.route('mail', { path: '/:mail_id' });
      });
      this.route('not-found', { path: '/*wildcard' });
      this.route('login');
    });

    // REACTION APP
    this.route('reaction', function () {
      this.route('login');
      this.route('sign-up');
      this.route('account', function () {
        this.route('change-password');
        this.route('profile');
      });
      this.route('studies', function () {
        this.route('study', { path: '/:study_id' }, function () {
          this.route('participants', function () {
            this.route('participant', { path: '/:participant_id' });
            this.route('participant-upload');
          });
          this.route('results', function () {
            this.route('participant', { path: '/participant/:participant_id' });
          });

          this.route('entry', function () {
            this.route('participant', { path: '/participant/:participant_id' });
          });
          this.route('overview');

          this.route('create', function () {
            this.route('setup', function () {
              this.route('tasks');
              this.route('questions');
            });
            this.route('review');
            this.route('launch');
            this.route('participants');
          });
          this.route('reports');
        });
      });
      this.route('forgot-password');
      this.route('reset-password-success');
      this.route('privacy-policy');
      this.route('terms-of-service');
      this.route('not-found', { path: '/*wildcard' });
      this.route('reset-password');
      this.route('pending-activation');
      this.route('confirm-email');
      this.route('logout');
    });

    // JOURNAL APP
    this.route('journal', function () {
      this.route('sign-up');
      this.route('studies', function () {
        this.route('study', { path: '/:study_id' }, function () {
          this.route('create', function () {
            this.route('setup', function () {
              this.route('entries');
              this.route('questions');
            });
            this.route('review');
            this.route('launch');
            this.route('participants');
          });
          this.route('participants', function () {
            this.route('participant', { path: '/:participant_id' });
          });
          this.route('results', function () {
            this.route('participant', { path: '/participant/:participant_id' });
          });
          this.route('overview');
        });
      });
      this.route('forgot-password');

      this.route('account', function () {
        this.route('change-password');
        this.route('profile');
      });
      this.route('reset-password-success');
      this.route('privacy-policy');
      this.route('terms-of-service');
      this.route('not-found', { path: '/*wildcard' });
      this.route('reset-password');
      this.route('pending-activation');
      this.route('confirm-email');
      this.route('login');
      this.route('logout');
    });

    this.route('privacy-policy');
    this.route('terms-of-service');
    this.route('project', { path: '/project/:project_id' }, function () {
      this.route('study', { path: '/study/:study_id' });
    });
    this.route('logout');

    // FORD App
    this.route('ford', function () {
      this.route('account', function () {
        this.route('change-password');
        this.route('profile');
      });
      this.route('sign-up');
      this.route('reset-password');
      this.route('reset-password-success');
      this.route('pending-activitation');
      this.route('not-found');
      this.route('logout');
      this.route('login');
      this.route('forgot-password');
      this.route('confirm-email');
      this.route('projects', function () {
        this.route('project', { path: ':project_id' }, function () {
          this.route('study', { path: '/study/:study_id' }, function () {
            this.route('request');
            this.route('report');
          });
          this.route('request-new-evaluation');
        });
        this.route('create');
      });
      this.route('studies');
    });
  });

  exports.default = Router;
});