define('yxt-apps/routes/admin/study-templates/study-template', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model(params) {
      let studyTemplate = this.get('store').findRecord('study-template', params.studyTemplate_id);
      let featureTemplates = this.get('store').findAll('study-feature-template');
      let participantTemplates = this.get('store').findAll('study-participant-template');

      return Ember.RSVP.hash({
        studyTemplate: studyTemplate,
        featureTemplates: featureTemplates,
        participantTemplates: participantTemplates
      });
    },
    actions: {
      saveStudyTemplate(template) {
        template.save();
      }
    }
  });
});