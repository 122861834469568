define('yxt-apps/routes/reaction/studies/study/create/review', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = {
                title: `${pageName} - Review`
            };
            return breadCrumbTitle;
        }),
        afterModel() {
            let parentModel = this.modelFor('reaction.studies.study');
            let study = parentModel.study;
            if (study.get('status') === 'incomplete') {
                study.submit().then(() => {
                    study.set('status', 'ready');
                }); // mark it as ready
            }
        },

        actions: {
            willTransition() {
                let study = this.controller.get('model.study');
                if (study.get('status') === 'incomplete') {
                    study.submit(); // mark it as ready
                }
            }
        }
    });
});