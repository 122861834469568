define('yxt-apps/components/nav-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'nav',
    didInsertElement() {

      function isScrolledDown() {
        let buffer = 40;
        let viewportTop = Ember.$('body').scrollTop();
        let elemTop = Math.round(Ember.$('.workspace').offset().top);
        let isScrolled = elemTop < viewportTop + buffer;

        if (isScrolled) {
          Ember.$('nav').addClass('minimize');
          Ember.$('.title-bar').addClass('minimize');
        } else {
          Ember.$('nav').removeClass('minimize');
          Ember.$('.title-bar').removeClass('minimize');
        }
        return isScrolled;
      }

      // Capture scroll events
      Ember.$('body > div').scroll(function () {
        isScrolledDown();
      });
      isScrolledDown();
    },
    actions: {
      logout() {
        this.get('session').invalidate();
      },
      showReportProblem() {
        this.get('state').openModal('isReportProblem');
      },
      open(dropdown) {
        if (this.closeTimer) {
          Ember.run.cancel(this.closeTimer);
          this.closeTimer = null;
        } else {
          dropdown.actions.open();
        }
      },

      closeLater(dropdown) {
        this.closeTimer = Ember.run.later(() => {
          this.closeTimer = null;
          dropdown.actions.close();
        }, 200);
      },

      showGiveFeedback() {
        this.get('state').openModal('isGiveFeedback');
      }
    }
  });
});