define('yxt-apps/models/user', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isEmailValid(email) {
    if (email) {
      if (email.length >= 5) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
      }
    }
  }
  function isPasswordMatched(a, b) {
    if (a == b) {
      return true;
    }
  }
  function isPasswordValid(pwd) {
    return (/(?=^.{6,32}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(pwd)
    );
  }

  exports.default = _emberData.default.Model.extend({
    tenantId: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirm: _emberData.default.attr('string'),
    passwordUpdated: _emberData.default.attr('boolean', { defaultValue: false }),
    roles: _emberData.default.hasMany('role'),
    profile: (0, _attributes.fragment)('profile'),
    language: _emberData.default.attr('string', { defaultValue: 'en' }),
    currency: _emberData.default.attr('string', { defaultValue: 'USD' }),
    organizationMember: _emberData.default.belongsTo('organizationMember'),
    modifiedAt: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    isPasswordValid: Ember.computed('password', function () {
      let pwd = this.get('password');
      if (pwd) {
        return isPasswordValid(pwd);
      }
    }),
    isEmailValid: Ember.computed('email', function () {
      let email = this.get('email');
      if (email) {
        return isEmailValid(email);
      }
    }),
    isPasswordMatched: Ember.computed('password', 'passwordConfirm', function () {
      let password = this.get('password');
      let confirmPassword = this.get('passwordConfirm');
      if (password && confirmPassword) {
        return isPasswordMatched(password, confirmPassword);
      }
    })
  });
});