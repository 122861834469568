define('yxt-apps/components/sign-up', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    app: null,
    displayEmailError: false,
    displayPasswordError: false,
    displayConfirmPasswordError: false,
    actions: {
      blurEmail() {
        if (!this.get('model.isEmailValid')) {
          this.set('displayEmailError', true);
        } else {
          this.set('displayEmailError', false);
        }
      },
      blurPassword() {
        if (!this.get('model.isPasswordValid')) {
          this.set('displayPasswordError', true);
        } else {
          this.set('displayPasswordError', false);
        }
      },
      blurConfirmPassword() {
        if (!this.get('model.isPasswordMatched')) {
          this.set('displayConfirmPasswordError', true);
        } else {
          this.set('displayConfirmPasswordError', false);
        }
      },
      authenticate() {
        let { username, password } = this.getProperties('username', 'password');

        this.get('session').authenticate('authenticator:custom', username, password).catch(reason => {
          self.get('session').set('errorMessage', reason.message || reason);
        });
      },
      openConsentModal() {
        this.get('state').set('isDisplayConsentModal', true);
      },
      closeConsentModal() {
        this.get('state').set('isDisplayConsentModal', false);
      },
      registerUser() {
        let self = this;

        function transitionToLogin() {
          let app_tmp = self.get('app');
          self.get('router').transitionTo(app_tmp + '.login');
        }

        function waitForAccountActivation() {
          // store some of this user data in the localStorage
          let app_tmp = self.get('app');
          let newUser = self.get("model");
          self.get('me').saveUsername(newUser.get('username'));
          self.get('me').saveFirstName(newUser.get('profile.firstName'));
          self.get('me').saveLastName(newUser.get('profile.lastName'));

          self.get('router').transitionTo(app_tmp + '.pending-activation');
        }

        //let newUser = this.controller.get('model');
        let newUser = self.get("model");
        let email = newUser.get('email');
        self.get('me').saveEmail(email);
        newUser.set('username', email);
        newUser.set('organization', email);

        newUser.save().then(waitForAccountActivation).catch(reason => {
          self.get('session').set('errorMessage', reason || reason);
          self.get('state').set('isError', true);
          if (reason) {
            if (reason.errors) {
              if (reason.errors[0].detail.detail == 'DuplicatedItemIdError') {
                self.get('state').set('errorMessage', 'This account already exists.  Login or create a new account.');
              } else if (reason.errors[0].detail.detail == 'ConstraintViolationError') {
                self.get('state').set('errorMessage', 'This account already exists.  Login or create a new account.');
              } else {
                self.get('state').set('errorMessage', reason.errors[0].detail.detail);
              }
            } else {
              self.get('state').set('errorMessage', reason);
              waitForAccountActivation(reason);
            }
          } else {
            transitionToLogin();
            self.get('state').set('errorMessage', '');
          }
        });
      },
      cancelSignup(user) {
        // reset form.
        user.rollbackAttributes();

        // go back to previous route.
        history.back();
      }
    }
  });
});