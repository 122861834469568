define('yxt-apps/components/study-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            cancelChanges(study) {
                study.rollbackAttributes();
            },
            updateStudy(study) {
                this.get('updateStudy')(study);
            }
        }
    });
});