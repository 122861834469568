define('yxt-apps/components/study-list-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['list-header'],
    actions: {
      toggleSelectAll() {
        this.sendAction('toggleSelectAll');
      }
    }
  });
});