define('yxt-apps/routes/ford/projects', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: {
            title: 'Projects'
        },
        model() {
            let teamProject = this.get('me.userNotes');
            return this.get('store').findAll('project').then(function (projects) {
                return projects.sortBy('modifiedAt').reverse();
            });
        },
        beforeModel() {
            this.set('headData.title', 'Ford - Projects');
        }
    });
});