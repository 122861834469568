define('yxt-apps/routes/reaction/studies/study/reports', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.study.title', function () {
            const pageName = this.get('controller.model.study.title') || 'Study';
            const breadCrumbTitle = {
                title: `${pageName} Upload Reports`
            };
            return breadCrumbTitle;
        }),
        getReports(studyId) {
            if (this.get('config.isReportZipUploadEnabled')) {
                return this.get('store').query('ford-html-report', {
                    study: studyId
                });
            }
        },
        beforeModel() {
            this.get('state').set('isCreationFlowActive', false);
        },
        model() {
            let parentModel = this.modelFor('reaction.studies.study');
            let studyId = parentModel.study.get('id');
            this.set('studyId', studyId);
            return Ember.RSVP.hash({
                studyTypes: parentModel.studyTypes,
                study: parentModel.study,
                participants: parentModel.participants,
                completedParticipants: parentModel.completedParticipants,
                studyWebsiteTasks: parentModel.studyWebsiteTasks,
                participantResults: parentModel.participantResults,
                reports: this.getReports(studyId)

            });
        },
        onPoll() {
            let parentModel = this.modelFor('reaction.studies.study');
            let studyId = parentModel.study.get('id');
            return this.getReports(studyId).then(reports => {
                this.set('currentModel.reports', reports);
            });
        },
        afterModel(model) {
            let usersPoller = this.get('usersPoller');
            //this.set('headData.title', `YouXReaction - ${model.study.get('title')}`);
            // Make sure we only create one poller instance. Without this every time onPoll
            // is called afterModel would create a new poller causing us to have a growing list
            // of pollers all polling the same thing (which would result in more frequent polling).
            if (!usersPoller) {
                usersPoller = this.get('pollboy').add(this, this.onPoll, this.get('config.pollFrequency'));
                this.set('usersPoller', usersPoller);
            }
        },
        deactivate() {
            const usersPoller = this.get('usersPoller');
            this.get('pollboy').remove(usersPoller);
        },
        actions: {
            deleteReport(report) {
                let reportId = report.get('id');
                this.get('store').findRecord('ford-html-report', reportId, {
                    backgroundReload: false
                }).then(function (report) {
                    report.destroyRecord(); // => DELETE to /reports/:id
                });
            },
            updateModel() {
                let parentModel = this.modelFor('reaction.studies.study');
                let studyId = parentModel.study.get('id');
                return this.getReports(studyId).then(reports => {
                    this.set('currentModel.reports', reports);
                });
            }
        }
    });
});