define('yxt-apps/routes/reaction/not-found', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        breadCrumb: {
            title: 'Ooops, page not found!'
        }
    });
});