define('yxt-apps/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({

    serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attributes);
      } else if (snapshot.attr(key) != null) {
        this._super(...arguments);
      }
    },
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data) {
        payload = payload.data;
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});