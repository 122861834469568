define('yxt-apps/components/modal/confirm-delete-participant', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isConfirmDelete: false,
        isModalDisplayed: true,
        actions: {
            removeParticipant(participant) {
                // call removeParticipant on parent
                this.get('removeParticipant')(participant);
            },
            cancelModal() {
                this.get('cancelModal')();
            }
        }
    });
});