define('yxt-apps/adapters/role', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'yxt-apps/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    session: Ember.inject.service(),
    authorize(xhr) {
      let { token } = this.get('session.data.authenticated');
      if (Ember.isPresent(token)) {
        xhr.setRequestHeader('Authorization', `Cactus token=${token}`);
      }
    },
    host: `${_environment.default.host}/${_environment.default.namespace}`,
    pathForType: function () {
      return 'roles';
    }
  });
});