define('yxt-apps/models/organization-member', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    roles: _emberData.default.hasMany('role'),
    modifiedAt: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    organization: _emberData.default.belongsTo('organization')
  });
});