define('yxt-apps/routes/admin/organizations/organization/members', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    breadCrumb: Ember.computed('controller.model.name', function () {
      const membersLength = this.get('controller.model.length') || 0;

      const members = {
        title: `Members (${membersLength})`
      };

      return members;
    }),
    beforeModel() {
      this.get('store').unloadAll('organization-member');
    },
    model() {
      return this.get('store').findAll('organization-member').then(function (member) {
        return member.sortBy('username');
      });
    }
  });
});