define('yxt-apps/components/report-card/explore-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    activeSection: 'ranking',
    isRanking: Ember.computed('activeSection', function () {
      let active = this.get('activeSection');
      if (active === 'ranking') {
        return true;
      }
    }),
    isBreakdown: Ember.computed('activeSection', function () {
      let active = this.get('activeSection');
      if (active === 'breakdown') {
        return true;
      }
    }),
    actions: {
      switchTab(name) {
        this.set('activeSection', name);
      }
    }
  });
});