define('yxt-apps/serializers/study-feature', ['exports', 'yxt-apps/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      //id: { serialize: false },
      //enabled: { serialize: false },
      template: { serialize: true },
      study: { serialize: false }, // api doesn't want this.
      studyTemplate: { serialize: false }
    },
    modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'data') {
        let modelName = 'study-feature';
        return this._super(payloadKey.replace('data', modelName));
      } else {
        return this._super(payloadKey);
      }
    }
  });
});