define('yxt-apps/serializers/participant-result', ['exports', 'yxt-apps/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      modifiedAt: { serialize: false },
      createdAt: { serialize: false }
    },
    serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
        this._super(snapshot, json, key, attributes);
      }
    },
    modelNameFromPayloadKey(payloadKey) {
      if (payloadKey === 'data') {
        let modelName = 'participantResult';
        return this._super(payloadKey.replace('data', modelName));
      } else {
        return this._super(payloadKey);
      }
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.data) {
        payload.data.forEach(function (val) {
          if (val.state) {
            val.status = val.state;
            delete val.state;
          }

          if (!val.type) {
            val.type = 'participant-result';
          }
          if (val.participant) {
            if (!val.participant.type) {
              val.participant.type = 'participant';
            }
            if (val.participant.profile) {
              val.participant.profile.id = `${val.participant.id}_pfl`;
              val.participant.profile.type = `profile`;
            }
          }

          if (val.studyData) {
            if (val.studyData.sessionVideo) {
              if (val.studyData.sessionVideo.presets) {
                if (val.studyData.sessionVideo.presets[0]) {
                  val.studyData.sessionVideo.mp4 = {};
                  val.studyData.sessionVideo.mp4.url = val.studyData.sessionVideo.presets[0].asset.url;
                  val.studyData.sessionVideo.mp4.thumbnail = val.studyData.sessionVideo.presets[0].thumbnail.url;
                }
              }
            }
          }

          if (val.entries.StudyEntry) {
            val.entries.StudyEntry.forEach(function (prop) {
              prop.taskData = [];
              for (var i in prop.properties) {
                prop.taskData.unshift(prop.properties[i]);
              }

              if (prop.pages) {
                prop.pages.forEach(function (page) {
                  page.answers = [];
                  if (page.content) {
                    for (var j in page.content) {
                      page.answers.unshift(page.content[j]);
                    }
                    for (var i = 0; i < page.answers.length; i++) {
                      if (page.templateId) {
                        if (page.templateId.content) {
                          if (page.templateId.content[0].contentType === "MultiChoice") {
                            let choiceIndex = page.answers[i];
                            let choices = new Array();
                            if (isNumber(choiceIndex)) {
                              choices.push(page.templateId.content[0].metadata.body[choiceIndex].name);
                            } else {
                              for (var m in choiceIndex) {
                                if (choiceIndex.hasOwnProperty(m)) {
                                  choices.push(page.templateId.content[0].metadata.body[choiceIndex[m]].name);
                                }
                              }
                              /*for(var k = 0; k < choices.length; k++ ){
                                let curChoiceIndex = choices[k];
                              }*/
                            }
                            page.answers[i] = choices;
                          }
                        }
                      }
                    }
                  }
                });
              }
            });
          }
          if (val.entries.PostSessionQuestions) {
            val.entries.PostSessionQuestions.forEach(function (prop) {
              prop.questionData = [];
              for (var i in prop.properties) {
                prop.questionData.unshift(prop.properties[i]);
              }

              if (prop.pages) {
                prop.pages.forEach(function (page) {
                  page.answers = [];
                  if (page.content) {
                    for (var i in page.content) {
                      page.answers.unshift(page.content[i]);
                    }
                  }
                });
              }
            });
          }
        });
        payload = payload.data;
      } else {
        payload.forEach(function (val) {
          if (val.state) {
            val.status = val.state;
            delete val.state;
          }

          if (!val.type) {
            val.type = 'participant-result';
          }
          if (val.participant) {
            if (!val.participant.type) {
              val.participant.type = 'participant';
            }
            if (val.participant.profile) {
              val.participant.profile.id = `${val.participant.id}_pfl`;
              val.participant.profile.type = `profile`;
            }
          }

          if (val.studyData) {
            if (val.studyData.sessionVideo) {
              if (val.studyData.sessionVideo.presets) {
                if (val.studyData.sessionVideo.presets[0]) {
                  val.studyData.sessionVideo.mp4 = {};
                  val.studyData.sessionVideo.mp4.url = val.studyData.sessionVideo.presets[0].asset.url;
                  val.studyData.sessionVideo.mp4.thumbnail = val.studyData.sessionVideo.presets[0].thumbnail.url;
                }
              }
            }
          }
          if (val.entries.StudyEntry) {
            val.entries.StudyEntry.forEach(function (prop) {
              prop.taskData = [];
              for (var i in prop.properties) {
                prop.taskData.unshift(prop.properties[i]);
              }
              if (prop.pages) {
                prop.pages.forEach(function (page) {
                  page.answers = [];
                  if (page.content) {
                    for (var j in page.content) {
                      page.answers.unshift(page.content[j]);
                    }
                    for (var i = 0; i < page.answers.length; i++) {
                      if (page.templateId) {
                        if (page.templateId.content) {
                          if (page.templateId.content[0].contentType === "MultiChoice") {
                            let choiceIndex = page.answers[i];
                            let choices = new Array();
                            if (isNumber(choiceIndex)) {
                              choices.push(page.templateId.content[0].metadata.body[choiceIndex].name);
                            } else {
                              for (var m in choiceIndex) {
                                if (choiceIndex.hasOwnProperty(m)) {
                                  choices.push(page.templateId.content[0].metadata.body[choiceIndex[m]].name);
                                }
                              }
                              /*for(var k = 0; k < choices.length; k++ ){
                                let curChoiceIndex = choices[k];
                              }*/
                            }
                            page.answers[i] = choices;
                          }
                        }
                      }
                    }
                  }
                });
              }
            });
          }
          if (val.entries.PostSessionQuestions) {
            val.entries.PostSessionQuestions.forEach(function (prop) {
              prop.questionData = [];
              for (var i in prop.properties) {
                prop.questionData.unshift(prop.properties[i]);
              }

              if (prop.pages) {
                prop.pages.forEach(function (page) {
                  page.answers = [];
                  if (page.content) {
                    for (var j in page.content) {
                      page.answers.unshift(page.content[j]);
                    }
                    for (var i = 0; i < page.answers.length; i++) {
                      if (page.templateId) {
                        if (page.templateId.content) {
                          if (page.templateId.content[0].contentType === "MultiChoice") {
                            let choiceIndex = page.answers[i];
                            let choices = new Array();
                            if (isNumber(choiceIndex)) {
                              choices.push(page.templateId.content[0].metadata.body[choiceIndex].name);
                            } else {
                              for (var m in choiceIndex) {
                                if (choiceIndex.hasOwnProperty(m)) {
                                  choices.push(page.templateId.content[0].metadata.body[choiceIndex[m]].name);
                                }
                              }
                              /*for(var k = 0; k < choices.length; k++ ){
                                let curChoiceIndex = choices[k];
                              }*/
                            }
                            page.answers[i] = choices;
                          }
                        }
                      }
                    }
                  }
                });
              }
            });
          }
        });
      }
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});