define('yxt-apps/models/registration-profile', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    industry: _emberData.default.attr('string'),
    jobTitle: _emberData.default.attr('string'),
    numberOfEmployees: _emberData.default.attr('number'),
    employerName: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string')
  });
});