define('yxt-apps/components/journal-study-card-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'li',
        classNameBindings: ['study.isSelected:selected'],
        classNames: ['list-item'],
        actions: {}
    });
});