define('yxt-apps/integrations/optimizely', ['exports', 'ember-cli-analytics/integrations/optimizely'], function (exports, _optimizely) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _optimizely.default;
    }
  });
});