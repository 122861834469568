define('yxt-apps/routes/admin/organizations', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    breadCrumb: Ember.computed('controller.model.name', function () {
      const orgsLength = this.get('controller.model.length') || 1;

      const orgs = {
        title: `Organizations (${orgsLength})`
      };

      return orgs;
    }),
    beforeModel() {
      this.get('store').unloadAll('organization');
    },
    model() {
      return this.get('store').findAll('organization').then(function (org) {
        return org.sortBy('modifiedAt').reverse();
      });
    },
    actions: {
      deleteOrg(org) {
        if (window.confirm(`Are you sure you want to delete ${org.get('name')}`)) {
          org.destroyRecord(); // => DELETE to /organization/{{id}}
        }
      }
    }
  });
});