define('yxt-apps/routes/admin/organizations/organization/studies/study', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        breadCrumb: Ember.computed('controller.model.title', function () {
            const studyName = this.get('controller.model.title') || 'Study';

            const stdy = {
                title: studyName
            };

            return stdy;
        }),
        getStudy(params) {
            return this.get('store').findRecord('study', params.study_id).catch(() => {
                return null;
            });
        },
        getStudyWebsiteTasks(params) {
            return this.get('store').query('study-website-task', { studyId: params.study_id }).catch(() => {
                return null;
            });
        },
        getStudyJournalTasks(params) {
            return this.get('store').query('study-journal-task', { studyId: params.study_id }).catch(() => {
                return null;
            });
        },
        getStudyPostQuestions(params) {
            return this.get('store').query('study-post-session-question', { studyId: params.study_id }).catch(() => {
                return null;
            });
        },
        getStudyTypes() {
            return this.get('store').findAll('study-template').catch(() => {
                return null;
            });
        },
        getStudyFeatureTypes() {
            return this.get('store').findAll('study-feature-template').catch(() => {
                return null;
            });
        },
        getStudyParticipants(params) {
            return this.get('store').query('study-participant', { filterByField: 'study', filterValue: params.study_id }).catch(() => {
                return null;
            });
        },
        getMailTemplates(params) {
            return this.get('store').query('mail-template', { studyId: params.study_id }).catch(() => {
                return null;
            });
        },
        model(params) {
            return Ember.RSVP.hash({
                study: this.getStudy(params),
                studyTypes: this.getStudyTypes(),
                studyFeatureTypes: this.getStudyFeatureTypes(),
                studyParticipants: this.getStudyParticipants(params),
                studyWebsiteTasks: this.getStudyWebsiteTasks(params),
                studyJournalTasks: this.getStudyJournalTasks(params),
                studyPostSessionQuestions: this.getStudyPostQuestions(params),
                mailTemplates: this.getMailTemplates(params)
            });
        },
        actions: {
            updateStudy(study) {
                let self = this;

                function failure(reason) {
                    self.toast.error(reason);
                }

                function notifyStudySuccess(study) {
                    self.toast.success(`Study (${study.get('title')}) updated`);
                }
                let curStudy = this.get('store').peekRecord('study', study.get('id'));
                curStudy.save().then(notifyStudySuccess).catch(failure);
            },
            cancelChanges(study) {
                study.rollbackAttributes();
            },
            updateMailTemplate(mailTemplate) {
                let self = this;

                function failure(reason) {
                    self.toast.error(reason);
                }

                function notifyMailTemplateSuccess(mailTemplate) {
                    self.toast.success(`Study (${mailTemplate.get('name')}) updated`);
                }
                let curMailTemplate = this.get('store').peekRecord('mail-template', mailTemplate.get('id'));
                curMailTemplate.save().then(notifyMailTemplateSuccess).catch(failure);
            }
        }
    });
});