define('yxt-apps/models/you-x-score', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    count: _emberData.default.attr('number'),
    uegScore: _emberData.default.attr('number'),
    emotioncompleting: _emberData.default.attr('number'),
    ratecompleting: _emberData.default.attr('number'),
    howsatisfied: _emberData.default.attr('number'),
    //taskScores: DS.hasMany('you-x-score-task'),
    taskScores: _emberData.default.attr()
  });
});