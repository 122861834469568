define('yxt-apps/components/reaction/participant-list-myown', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['card', 'participant-card-list'],
        actions: {
            sendReminder(participant) {
                this.get('sendReminder')(participant);
            }
        }
    });
});