define('yxt-apps/components/storage-capacity-more-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'section',
        //classNames: ['notification','storage-help'],
        actions: {
            toggleStorageHelp() {
                this.toggleProperty('isShowStorageHelp');
            }
        }
    });
});