define('yxt-apps/helpers/study-participant-url', ['exports', 'yxt-apps/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.studyParticipantUrl = studyParticipantUrl;
  function studyParticipantUrl(params) {
    let [studyParticipant, studyType, lang, isUpload] = params;
    let orgId = window.localStorage.getItem('organizationId');
    let url;
    studyType = studyType.toLowerCase();
    lang = lang.toLowerCase();
    if (!lang || lang == null) {
      lang = 'en';
    }
    if (studyType === 'qualification') {
      studyType = 'reaction';
    }
    if (!isUpload || isUpload == null) {
      isUpload = false;
    }
    url = `${_environment.default.insightdomain}/${studyType}/?spId=${studyParticipant}&oid=${orgId}&lang=${lang}`;
    if (isUpload == "true") {
      return `${url}&uploadvideo=1`;
    } else {
      return url;
    }
  }

  exports.default = Ember.Helper.helper(studyParticipantUrl);
});