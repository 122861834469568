define('yxt-apps/routes/ford/pending-activitation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        actions: {
            resendLink() {
                // no api for this yet
            }
        }
    });
});